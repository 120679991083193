import {Injectable} from '@angular/core';
import {IndividuHelpDesk} from './individu-helpdesk.model';
import {environment} from '@Env/environment';
import {HttpClient} from '@angular/common/http';
import {DaoGeneric} from "@Services/base/daoService";
import {Observable} from "rxjs";


@Injectable({providedIn: 'root'})
export class IndividuHDService extends DaoGeneric<IndividuHelpDesk> {
  private url = `${environment.baseUrl}/helpdesk/individu/`;

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  /**
   * Recherche de demandeurs / usages ayant déjà fait des tickets
   * @param keyword
   * @param extraParams
   */
  searchDemandeurs(keyword: string, extraParams: Map<string, string> = null): Observable<IndividuHelpDesk[]> {
    const url = `${this.getRootUrl()}search_demandeurs/`;
    const params = {};
    params['search'] = keyword;

    return this.httpClient.get<IndividuHelpDesk[]>(url, {params: params});
  }

  /**
   * Recherche d'agents
   * @param keyword
   * @param extraParams
   */
  searchAgents(keyword: string, extraParams: Map<string, string> = null): Observable<IndividuHelpDesk[]> {
    const url = `${this.getRootUrl()}search_agents/`;
    const params = {};
    params['search'] = keyword;
    return this.httpClient.get<IndividuHelpDesk[]>(url, {params: params});
  }

  /**
   * Recherche d'individus : demandeurs ou agents
   */
  searchIndividus(keyword: string, extraParams: Map<string, string> = null): Observable<IndividuHelpDesk[]> {
    const url = `${this.getRootUrl()}search_individus/`;
    const params = {};
    params['search'] = keyword;
    return this.httpClient.get<IndividuHelpDesk[]>(url, {params: params});
  }

  getRootUrl(urlApp?: string): string {
    return this.url;
  }
}
