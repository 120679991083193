export enum ProfilEnum {
  signature = 1,
  categories_notifs = 10,
  recap = 20,
  home_cart = 30,
  notif_archiving = 40,
}

export enum ProfilTab {
  profil = 0,
  filtres = 1,
  widgetsGraph = 2,
  shared_filters = 3,
  exports = 4,
}
