import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTimeWithoutSeconds'
})
export class GetTimeWithoutSecondsPipe implements PipeTransform {

  transform(time: any): any {
    const hour = (time.split(':'))[0];
    const min = (time.split(':'))[1];
    return `${hour}:${min}`;
  }
}
