import { Injectable } from '@angular/core';


import { AppUrlConfig, ServiceBase } from '../../base';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ArticleAdminService extends ServiceBase {
  private static urlConfig = 'articlesadmin';

  constructor(private http: HttpClient,
              private config: AppUrlConfig) {
    super(http, config);
  }

  getKeyConfig() {
    return ArticleAdminService.urlConfig;
  }
}
