import { Component, Input }  from '@angular/core';

import { NavComponent }       from '../dynamic-core-components/component-interface.component';
import {BehaviorSubject} from "rxjs";

@Component({
  template: `
    <div>
      <ng-container *ngFor="let m of data">
        <button *ngIf="m.nav" mat-button [routerLink]="m.url" [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{exact:true}">
    
        <app-menuicon *ngIf="m.icon!==''" [icon]="m.icon"></app-menuicon>
    
          {{ m.titre }}
        </button>
      </ng-container>
    </div>
  `
})
export class MenuNavComponent implements NavComponent {
  @Input() data: any;
  subject: BehaviorSubject<string> = new BehaviorSubject(null);
}
