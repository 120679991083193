<mat-card fxFlex="70" class="mat-elevation-z3">
  <mat-card-content fxLayout="column" style="background-color: #ffffee">
    <div>
      <form>
        <div class="mat-card-subtitle">
          Destinataires agents ou groupes ({{MAX_DESTINATAIRES}} max.) :
        </div>
        <div><mat-progress-bar *ngIf="searchLoading" mode="indeterminate"></mat-progress-bar></div>
        <div>
          <mat-form-field>
            <mat-chip-list #chipList>
              <mat-chip fxLayout="row" style="max-width: 200px"
                        *ngFor="let sub of destinataires"
                        [selectable]="true"
                        [removable]="true"
                        (removed)="delete(sub)">
                <span>{{sub.label}}</span>
                <span class="fill-space"></span>
                <mat-icon matChipRemove matTooltip="cliquer pour enlever ce contact">cancel</mat-icon>
              </mat-chip>
              <input matInput
                 type="text"
                 placeholder="rechercher nom, prénom, email ou uid parmi les agents traitants ou un groupe"
                 #search
                 [matTooltip]="'rechercher sur une partie du nom / prénom / email ou le libellé du groupe'"
                 [matChipInputFor]="chipList"
                 [matAutocomplete]="auto"
                 [formControl]="destForm"
                 [(ngModel)]="searchTerm">
            </mat-chip-list>

          </mat-form-field>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let result of indgrpSearch | async"
                        matTooltip="{{ result.label }}"
                        (onSelectionChange)="onSelectIndiv(result)">
              {{ result.label }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </form>
    </div>
    <mat-card>
      <mat-card-content>
        <div>
          <mat-form-field>
            <input type="text" matInput
                   [UpperCaseFirstLetter]="true"
                   [minLength]="2"
                   [maxLength]="100"
                   [formControl]="objectForm"
                   placeholder="Objet de votre message">
          </mat-form-field>
          <div fxLayoutAlign="end"><mat-hint><code>{{objectForm?.value?.length}}</code> / <code>100</code></mat-hint></div>
        </div>

        <div>
          <span class="mat-subheading-1 p8-secondary">contenu du message, maximum {{MAX_MSG}} caractères</span>
          <quill-editor [(ngModel)]="message.messageHTML"
                        [modules]="modules"
                        [minLength]="10"
                        [maxLength]="MAX_MSG"
                        [placeholder]="placeholder"
                        [formats]="formats">
          </quill-editor>
          <div fxLayoutAlign="end"><mat-hint><code>{{message?.messageHTML?.length}}</code> / <code>{{MAX_MSG}}</code></mat-hint></div>
        </div>
      </mat-card-content>
    </mat-card>
    <div style="clear: both"></div>
    <div>
      <mat-checkbox [formControl]="chkNoteInterne">transformer le message en note interne</mat-checkbox>
    </div>

  </mat-card-content>
  <mat-card-actions align="end">
      <div><button mat-raised-button
                 color="primary"
                 (click)="send($event)"
                 [disabled]="blockSend()">
        Envoyer
        <spinner-ellipsis *ngIf="sendLoading"></spinner-ellipsis>
      </button></div>
  </mat-card-actions>
</mat-card>
