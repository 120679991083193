import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DispatcherGuardService} from './dispatcher-guard.service';
import {SolverGuardService} from './solver-guard.service';
import {StaffGuard} from './staff.guard';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    DispatcherGuardService,
    SolverGuardService,
    StaffGuard

  ]
})
export class HelpdeskGuardsModule {
}
