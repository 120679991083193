<div>
  <span class="mandatory-field">* </span><span class="mat-subheading-1 p8-secondary">{{placeholder}}</span>
  <mat-icon *ngIf="descriptionHelp" [matTooltip]="getToolTipDescription()" [matTooltipClass]="'tooltip'">help_outline</mat-icon>
  <app-ckeditor-p8 #monCkEditor
                   (change)="onChangeValue($event)"
                   [(ngModel)]="description"
                   [ngModelOptions]="{standalone: true}"
                   [config]="config">
  </app-ckeditor-p8>
</div>
