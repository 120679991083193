import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class TemplatesService {
  /**
   * Retire toutes les balises HTML
   * @param text
   * @returns {string}
   */
  public static htmlToPlaintext(text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

  /**
   * Decode l'encodage HTML (&eacute; &nbsp; etc)
   * @param input
   * @returns {string | string | null}
   */
  public static htmlDecode(input){
    const e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  }

  /**
   * Transforme un modèle django HTML en "plain text"
   * @param htmlText
   */
  public static plainText(htmlText) {
    return TemplatesService.htmlDecode(TemplatesService.htmlToPlaintext(htmlText));
  }

  constructor() {
  }
}
