/**
 * Type d'onglets à afficher pour la création d'un commentaire
 * en bitwise
 */
export enum CommentTypeDisplay {
  public = 1,
  note = 2,
  annotation = 4,
  all = CommentTypeDisplay.public + CommentTypeDisplay.note + CommentTypeDisplay.annotation,
}
