<span class="mat-caption">Demandeur : effectuer la création du ticket à la place d'un demandeur, il en deviendra le propriétaire et vous en CC</span>

<mat-form-field appearance="standard" class="form-field-applicant">
  <mat-chip-list multiple="false">
    <mat-chip fxLayout="row" *ngIf="individuApplicant"
              [selectable]="true"
              [removable]="true"
              (removed)="deleteApplicant()">
      <span>{{individuApplicant.first_name}} {{individuApplicant.last_name}}</span>
      <mat-icon matChipRemove matTooltip="cliquer pour enlever ce demandeur">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>

  <app-search-ldap *ngIf="!individuApplicant"
                   [mode]="'light'"
                   [ldapDisplayProperty]="'cn,mail,uid'"
                   [removeDiatrics]="true"
                   [splitWords]="false"
                   [appearance]="'standard'"
                   [toolTip]="'taper les 3 premiers car. du nom ou prénom ou email, sélectionner pour ajouter le demandeur'"
                   [placeHolder]="'rechercher un demandeur par son nom ou prénom ou email'"
                   (ldapUserSelected)="onSelectLdapIndiv($event)"></app-search-ldap>
</mat-form-field>
