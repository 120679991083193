import { OnDestroy, ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import {Observable} from 'rxjs';


@Pipe({
  name: 'timeAgo',
  pure: false
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {

  private value: Date;


  constructor() {

  }

  public transform(obj: any): any {
    if (obj == null) {
      return '';
    }
    obj = new Date(obj);

    if (!(obj instanceof Date)) {
      console.log(obj)
      throw new Error('TimeAgoPipe works only with Dates');
    }

    this.value = obj;


    return this.elapsed();
  }

  public now(): Date {
    return new Date();
  }

  public ngOnDestroy() {

  }


  private elapsed(): string {
    let now = this.now().getTime();

    // time since message was sent in seconds
    let delta = (now - this.value.getTime()) / 1000;

    // format string
    if (delta < 60) { // sent in last minute
      return `0 min `;
    } else if (delta < 3600) { // sent in last hour
      return `${Math.floor(delta / 60)} min `;
    } else if (delta < 86400) { // sent on last day
      return `${Math.floor(delta / 3600)} h `;
    } else if (delta < 2592000){ // sent more than one day ago
      return `${Math.floor(delta / 86400)} j`;
    } else{
      return `${Math.floor(delta / 2592000)} mois`;
    }
  }
}
