import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {IndividuSafeService} from '../common/services/individu_helpdesk/individu.service';

@Injectable({   providedIn: 'root' })
export class DispatcherGuardService implements CanLoad, CanActivate {


  constructor(private individuService: IndividuSafeService) {
  }

  canLoad(route: Route): Observable<boolean> {
    return this.isDispatcher();

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.isDispatcher();
  }


  private isDispatcher(): Observable<boolean> {
    return Observable.create(observer => {
      this.individuService.individu$.subscribe(_individu => {
          observer.next(this.individuService.hasRole(_individu, 'dispatcher'));
          observer.complete();
        }
      );
    });
  }

}
