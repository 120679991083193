<mat-card>
    <app-ckeditor-p8 #monCkEditor
            [config]="config"
            (change)="editorChanged()">

      </app-ckeditor-p8>


            <form (ngSubmit)="saveAndclose($event)">
              <mat-card-actions>
                <mat-slide-toggle style="margin-right: 30px;" *ngIf="isStaff" matTooltip="La carte disparaitra de la page"
                                 [checked]="newFeed.archived"
                                 (change) = "doChange()"
                                 [color]="'primary'"
                                  labelPosition="before"
                >{{newFeed.archived?'Désarchiver':'Archiver'}} ?
                </mat-slide-toggle>
                &nbsp;
                <mat-form-field >
                  <mat-label>Ordre</mat-label>
                  <input matInput type="number" name="order" [value]="newFeed.order">
                </mat-form-field>
              </mat-card-actions>
              <mat-card-actions align="end">
                <button mat-raised-button type="submit" color="accent">Enregistrer</button>
                <button mat-raised-button type="button" color="primary" (click)="justClose()">Fermer</button>
              </mat-card-actions>
            </form>

</mat-card>
