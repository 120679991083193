export enum DurationDelay {
  'annees' = 1,
  'mois'= 2,
  'semaines' = 3,
  'jours'= 4,
  'heures' = 5,
}
export interface DurationCondition {
  typeDelay: DurationDelay;
}
