<router-outlet></router-outlet>
<div *ngIf="environment.env==='test'" style="padding-bottom: 10px;">
  <mat-icon aria-hidden="true" color="primary">warning</mat-icon>
  <strong>
    <mark>Cet espace est l'application de TEST afin de prendre en main l'outil ou de détecter des anomalies,
      aucune donnée / information ne sera conservée et ne représente en aucun cas de réelles informations à exploiter</mark>
  </strong>
  <mat-icon aria-hidden="true" color="primary">warning</mat-icon>
</div>

<div fxLayout="row" fxLayout.lt-md="column">
  <div fxFlex="50%" fxLayout="column" *ngIf="!specialCase"  fxFlexOrder.xs="2">

    <app-notes-versions></app-notes-versions>

    <mat-card>
      <mat-card-content>
        <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)="getNewsFeeds($event)">
          <mat-tab label="Informations">
            <div cdkDropList class="news-list" (cdkDropListDropped)="drop($event)" *ngIf="selectedTabIndex === 0">
              <app-news-feed
                *ngFor="let nf of newsFeeds | orderBy:'order'"
                [newsFeed]="nf"
                (saved)="fetchNewsFeed()"
                cdkDrag
                [cdkDragDisabled]="!isStaff()">
              </app-news-feed>
            </div>
          </mat-tab>
          <mat-tab label="Cartes archivés" *ngIf="isAdminOrisSuperviseur()">
            <div cdkDropList class="news-list" (cdkDropListDropped)="drop($event)" *ngIf="selectedTabIndex === 1">
              <app-news-feed
                *ngFor="let nf of newsFeeds | orderBy:'order'"
                [newsFeed]="nf"
                (saved)="fetchNewsFeed(true)"
                cdkDrag
                [cdkDragDisabled]="!isStaff()">
              </app-news-feed>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>

      <mat-card-actions>
        <button (click)="addActualite()"
                [ngStyle.xs]="{width: '100%'}"
                class="add-carte-button"
                mat-raised-button color="accent"
                *ngIf="isAdminOrisSuperviseur()">
          <mat-icon>add</mat-icon> AJOUTER CARTE
        </button>
      </mat-card-actions>
    </mat-card>

  </div>

  <app-overview-tickets fxFlex="50%" fxFlexOrder.xs="1">

  </app-overview-tickets>

</div>



