import {Ticket} from "./services";

export enum ActionTicketChangeStream {
  save = 0, // le ticket a été sauvegardée => notif sur ce dernier
  load = 1 // demande de rechargement simple du ticket (annotations, ticket suivant / précédent / ...)
}

export interface TicketChangeStream {
  ticket: Ticket;
  action: ActionTicketChangeStream;
}
