<mat-card>
  <mat-card-content >

    <img [src]="virtualUrl"
         class="image_preview"
         *ngIf="isImage">

    <!--pour le pdf tous les 3(embed , object , iframe) sont valides embed et object rends un bug non bloquant sur chrome -->
    <!--j'ai teste sur firefox safari et chrome -->
    <!--n'efface pas on sait jamais quand une methode et plus permformante que l'autre-->

      <embed [src]="virtualUrl"
             type="application/pdf"
             width="1024px" height="768px"
             *ngIf="isPdf"
      >
    <!--<iframe [src]="virtualUrl"-->
           <!--type="application/pdf"-->
           <!--width="1024px" height="768px"-->
    <!--&gt;-->
    <!--</iframe>-->
    <!--<object [data]="virtualUrl"-->
            <!--type="application/pdf"-->
            <!--width="1024px" height="768px"-->
    <!--&gt;-->
    <!--</object>-->



  </mat-card-content>

  <mat-card-actions>
    <button mat-button (click)="justClose()">Fermer</button>
  </mat-card-actions>
</mat-card>
