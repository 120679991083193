<!-- uniquement le champ de recherche LDAP -->
<div *ngIf="mode === 'light'">
  <ng-container *ngTemplateOutlet="searchTemplate;"></ng-container>
</div>
<!-- la recherche habillée -->
<mat-card *ngIf="mode === 'full'">
  <mat-card-header>
    <mat-card-title *ngIf="title">{{titleLabel}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div>
      <div>
        <mat-progress-bar *ngIf="progressBar && loading" [mode]="'indeterminate'"></mat-progress-bar>
      </div>
      <div>
        <ng-container *ngTemplateOutlet="searchTemplate;"></ng-container>
      </div>
    </div>
    <div *ngIf="scope">
      <mat-form-field fxFlex="40">
        <mat-select placeholder="Affiner la recherche sur..."
                    [matTooltip]="'Affiner la recherche sur...si aucun n\'est sélectionné, la recherche s\'effectue sur tous'"
                    [multiple]="true"
                    [disabled]="submitted"
                    [value]="scopeValues"
                    (valueChange)="onSelected($event)">
          <mat-option *ngFor="let scope of getScopesToDisplay()" [value]="scope.id">
            {{scope.libelle}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>

<!-- modèle recherche -->
<ng-template #searchTemplate>
  <mat-form-field fxFlex="90" [appearance]="appearance">
    <input type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto"
           placeholder="{{placeHolder}}"
           matTooltip="{{toolTip}}">
  </mat-form-field>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let model of ldapUsersSearch | async" [value]="model"
                (onSelectionChange)="typeaheadOnSelect(model)"
                [matTooltip]="displayOption(model)">
      {{ displayOption(model) }}
    </mat-option>
  </mat-autocomplete>
</ng-template>
