import {MemberModel} from '../../services/group/member.model';

export enum typeSearchResult {
  individu = 0,
  group = 1
}
export interface SearchToResults {
  id: string; // id individu | id group
  label: string;
  type: typeSearchResult;
  email?: string; // individu
  members_obj?: MemberModel[]; // group members
}
