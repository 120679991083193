import { Injectable } from '@angular/core';
import {environment} from '@Env/environment';
import {tap} from 'rxjs/operators';
import {Observable, ReplaySubject} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Parameter} from './parameter';
import {Threshold} from '../duration/threshold.model';
import {DurationDelay} from '../../filters/filter/configs-fields/duration.config';
import {UtilsService} from '@Services/core/utils/utils.service';
import {parameters} from "app/helpdesk/common/services/parameter/parameters";

@Injectable({   providedIn: 'root' })
export class ParameterService {
  private url = `${environment.baseUrl}/helpdesk/parameter/`;

  private paramSource = new ReplaySubject<Parameter[]>(1);
  parameter$ = this.paramSource.asObservable();

  constructor(private http: HttpClient) {
  }

  create(param: Parameter) {
    return this.http.post<Parameter>(this.url, param)
      .pipe(tap(data => this.list()));
  }


  list() {
    return this.http.get<Parameter[]>(this.url);
  }
  get(id: number) {
    return this.http.get<Parameter>(`${this.url}${id}/`);
  }
  update(param: Parameter) {
    return this.http.put<Parameter>(`${this.url}${param.id}/`, param);
  }
  delete(param: Parameter) {
    return this.http.delete<Parameter>(`${this.url}${param.id}/`);
  }

  getByName(name: string): Observable<Parameter[]> {
    let params = new HttpParams();
    params = params.set('name', name);

    return this.http.get<Parameter[]>(this.url, {params: params});
  }
  getDurationParameterInSeconds(config: Threshold) {
    if (config) {
      const _duration = {
        years: 0, weeks: 0, months: 0,
        days: config.typeDelay === DurationDelay.jours ? config.threshold : 0,
        hours: config.typeDelay === DurationDelay.heures ? config.threshold : 0,
        minutes: 0, seconds: 0
      };
      return UtilsService.YMWDHMSToseconds(_duration);
    }
    throw new Error('config est obligatoire');
  }

  configFormulaire(): Array<string> {
    return [
      parameters.phoneRequired,
      parameters.categorieRequired,
      parameters.filesRequired,
      parameters.contactsRequired
    ];
  }

  configParameter(): Array<string> {
    return [
      parameters.orniPause,
      parameters.orniActive,
      parameters.notifNew,
      parameters.notifDelay,
      parameters.refresh,
      parameters.autoClosed,
      parameters.descriptionHelp,
      parameters.thresholds_duration,
      parameters.notifDelayBeforeArchiving
    ]
  }
}
