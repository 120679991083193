import {HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse} from '@angular/common/http';
import {Upload} from './upload.interface';

/**
 * Test de progress upload
 * https://nils-mehlhorn.de/posts/angular-file-upload-progress
 * voir à installer et à utiliser le composant https://github.com/nilsmehlhorn/ngx-operators qui l'inclut à termes ?
 */
export const initialState: Upload = { state: 'PENDING', progress: 0 };

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
  return (event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress);
}

export const calculateState = (upload: Upload, event: HttpEvent<unknown>): Upload => {
  if (isHttpProgressEvent(event)) {
    return {
      progress: event.total
        ? Math.round((100 * event.loaded) / event.total)
        : upload.progress,
      state: 'IN_PROGRESS',
    };
  }
  if (isHttpResponse(event)) {
    return {
      progress: 100,
      state: 'DONE',
    };
  }
  return upload;
};
