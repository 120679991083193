import {Injectable} from '@angular/core';
import {DaoGeneric} from '@Services/base/daoService';
import {environment} from '@Env/environment';
import {HttpClient} from '@angular/common/http';

import {Filter} from './filter.model';
import {OperatorFilterEnum} from './operator.enum';

@Injectable({ providedIn: 'root' })
export class FiltersService extends DaoGeneric<Filter> {

  private url = `${environment.baseUrl}/helpdesk/filters/`;
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getRootUrl(): string {
    return this.url;
  }

  /**
   * Obtient le nombre d'objets trouvés à partir de ce filtre
   * @param id
   */
  getCountTickets(id: number) {
    const url = `${this.url}${id}/count_tickets_filter/`;
    return this.httpClient.get<any>(url);
  }

  /**
   * Nombre de filtres pour le connecté
   */
  countFiltersConnected() {
    const url = `${this.url}count_filters/`;
    return this.httpClient.get<any>(url);
  }

  /**
   * Duplication de filtre
   * @param {number} id : id du filtre à dupliquer
   * @param {string} label : nouveau libellé du filtre
   * @param {string} owner : le propriétaire, null si filtre global admin
   */
  duplicateFilter(id: number, label = null, owner = null) {
    const url = `${this.url}${id}/duplicate_filter/`;
    const body = {
      label: label
    };
    if (owner) {
      body['owner'] = owner;
    }
    return this.httpClient.post<Filter>(url, body);
  }
  /**
   * Transforme un opérateur enum en operateur django (eq, ne, etc)
   *
   * EQUAL = 1
   * DIFFERENT = 2
   *
   * GREAT_THAN = 3
   * GREAT_THAN_OR_EQUAL = 4
   *
   * LESS_THAN = 5
   * LESS_THAN_OR_EQUAL = 6
   *
   * CONTAINS = 7
   * NOT_CONTAINS = 8
   *
   * etc
   *
   * @param {number} operator
   */
  transformOperatorNumberToString(operator: number) {
    switch (operator) {
      case OperatorFilterEnum.eq:
        return 'eq';
      case OperatorFilterEnum.ne:
        return 'ne';
      case OperatorFilterEnum.gt:
        return 'gt';
      case OperatorFilterEnum.gte:
        return 'gte';
      case OperatorFilterEnum.lt:
        return 'lt';
      case OperatorFilterEnum.lte:
        return 'lte';
      case OperatorFilterEnum.contains:
        return 'contains';
      case OperatorFilterEnum.not_contains:
        return 'not_contains';
      case OperatorFilterEnum.in:
        return 'in';
      case OperatorFilterEnum.not_in:
        return 'not_in';
      case OperatorFilterEnum.since:
        return 'since';
      case OperatorFilterEnum.ago:
        return 'ago';
      case OperatorFilterEnum.empty:
        return 'empty';
      case OperatorFilterEnum.not_empty:
        return 'not_empty';
    }
  }
  /**
   * Transforme un opérateur django (eq, ...) en sa valeur number
   * @param {string} operator
   */
  transformOperatorStringToNumber(operator: string): OperatorFilterEnum {
    switch (operator) {
      case 'eq':
        return OperatorFilterEnum.eq;
      case 'ne':
        return OperatorFilterEnum.ne;
      case 'gt':
        return OperatorFilterEnum.gt;
      case 'gte':
        return OperatorFilterEnum.gte;
      case 'lt':
        return OperatorFilterEnum.lt;
      case 'lte':
        return OperatorFilterEnum.lte;
      case 'contains':
        return OperatorFilterEnum.contains;
      case 'not_contains':
        return OperatorFilterEnum.not_contains;
      case 'in':
        return OperatorFilterEnum.in;
      case 'not_in':
        return OperatorFilterEnum.not_in;
      case 'since':
        return OperatorFilterEnum.since;
      case 'ago':
        return OperatorFilterEnum.ago;
      case 'empty':
        return OperatorFilterEnum.empty;
      case 'not_empty':
        return OperatorFilterEnum.not_empty;
    }
  }
}
