<div>
  <div *ngIf="appearance">
    <mat-form-field [appearance]="appearance" fxFlex="100" style="width: 400px;">
      <input type="text" style="width: 400px;"
               matInput
               placeholder="{{placeholder}}"
               #myInput
               matTooltip="Choisir une catégorie en tapant les 1ère lettres"
               [formControl]="categorieControl"
               [matAutocomplete]="auto"
               [ngClass]="class">
      <mat-hint *ngIf="categorieSelected">{{categorieSelected?.description}}</mat-hint>
    </mat-form-field>
  </div>
  <div *ngIf="!appearance">
    <input type="text" *ngIf="! appearance" style="width: 200px;"
           matInput
           placeholder="{{placeholder}}"
           #myInput
           matTooltip="Choisir une catégorie en tapant les 1ère lettres"
           [formControl]="categorieControl"
           [matAutocomplete]="auto"
           [ngClass]="class">
  </div>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async"
                [value]="option.label"
                (onSelectionChange)="triggerSelect(option)"
                [matTooltip]="option.description" [matTooltipClass]="'tooltip'">
      {{ option.label }}
    </mat-option>
  </mat-autocomplete>
</div>
