import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LdapUser} from "@Services/ldap/ldap/ldap-user.model";
import {finalize} from "rxjs/operators";
import {PubSubService} from "../../../pub-sub.service";
import {PriorityService} from "../../../services";
import {IndividuService} from "@Services/index";
import {SubSink} from "@Common/core/utils/subsink";
import {IndividuHelpDesk} from "../../../services/individu_helpdesk/individu-helpdesk.model";

@Component({
  selector: 'app-add-contacts',
  templateUrl: './add-contacts.component.html',
  styleUrls: ['./add-contacts.component.scss']
})
export class AddContactsComponent implements OnInit, OnDestroy {
  @Input() contactsRequired = false;
  @Output() subscribers: EventEmitter<IndividuHelpDesk[]> = new EventEmitter<IndividuHelpDesk[]>();

  subSink = new SubSink();

  subscriptions: IndividuHelpDesk[] = [];

  constructor(private pubSubSvc: PubSubService,
              private priorityService: PriorityService,
              private individuBaseService: IndividuService) { }

  ngOnInit() {
  }

  /**
   * A la sélection de l'individu LDAP
   * =>
   * @param ldapUser
   */
  onSelectLdapIndiv(ldapUser: LdapUser) {
    console.log(ldapUser);

    const uid = ldapUser.uid[0];
    const urlApiIndividu = '/helpdesk/individu_ldap_safe/';

    this.pubSubSvc.publish('loading', true);
    this.subSink.sink = this.individuBaseService
      .getOrCreate(uid, urlApiIndividu)
      .pipe(finalize(() => this.pubSubSvc.publish('loading', false)))
      .subscribe(_individu => {
        const idx = this.findContact(_individu);
        if (idx <= -1) {
          this.subscriptions.push(_individu);
          this.subscribers.emit(this.subscriptions);
        }
      });
  }

  delete(cc: IndividuHelpDesk) {
    const idx = this.findContact(cc);
    if (idx > -1) {
      this.subscriptions.splice(idx, 1);
      this.subscribers.emit(this.subscriptions);
    }
  }

  findContact(cc: IndividuHelpDesk) {
    return this.subscriptions.findIndex((_cc) => _cc.uid === cc.uid);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

}
