import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from '@Services/auth';
import {AcceuilComponent} from './acceuil/acceuil.component';
import {AnonymousComponent} from './anonymous/anonymous.component';
import {StaffGuard} from './helpdesk-guards/staff.guard';


const appRoutes: Routes = [
  {path: 'public', component: AnonymousComponent, pathMatch: 'full'},
  {
    path: '',
    canLoad: [AuthGuardService],
    canActivate: [AuthGuardService],
    children: [
      {path: '', component: AcceuilComponent},
      {path: 'helpdesk', loadChildren: () => import('./client/personnel.module').then(m => m.PersonnelModule)},
      {
        path: 'solver',
        loadChildren: () => import('./solver/solver.module').then(m => m.StaffModule),
        canLoad: [StaffGuard]
      },
      {path: 'conf',
        loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule)
      },
      {path: 'stats',
        loadChildren: () => import('./statistique/statistique.module').then(m => m.StatistiqueModule)
      },

    ]
  },
  {path: '**', component: AcceuilComponent, canActivate: [AuthGuardService]}
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule {
}
