import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Ticket} from '../../services';

@Component({
  selector: 'app-description-ticket',
  templateUrl: './description-ticket.component.html',
  styleUrls: ['./description-ticket.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DescriptionTicketComponent implements OnInit {
  @Input() placeholder = 'résumé de la demande';
  @Input() set descriptionHelp(value) {
    if (value) {
      this._descriptionToolTip = value;
    }
  }
  get descriptionHelp() {
    return this._descriptionToolTip;
  }
  @Output() descriptionUpdated = new EventEmitter<string>();
  private _descriptionToolTip = '';
  description = null;

  _ticket = <Ticket> {
    title: '',
    status: null,
    description: '',
    priority: null,
    submitter_email: '',
  };

  submitted: boolean;

  /* ckeditor */
  config = {
    toolbar: [
      {
        name: 'basicstyles',
        items: [
          'Bold', 'Italic', 'Underline'
        ]
      },
      {
        name: 'paragraph',
        items: [
          'NumberedList', 'BulletedList', '-', 'Blockquote'
        ]
      }
    ],
    removePlugins: 'elementspath',
    extraPlugins: 'autogrow',
    resize_enabled: false,
    autoGrow_onStartup: true,
    height: 80,
    autoGrow_minHeight: 80,
    autoGrow_maxHeight: 230,
    uiColor: '#fafafa'
  };

  constructor() { }

  ngOnInit() {
  }

  isControlValid() {
    return !! this.description;
  }
  getToolTipDescription() {
    return this._descriptionToolTip;
  }
  onChangeValue(event) {
    this.descriptionUpdated.emit(event);
  }
}
