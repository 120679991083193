import {Component, Inject} from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import {SnackBarDataModel} from "@Common/widgets/general/snack-bar/snack-bar-data.model";
import {typeToast} from "@Common/widgets/general/snack-bar/snack-bar-toasty-type.enum";

@Component({
  selector: 'snack-bar-toasty',
  templateUrl: 'snack-bar-toasty.component.html',
  styleUrls: ['./snack-bar-toasty.component.scss'],
})
export class SnackBarToastyComponent {
  toast: SnackBarDataModel = { title: '', message: '', type: typeToast.info, showClose: true};

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              private matSnackRef: MatSnackBarRef<SnackBarToastyComponent>) {
    if (data) {
      this.toast = data as SnackBarDataModel;
    }
  }

  close($event: any) {
    $event.preventDefault();
    if (this.matSnackRef) {
      this.matSnackRef.dismiss();
    }
  }

  getToastType() {
    switch(this.toast.type) {
      case typeToast.info:
      default:
        return 'toasty-type-info';
      case typeToast.warning:
        return 'toasty-type-warning';
      case typeToast.success:
        return 'toasty-type-success';
      case typeToast.error:
        return 'toasty-type-error';
      case typeToast.wait:
        return 'toasty-type-wait';
    }
  }
}
