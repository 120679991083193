export enum StatusEnum {
  new = 1,
  waiting = 3,
  resolved = 2,
  closed = 4,
  open = 5,
}
export interface Status {
  id: number;
  label: string;
  ordre: number;
  color: string;
  autoload: boolean;
}
