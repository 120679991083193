import {Injectable} from '@angular/core';
import {DaoGeneric} from '@Services/base/daoService';
import {environment} from '@Env/environment';
import {HttpClient} from '@angular/common/http';
import {Annotation} from './annotation.model';
import {Subject} from 'rxjs';
import {Ticket} from '../index';
import {Pagination} from '@Common/widgets/general/pagination';

@Injectable({   providedIn: 'root' })
export class AnnotationService extends DaoGeneric<Annotation> {
  private url = `${environment.baseUrl}/helpdesk/annotations/`;

  private annotationSubject = new Subject<Annotation>();
  onCreatedAnnotation$ = this.annotationSubject.asObservable();

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  annotationCreated(annotation: Annotation) {
    this.annotationSubject.next(annotation);
  }

  getRootUrl(): string {
    return this.url;
  }

}
