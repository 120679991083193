import { Injectable } from '@angular/core';
import { ReplaySubject ,  Observable } from "rxjs";
import { environment } from '@Env/environment';
import { LdapUser } from './ldap-user.model';
import { LdapGroup } from './ldap-group.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({   providedIn: 'root' })
export class LdapService {
  private url = `${environment.baseUrl}/annuaire/recherche-user/`;
  private rechercheUserByUid = `${environment.baseUrl}/annuaire/recherche-user-by-uid/`;
  private rechercheGroupsByUserUrl = `${environment.baseUrl}/annuaire/recherche-groups-by-user/`;
  private removeUserFromGroupUrl = `${environment.baseUrl}/annuaire/remove-user-from-group/`;
  private searchGroupUrl = `${environment.baseUrl}/annuaire/search-groups/`;
  private addUserToGroupUrl = `${environment.baseUrl}/annuaire/add-user-to-group/`;
  private userLdapSource = new ReplaySubject<LdapUser>(1);
  userLdap$ = this.userLdapSource.asObservable();

  constructor(private http: HttpClient) {
  }

  /**
    Recherche d'utilisateurs dans l'annuaire P8 (uid, cn, sn, etc)
   */
  list(searchTerm: string, scope: string = '', splitWords = false, attrsAddFilters = null): Observable<LdapUser[]> {
    let params = {};
    params = {
      search_terms: searchTerm,
      scope: scope
    };
    if (splitWords) {
      params = {...params, split_words: true};
    }
    if (attrsAddFilters) {
      params = {...params, attrs_add_filters: attrsAddFilters};
    }
    return this.http.get<LdapUser[]>(this.url, {params: params});
  }

  /**
    Recherche d'un utilisateur sur son uid
   */
  searchByUid(uid: string) {
    return this.http.get<LdapUser>(this.rechercheUserByUid, {params: {search_terms: uid}});
  }

  sendLdapUserSelected(user: LdapUser) {
    this.userLdapSource.next(user);
  }

  getGroupsForUser(user: LdapUser) {
      return this.http.get<LdapGroup[]>(this.rechercheGroupsByUserUrl, {params: {dn: user.dn}});
  }
  removeUserFromGroup(groupDn: string, userDn: string) {
    return this.http.get<boolean> (this.removeUserFromGroupUrl, {params: {groupDn: groupDn, userDn: userDn}}).pipe(map(data => true))
  }
  searchGroup(cn_term?: string) {
    const search = {cn: cn_term};
    return this.http.get<LdapGroup[]>(this.searchGroupUrl, {params: search});
  }
  addUserToGroup(groupDn: string, userDn: string) {
    return this.http.get<boolean>(this.addUserToGroupUrl, {params: {groupDn: groupDn, userDn: userDn}}).pipe(map(data => true));
  }
}
