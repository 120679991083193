import {Injectable} from "@angular/core";
import {DaoGeneric} from "@Services/base/daoService";

import {environment} from "@Env/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {FilterCondition} from "../index";

@Injectable({ providedIn: 'root' })
export class FilterConditionsService extends DaoGeneric<FilterCondition> {
  private url = `${environment.baseUrl}/helpdesk/filtersconditions/`;

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getRootUrl(): string {
    return this.url;
  }

  /**
   * Supprimer toutes les conditions d'un filtre
   * @param filter
   */
  conditions_delete(filter: number) {
    const url = `${this.url}conditions_delete/`;

    let urlQS = new HttpParams();
    urlQS = urlQS.set('filter', filter.toString());

    return this.httpClient.get(url, {params: urlQS});
  }
}
