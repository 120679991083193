import {Injectable} from "@angular/core";
import {environment} from "@Env/environment";
import {MessageNotif} from "./message.model";
import {HttpClient} from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class MessageNotifService {
  private url = `${environment.baseUrl}/helpdesk/message/`;
  constructor(private http: HttpClient) {}

  send(message: MessageNotif) {
    return this.http.post<MessageNotif>(this.url, message);
  }
}
