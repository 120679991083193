import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {SubSink} from '@Common/core/utils/subsink';
import {UntypedFormControl, Validators} from '@angular/forms';
import {catchError, concatMap, debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap} from 'rxjs/operators';
import {forkJoin, Observable, of, of as observableOf, Subject} from 'rxjs';
import {IndividuHelpDesk} from '../../services/individu_helpdesk/individu-helpdesk.model';
import {IndividuHDService} from '../../services/individu_helpdesk/individu-hd.service';
import {MessageNotif} from '../../services/message/message.model';
import {MessageNotifService} from '../../services/message/message.service';
import {CommentService, CommentTicket, Ticket} from '../../services';
import {SnackBarToastyService, typeToast} from '@Common/widgets/general/snack-bar';
import {ActionTicketChangeStream, TicketChangeStream} from '../../ticket-change-stream.model';
import {TemplatesService} from '../../services/template.service';
import {GroupService} from '../../services/group/group.service';
import {GroupModel} from '../../services/group/group.model';
import {SearchToResults, typeSearchResult} from './search-to.model';

/**
 * Ecrire un mail à des agents
 */
@Component({
  selector: 'app-ux-ticket-message',
  templateUrl: './ux-ticket-message.component.html',
  styleUrls: ['./ux-ticket-message.component.scss']
})
export class UxTicketMessageComponent implements OnInit {
  @Input() ticket;
  /**
   * Pour écoute modification du ticket ailleurs
   */
  @Input()
  ticketChangeStream: Subject<TicketChangeStream>;

  destForm = new UntypedFormControl();
  objectForm = new UntypedFormControl([''], [Validators.required, Validators.min(2), Validators.max(100)]);
  chkNoteInterne = new UntypedFormControl(false);
  destinataires: SearchToResults[] = [];
  searchTerm = '';
  indgrpSearch: Observable<SearchToResults[]>;
  message: MessageNotif = <MessageNotif>{};
  MAX_MSG = 2000;
  MAX_DESTINATAIRES = 10;
  placeholder = `Ecrire votre message ici, max ${this.MAX_MSG} cars.`;
  searchLoading = false;
  bgColor = 'None';
  modeEdit = false;
  isMine = false;

  formats = ['bold', 'italic', 'code-block', 'list', 'indent', 'link',];

  modules = {
    toolbar: [
      ['bold', 'italic'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

      ['link']                         // link and image, video
    ]
  };

  sendLoading = false;
  subSink = new SubSink();

  @ViewChild('search', { static: true }) search: ElementRef;

  constructor(private individuService: IndividuHDService,
              private snackBarService: SnackBarToastyService,
              private commentSvc: CommentService,
              private msgSvc: MessageNotifService,
              private groupSvc: GroupService) { }

  ngOnInit() {
    this.attachEvent();
  }

  delete(dest) {
    const idx = this.destinataires.findIndex((_cc) => _cc.id === dest.id);
    if (idx > -1) {
      this.destinataires.splice(idx, 1);
    }
  }
  onSelectIndiv(_choice: SearchToResults) {
    this.searchLoading = false;
    if (!this.destinataires.find((_elt) => _elt.id === _choice.id) &&
         this.destinataires.length < this.MAX_DESTINATAIRES) {
      this.destinataires.push(_choice);
    }
    this.attachEvent();
  }
  blockSend() {
    return (! this.message || ! this.message.messageHTML || this.message.messageHTML.length > this.MAX_MSG
        || ! this.objectForm.valid || this.destinataires.length === 0 || this.sendLoading);
  }

  /**
   * La recherche s'effectue sur les agents et sur les groupes
   * Aggrégation des résultats dans SearchToResults[]
   */
  attachEvent() {
    this.destForm.setValue('');
    this.searchTerm = '';
    this.search.nativeElement.value = '';
    this.indgrpSearch = this.destForm.valueChanges
      .pipe(
        finalize(() => this.searchLoading = false),
        debounceTime(250), distinctUntilChanged(),
        filter((searchTerm) => searchTerm.length >= 2),
        tap(() => this.searchLoading = true),
        switchMap(val =>
          forkJoin([this.groupSvc.search(val), this.individuService.searchAgents(val)])),
        concatMap(results => of(this._mapSearchResults(results))),
        catchError(err => observableOf<SearchToResults[]>([])));
  }
  private _mapSearchResults(results: [GroupModel[], IndividuHelpDesk[]]) {
    const groups = results[0].map(_group =>
      <SearchToResults>{id: _group.id.toString(), label: `${_group.short_label} (${_group.long_label})`,
        type: typeSearchResult.group, members_obj: _group.members_obj});
    const agents = results[1].map(_individu =>
      <SearchToResults>{id: _individu.uid, label: `${_individu.last_name} ${_individu.first_name} (${_individu.username})`,
        type: typeSearchResult.individu, email: _individu.email});
    return [...groups, ...agents];
  }
  private htmlDecode(input) {
    return TemplatesService.plainText(input);
  }
  razMessage() {
    this.destinataires = [];
    this.message = {messageHTML: '', messageRaw: '', to: [], object: ''};
    this.objectForm.setValue('');
    this.search.nativeElement.value = '';
    this.searchTerm = '';
    this.search.nativeElement.value = '';
  }
  sendNoteInterne() {
    const destinataires = this.destinataires
      .reduce<string>((previousValue, currentValue: SearchToResults, currentIndex) =>
        currentIndex === 0 ? `@${this._destType(currentValue)}` : `${previousValue} @${this._destType(currentValue)}`, '');
    const message = `<em>message tranformé en note, destinaire${this.destinataires.length === 1 ? '' : 's'} :</em> ${destinataires}<br/><br/>${this.message.messageHTML.trim()}`;
    const comment = <CommentTicket>{
      content: message, ticket: this.ticket.id, publique: false
    };
    return this.commentSvc.create(comment);
  }
  private _destType(currentValue) {
    return `${currentValue.type === typeSearchResult.individu ? currentValue.id : currentValue.label}`;
  }
  emitTicketChangeStream(_ticket: Ticket = this.ticket,
                         action: ActionTicketChangeStream = ActionTicketChangeStream.load) {
    const ticketChangeStream = <TicketChangeStream> {
      ticket: _ticket,
      action: action
    };
    if (this.ticketChangeStream) {
      this.ticketChangeStream.next(ticketChangeStream);
    }
  }
  private _setMessage() {
    this.message.ticket = this.ticket.id;
    this.message.object = this.objectForm.value;
    this.message.messageRaw = this.htmlDecode(this.message.messageHTML.trim());
  }

  /**
   * Groupement des mails des membres des groupes et des agents, unicité des mails
   * @private
   */
  private _mapIndividusGroupsToEmails() {
    const _emails: Set<string> = new Set<string>();
    this.destinataires.forEach(_elt => {
      if (_elt.type === typeSearchResult.individu) {
        _emails.add(_elt.email);
      }
      if (_elt.type === typeSearchResult.group) {
        _elt.members_obj.forEach(_member => _emails.add(_member.member_obj.email));
      }
    });
    return Array.from(_emails.values());
  }
  send(event) {
    if (this.message && this.message.messageHTML) {
      this.message.to = this._mapIndividusGroupsToEmails();
      if (this.message.to.length > 0) {
        this._setMessage();
        this.sendLoading = true;
        this.subSink.sink = this.msgSvc
          .send(this.message)
          .pipe(
            concatMap((_msg) => this.chkNoteInterne.value ? this.sendNoteInterne() : of(_msg)),
            finalize(() => this.sendLoading = false))
          .subscribe((_msg) => {
            const msg = `Message bien envoyé ! ${this.chkNoteInterne.value ? 'Une note interne a aussi été créée' : ''}`;
            this.snackBarService.toasty('Message agents', msg, typeToast.success);
            this.razMessage();
            this.emitTicketChangeStream();
          });
      }
    }
  }
}
