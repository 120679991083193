import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import {environment} from '@Env/environment';
import {Priority} from './priority.model';
import {HttpClient} from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PriorityService {
  private url = `${environment.baseUrl}/helpdesk/priority/`;

  constructor(private http: HttpClient) {
  }

  create(priority: Priority) {
    return this.http.post<Priority>(this.url, priority)
      .pipe(tap(data => this.list()));
  }

  list() {
    return this.http.get<Priority[]>(this.url);
  }
  get(id: number): Observable<Priority> {
    return this.http.get<Priority>(`${this.url}${id}/`);
  }
  update(priority: Priority) {
    return this.http.put<Priority>(`${this.url}${priority.id}/`, priority);

  }
  delete(priority: Priority) {
    return this.http.delete<Priority>(`${this.url}${priority.id}/`);

  }
}
