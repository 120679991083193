import {Injectable} from '@angular/core';
import {DaoGeneric} from '@Services/base/daoService';

import {environment} from '@Env/environment';
import {HttpClient} from '@angular/common/http';
import {ProfilModel} from '../index';
import {Observable} from "rxjs";
import {Individu} from "@Services/index";
import {IndividuHelpDesk} from "../individu_helpdesk/individu-helpdesk.model";
import {CategorieHelpDesk} from "../categorie/categorie.model";

@Injectable({ providedIn: 'root' })
export class ProfilService extends DaoGeneric<ProfilModel> {
  private url = `${environment.baseUrl}/helpdesk/profil/`;

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getRootUrl(): string {
    return this.url;
  }

  /**
   * Retire une catégorie le cas échéant au profil categories_notifs
   * @param {CategorieHelpDesk} category
   * @param {IndividuHelpDesk} owner
   */
  public removeCategoryFromNotifs(category: CategorieHelpDesk, owner: IndividuHelpDesk) {
    const url = `${this.url}remove_category/`;
    const extra = new Map<string, string>();
    extra.set('categorie', category.id.toString()).set('owner', owner.uid);
    const params = this._getUrlHttpParams(extra);

    return this.httpClient.post(url, {}, {params: params});
  }

  public updateOrCreateBulk(profils: ProfilModel[]): Observable<boolean> {
    return Observable.create(observer => {
      const len = profils.length;
      let cnt = 0;

      profils.forEach((_profil) => {
        this.updateOrcreate(_profil).subscribe((_data) => {
          cnt++;

          if (len === cnt) {
            observer.next(true);
            observer.complete();
          }
        });
      });
    });
  }
}
