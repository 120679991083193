<div style="padding-left: 10px; padding-top: 10px;" fxHide.xs="True">
  <mat-expansion-panel *ngIf="isStaff()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Notes de versions
      </mat-panel-title>
    </mat-expansion-panel-header>
    2023 v3.5.2 : <br/><br/>
    <ul>
      <li>[bug fix]
        <ul>
          <li>TG-520 : la limite de 5 individus pour le filtrage CC saute : pas de limite</li>
          <li>TG-516 : la pagination ne revenait pas à la 1ère page lors du changement de panier (général => filtre avec moins de ticket)</li>
        </ul>
      </li>
    </ul>
    2023 v3.5 : <br/><br/>
    <ul>
      <li>[groupes] TG-434 : introduction des groupes pour représenter les pôles, possibilité de : mettre en CC groupe(s), message direct groupe(s)</li>
      <li>[ticket demandeur] TG-440 : tous les champs sont dorénavant paramétrables pour optionnel ou obligatoire (sauf objet et description qui restent obligatoires)</li>
      <li>[panier & filtres] TG-450 : indicateur de durée des tickets sur le panier, possibilité d'ajouter une condition sur la durée dans les filtres personnalisés</li>
      <li>[exports ticket] TG-336 : l'export Excel des tickets s'effectue en asynchrone avec envoi d'un lien pour télécharger</li>
      <li>[ticket fichiers] TG-434 : possibilité de choisir plusieurs fichiers à la fois (pour un commentaire)</li>
      <li>[filtres / widgets] TG-439
        <ul>
          <li>les filtres personnalisés sont triables via la vue panier pour les ordonner à sa convenance</li>
          <li>les filtres sont partageables pour d'autres agents ("dépôt" de filtres)</li>
          <li>les widgets peuvent être créés par copie d'un autre (duplication de widgets) </li>
        </ul>
      </li>
      <li>[machine learning] TG-500 : la ML a été enlevée du Helpdesk </li>
    </ul>
    2023 v3.0.2 : <br/><br/>
    <ul>
      <li>[ticket] TG-432 sur la vue mobile, il manquait le téléphone du demandeur</li>
      <li>[bug fix] TG-429 un lien direct peut potentiellement aller directement sur le panier selon le profil d'arrivée du connecté</li>
    </ul>
    2022 v3.0 : <br/><br/>
    <ul>
      <li>[panier] TG-126 Vue mobile pour le panier de ticket : pour les résolutions < 960 px, un bouton est proposé vers une vue simplifiée</li>
      <li>[catégories] TG-355 tout agent traitant, via son profil, peut choisir les catégories à suivre ainsi que leur notification nouveaux tickets</li>
      <li>[création ticket agent] TG-420 un champ supplémentaire est permis afin d'ajouter un demandeur : le demandeur devient le propriétaire du ticket créé</li>
      <li>[choix d'arrivée] TG-415 l'agent traitant, via son profil, peut choisir sa page d'arrivée ainsi que le panier : soit page d'accueil / soit sur le panier, soit "Mes catégories" / soit "Toutes les catégories"</li>
      <li>[cartes] TG-398 les cartes en page d'accueil sont drag & droppables, les cartes archivées sont accessibles pour les réactiver</li>
    </ul>
    2022 v2.8 : <br/><br/>
    <ul>
      <li>[tech] Montée de version Angular 12 à Angular 14</li>
      <li>[création ticket]
        <ul>
          <li>TG-356 le téléphone est paramétrable pour le formulaire demandeur : optionnel ou obligatoire</li>
          <li>TG-357 l'aide (?) pour la description du ticket est paramétrable</li>
          <li>TG-357 les * sont mis en rouge comme indiqué sur la page, la catégorie, sa description en tooltip</li>
        </ul>
      </li>
      <li>TG-388 la prévisualisation PJ n'est plus proposée pour les fichiers qui ne sont pas des images (JPG / PNG / GIF) ou PDF</li>
    </ul>
    2022 v2.7.0 : <br/><br/>
    <ul>
      <li>TG-366 Autoarchivage activé par défaut</li>
      <li>TG-369 / TG-370 un demandeur peut choisir une catégorie avec possibilité de paramétrer les catégories possible pour le demandeur pour la création d'un ticket</li>
      <li>[bug fix
        <ul>
          <li>sélection catégorie dans la liste pour le demandeur : simplification de la liste (sans recherche, liste simple)</li>
          <li>TG-371 le retour au statut Nouveau n'enlève plus l'archivage automatique</li>
        </ul>
      </li>
    </ul>
    2022 v2.6 : <br/><br/>
    <ul>
      <li>[tech] Montée de version Angular 8 à Angular 12</li>
      <li>Passage à 5 Mo des fichiers téléversés</li>
      <li>TG-334 : le téléversement / upload de fichiers s'effectue en mode "synchrone" : la page est bloquée tant que les fichiers ne sont pas téléversés, évite toute perte sur les connexions lentes</li>
      <li>Toute catégorie peut être affecté à un ticket déjà affecté, et non plus sur les catégories de l'affecté</li>
      <li>[bug fix]
        <ul>
          <li>TG-346 : le cache sur catégories, statuts et priorités peut poser un souci selon une manip (changement de page rapide) : a été enlevé en attendant une meilleure solution</li>
        </ul>
      </li>
    </ul>
    2022 v2.5.2 : fix backend<br/><br/>
    <ul>
      <li>[bug fix]
        <ul>
          <li>TG-353 : Les catégories de l'agent renvoyaient tous les tickets, tout statut confondu au lieu des statuts "autoload"</li>
          <li>TG-340 : L'ajout aux CC ne fonctionnait que pour les individus s'étant déjà connectés à la plateforme : recherche LDAP puis attribution</li>
          <li>TG-325 : Filtre conditions Mes catégories : égal et différent ramènent le nombre de tickets</li>
          <li>TG-317 : Filtres copie : les champs de type date ne sont pas bien copiés</li>
          <li>TG-314 : Filtres : il n'y a plus de restrictions de filtrage sur les catégories pour les agents</li>
        </ul>
      </li>
    </ul>
    2021 v2.5.2 : <br/><br/>
    <ul>
      <li>[bug fix]
        <ul>
          <li>TG-328 : Affectation : à la suppression d'une affectation, la liste des catégories possible sur le ticket doit être "toutes", cela restait sur les catégories de l'ancien affecté</li>
          <li>TG-329 : Commentaire et affectation : prendre en compte le 1er commentaire d'un agent, même placé après des commentaires de demandeurs pour l'affectation et l'avancement du ticket</li>
        </ul>
      </li>
    </ul>
    2021 v2.5.1 : <br/><br/>
    <ul>
      <li>TG-314 : filtres personnalisés : restrictions enlevées pour les agents sur les catégories, il peut maintenant filtrer sur toutes les catégories</li>
      <li>[bug fix]
        <ul>
          <li>TG-312 : panier : le filtre sur un demandeur provoquait une erreur (mauvaise méthode d'API appellée)</li>
          <li>TG-315 : un demandeur pouvait être affecté à un ticket s'il commentait en 1er un ticket</li>
          <li>TG-316 : le lien direct d'un ticket affiché une nouvelle page avec un fond différent</li>
          <li>TG-317 : filtres personnalisés : les champs dates n'étaient pas recopiés lors d'une copie d'un filtre</li>
          <li>TG-325 : filtres personnalisés : condition sur "Mes catégories" : égal et différent ramènaient le même nombre de tickets</li>
          <li>TG-326 : le statut d'un ticket lors d'un 1er commentaire ne passait pas forcément de "Nouveau" à "Ouvert", bug sporadique</li>
        </ul>
      </li>
    </ul>
    2020 v2.5 : <br/><br/>
    <ul>
      6 évolutions importantes : annotations, message direct à des agents & note interne, tickets liés, filtres panier, graphiques, export excel des tickets du panier (page courante) ou filtre (limite à 300 tickets)
    </ul>
    <ul>
      <li>[annotations] : l'agent traitant a la possibilité d'adjoindre des annotations à un ticket, une vue générale est disponible pour y accéder</li>
      <li>[panier] agent : possibilié d'obtenir l'export Excel de la page courante du panier</li>
      <li>[panier] demandeur : mise en avant de ses tickets et des tickets pour lesquels il est en copie</li>
      <li>[panier][filtres] Prise en charge : les tickets peuvent être triés sur ceux qui ne sont pas pris en charge (@Non affecté)</li>
      <li>[widgets] stats : widgets graphiques pour les agents : tuiles, courbes, histogrammes, histogrammes empilés, camemberts, ceci à partir de filtres paramétrés</li>
      <li>
        [filtres] : les agents traitants et superviseur peuvent créer leurs propres filtres pour interroger la base de tickets. <br/>
        Les filtres peuvent servir sur le panier ou à créer des graphes. Pour les agents, les résultats sont réduits à leurs catégories et ne peuvent filtrer un "affecté" autre qu'eux-mêmes.
      </li>
      <li>[ticket][liaisons] : les gestionnaires peuvent lier des tickets entre eux - ticket source -> multi tickets liés, soit sur un ticket existant, soit en créant un</li>
      <li>[filtres][panier] : l'utilisateur peut obtenir les conditions sous forme textuelle d'un filtre</li>
      <li>[filtres] : ajout de l'opérateur OU entre les champs</li>
      <li>[filtres] : un filtre peut être dupliqué</li>
      <li>[ticket] agent : possibilité d'écrire à d'autres agents (max 5) à partir d'un ticket + transformation en note interne le cas échéant</li>
      <li>[ticket] commentaires : au 1er commentaire d'un agent, on lui affecte</li>
      <li>[ticket] demandeur : ajout d'un téléphone</li>
      <li>[ticket] demandeur : ajustements des contacts mis en copie</li>
      <li>[bug fix]
        <ul>
          <li>[dispatcher] dans le ticket, seuls les dispatchers peuvent changer la catégorie</li>
          <li>[panier] catégorie : la liste se réduit aux catégories de l'affecté le cas échéant sinon toutes (évite potentiellement que l'affecté ne voit plus le ticket)</li>
        </ul>
      </li>
      <li>[tech]
        <ul>
          <li>Ajout de nouvelles actions : CLOSED_ARCHIVED, AUTO_ARCHIVED</li>
          <li>Refonte des logs actions : ajout des champs de contenus</li>
          <li>Quelques optimisations back et front pour le panier / ticket</li>
        </ul>
      </li>
    </ul>
    <br/>
    semaine 12 2020 v2.0.3 : <br/><br/>
    <ul>
      <li>
        [pièce jointe] on monte à 2 MO la taille des PJ autorisée pour un ticket / commentaire
      </li>
      <li>[bug fix] : [ticket] navigation : la catégorie n'est pas rechargée lors de la navigation vers un autre ticket (précédent ou suivant)</li>
    </ul>
    <br/>
    semaine 38 2019 à 6 2020 v2.0 : <br/><br/>
    <ul>
      évolutions importantes : création de tickets pour les agents, profil agent (signature, abonnement nouveaux tickets catégories, récap. quotidien), panier filtrage: favoris, non lus
    </ul>
    <ul>
      <li>[profil] les gestionnaires (agents traitants) ont accès à un paramétrage
        <ul>
          <li>signature de commentaire : permet d'appliquer une signature dans un commentaire, automatiquement ou par l'action du gestionnaire</li>
          <li>sélection des catégories dans lesquelles je suis affecté afin de recevoir un mail lorsqu'il y a un nouveau ticket dans ces dernières, pour l'instant, ne fonctionne uniquement sur le dispatching manuel (hors skynet)</li>
          <li>récapitulatif journalier : permet de m'en exclure</li>
        </ul>
      </li>
      <li>[ticket création] les agents traitants peuvent créer un ticket, avec la catégorie</li>
      <li>[ticket] prise en compte de l'IP (ou des IPs) à la création d'un ticket ou dans la gestion du ticket</li>
      <li>[ticket] la catégorie peut être modifiée directement sur le ticket</li>
      <li>[ticket] un ticket peut être activé pour l'auto archivage pour les status après le 1er (sera archivé selon le paramétrage en jours)</li>
      <li>[ticket] barre de navigation pour atteindre le ticket précédent ou suivant du panier en cours</li>
      <li>[ticket] un ticket peut être mis en favoris (flag)</li>
      <li>[panier agent] obtenir uniquement les tickets mis en favoris</li>
      <li>[panier agent] indication si fichiers attachés à la demande</li>
      <li>[panier agent] obtenir uniquement les tickets non lus</li>
      <li>[panier agent] optimisations des tickets lus / non lus</li>
      <li>[panier agent] ajout d'un filtre catégorie pour "toutes mes catégories"</li>
      <li>[panier agent] filtre "Prise en charge" : ajout d'un raccourci "@Moi-même" en haut de la liste</li>
      <li>[back-office] création d'un rôle "superviseur" moins fort que l'admin, cf. #166</li>
      <li>[notifs mails] refonte de quelques modèles de mails : récap, notif nouveau ticket en temps réel</li>
      <li>[formulaire ticket] initialisation de formulaires (de création) avec une liste de champs à saisir selon le type de connecté (demandeur / agent) : formulaires demandeur et agent basés sur cette refonte
      </li>
      <li>[stats] courbes / histo : possibilité de conditionner le count (having) du Pement de colonne</li>
      <li>[catégories admin] ajout du paramétrage "Exclure" / "Y laisser les tickets" pour futur Skynet</li>
      <li>[admin] ajout d'une page pour lister toutes les actions logs avec filtrage</li>
      <li>[logs] statuts, priorités, catégories : changement du format des old_value / new_value : de id à id##libellé (commit efb1c750 sha-1 : efb1c75020fd8ab72f0c6e0d1b20c9fa9c7051ea)</li>
      <li>[bug fix] :
        <ul>
          <li>lien direct sur le ticket, la vue n'était pas la bonne</li>
          <li>la limite du fichier attaché n'était pas pris en compte, on pouvait tout de même en mettre > 1 Mo</li>
          <li>fix effet de bord annuaire (api_gestion_universitaire.Individu) mise à unique=False sur la contrainte Individu.supann_emp_id, pour éviter un "défaut" de la synchro GRHUM / LDAP (adaguin, djoujeant, jmoreaux, esmoliakova)</li>
          <li>création d'un ticket : l'ajout d'un PJ puis on la retire de la liste (avant enregistrement), lors du clic sur "ENVOYER DEMANDE", cela restait sur la page au lieu d'aller sur le ticket nouvellement créé</li>
          <li>les PJ n'étaient pas pris en compte lors de la création d'un ticket (suite à l'ajout des contacts)</li>
        </ul>
      </li>
    </ul>
    <br/>
    semaine 33 - 34 2019 v1.3 : <br/><br/>
    <ul>
      <li>[stats] ajout de graphes histo ou courbes sur un champ de regroupement (statut, catégorie, etc), ces derniers peuvent être sur une période (jour, semaine, mois, trimestre, année) ou non</li>
    </ul>
    <br/>
    semaine 25 - 26 2019 v1.3 : <br/><br/>
    <ul>
      <li>[panier agents] possibilité d'activer le rafraichissement automatique, le paramétrage de la durée est faite par l'admin (Paramètres)</li>
      <li>[panier agents] les tickets non lus apparaissent en gras, possibilité de mettre le panier en lu / non lu</li>
      <li>[ticket] le N° du ticket est ajouté dans la "side bar" (gauche du ticket) pour les agents traitants</li>
      <li>[ticket CC] ajout de l'email dans les champs de recherche d'un CC</li>
      <li>[ticket CC] ajout dans la "tooltip" de l'uid de la personne recherchée afin de la distinguer dans les cas d'homonymes</li>
      <li>[ticket demandeur] un demandeur peut ajouter des contacts à son nouveau ticket, ces derniers auront accès au ticket pour interagir (commentaires)</li>
      <li>
        <ul>
          <li>[bugs fix] l'url vers le helpdesk dans le mail vers le contact était erronée</li>
        </ul>
      </li>
    </ul>
    <br/>
    semaine 21 2019 : <br/><br/>
    <ul>
      <li>[filtre] en tant qu'admin, je peux définir une condition sur "Catégorie" avec comme valeur "@Mes catégories"</li>
      <li>[export] en tant qu'admin, je peux exporter le résultat d'un filtre sous forme d'Excel (tickets remontés par le filtre)</li>
    </ul>
    <br/>
    semaine 18 2019 : <br/><br/>
    <ul>
      <li>[filtre] en tant qu'admin, je peux définir une condition sur "Affecté" avec comme valeur "@Connecté" (le connecté au moment de l'évaluation du filtre)</li>
      <li> tech :
        <ul>
          <li>[IE 11] correction du layout pour IE 11</li>
          <li>[optimisations] requêtes (my_tickets 44 => 18, my_categories 37 => 17, cat_active_only 41 => 21)</li>
          <li>[effet de bord] résolution effet de bord multi appels API (accumulation) liés aux filtres colonnes (singleton des filtres + subject$)</li>
          <li>nettoyage du code mort</li>
        </ul>
      </li>
    </ul>
    <br/>
    semaine 16 - 17 2019 : <br/><br/>
    <ul>
      <li>[ticket] un agent qui change le statut se voit affecter automatiquement le ticket</li>
      <li>[panier] ajout de la possibilité d'un filtre sur "demandeur"</li>
      <li>[panier] ajout de "toutes mes catégories" : tous les tickets des catégories pour lesquels l'agent est affecté</li>
      <li>[panier][fix] tout changement sur le ticket (statut, affectation, ...) provoque un raffraichissement du panier de ticket</li>
      <li>[tech] montée de versions Angular 7 & Material 7</li>
      <li>[tech] remplacement de tree (angular-tree-component) par mat-tree : provoquait une page blanche sous IE 11 (MobX)</li>
    </ul>
    <br/>
    semaine 13 - 14 - 15 2019 : <br/><br/>
    <ul>
      <li>[widget] ajout de widgets de type "camembert" pour des regroupements par type (type = statut ou priorité ou catégorie ou affecté)</li>
      <li>[cartes accueil] possibilité d'ordonner les cartes</li>
      <li>[ticket affectation] les "dispatchers" peuvent affecter un ticket à un agent de la catégorie du ticket</li>
      <li>[panier tickets agent]
        <ul>
          <li>possibilité d'avoir tous les tickets tous status confondus (par défaut, seuls les status en "autoload" sont chargés sur la catégorie)</li>
          <li>ajout d'un filtre sur la "prise en charge"</li>
        </ul>
      </li>
    </ul>
    semaine 12 2019 : <br/><br/>
    <ul>
      <li>[widgets] Une 1ère version de "tuiles" indicateurs de nombre tickets (selon le filtre paramétré), ajustements mobiles</li>
    </ul>
    semaine 10 2019 : <br/><br/>
    <ul>
      <li>[panier] ajout tris sur les colonnes "demandeur" et "prise en charge"</li>
      <li>[panier] tri de la liste des catégories pour l'affectation</li>
      <li>[optimisations] categorie : on n'inclut plus tous les tickets (ticket_objects)</li>
      <li>[bug fix] sélection d'une date à la main (sans le datepicker) : intervertisait jour et mois</li>
    </ul>
    <br/>
    Semaine 2 - 3 2019 : <br/><br/>
    <ul>
      <li>[notifications] notifs. mails
        <ul>
          <li>vers le demandeur : création ticket ou changement de statut, commentaire public d'un agent traitant</li>
          <li>vers les agents traitants (affecté ou CC à défaut) lors d'un commentaire public de la part du demandeur</li>
          <li>vers le CC ajouté</li>
        </ul>
      <li>[ticket] "ENVOYER DEMANDE" désactivé le temps de la sauvegarde / fichiers, réduit le multi poste de tickets</li>
      <li>[ticket affectation] à l'affectation, le statut avance (statut initial "Nouveau" => le suivant, uniquement dans ce cas là), ajout d'une confirmation pour l'ajout ou la supression affectation</li>
      <li>[panier tickets demandeur] ajout date de création</li>
      <li>[ticket] ajustement pour mobile sur le ticket plein (barre PJ / "Envoyer" / choix statut passe en dessous)</li>
      <li>[UX] ajout de quelques "toaster" et "spinner"</li>
      <li>[tech] actions / logs: revamping et ajout d'actions</li>
    </ul>
    <br/>
    Semaine 47 2018 : <br/><br/>
    <ul>
      <li>[cartes accueil] possibilité au "resolver" de modifier (pas d'ajout ni d'archivage), ajout de l'extension couleurs pour l'éditeur</li>
      <li>[ticket] ajout du n° de poste du demandeur (pour le staff)</li>
      <li>[front utilisateur] ajout spinner</li>
      <li>[bug fix] Souscriptions : les tris provoquaient une erreur</li>
    </ul>
    <br/>
    Semaine 43 2018 : <br/><br/>
    <ul>
      <li>[tech] panne DNS interne pour cdn.ckeditor.com => on inclut maintenant les sources dans le projet (assets)</li>
    </ul>
    <br/>
    Semaine 41 2018 : <br/><br/>
    <ul>
      <li>[mobile] ajustements écran "demande" / liste tickets</li>
      <li>[tech] montée de version DRF, finalisation @list_route / @detail_route => @action</li>
    </ul>
    <br/>
    Semaine 39 2018 : <br/><br/>
    <ul>
      <li>[ticket] ajout du numéro de ticket et d'un lien direct sur le ticket</li>
      <li>[filtres] sur un n° ticket (id), statut, priorité, date création pour la partie haute (panier) et l'historique des tickets</li>
      <li>[tris] activation des tris sur l'ensemble des tickets pour la partie haute et dans l'historique</li>
    </ul>
    <br/>
    Semaine 38 2018 : <br/><br/>
    <ul>
      <li>[tech] montée de version vers Angular 6.1.6</li>
      <li>[tech] montée de version Django 2.1 / DRF 3.8.2</li>
      <li>[bug fix]
        <ul>
          <li>[cron] correction robot programmation dans le crontab (suite renommage orni_* en skynet_*)</li>
          <li>[recherche] la recherche dans les souscriptions provoquait une erreur et ne ramenait rien</li>
        </ul>
      </li>
    </ul>
    <br/>
  </mat-expansion-panel>
</div>
