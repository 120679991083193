import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// import {CKEditorComponent} from 'ng2-ckeditor';
import {NewsFeed} from '../../services/news-feed/news-feed';
import {IndividuSafeService} from "../../services/individu_helpdesk/individu.service";
import {rolesHD} from "../../roleEnum";
import {CKEditorCustomComponentP8} from "@Common/widgets/template-editor";

@Component({
  selector: 'app-pop-up-ckeditor',
  templateUrl: './pop-up-ckeditor.component.html',
  styleUrls: ['./pop-up-ckeditor.component.scss']
})
export class PopUpCkeditorComponent implements OnInit {
  @ViewChild('monCkEditor', { static: true }) monEditor: CKEditorCustomComponentP8;

  newFeed : NewsFeed;
  isStaff: boolean = false;
  archiver: 'Archiver';

  constructor(@Inject(MAT_DIALOG_DATA) public data: NewsFeed,
              public dialogRef: MatDialogRef<PopUpCkeditorComponent>,
              private individuService: IndividuSafeService) { }

  ngOnInit() {
    this.individuService.individu$.subscribe((_ind) => {
      this.isStaff = this.individuService.hasRole(_ind, rolesHD.admin) || this.individuService.hasRole(_ind, rolesHD.superviseur) || this.individuService.hasRole(_ind, rolesHD.solver);
    });

    this.newFeed = this.data;
    this.monEditor.value = this.newFeed.content;
    this.initOrder();
  }

  doChange() {
    this.newFeed.archived = !this.newFeed.archived;
  }

  initOrder() {
    if (!this.newFeed.order) {
      this.newFeed.order = 0;
    }
  }

  config = {
    extraPlugins: 'colorbutton',
    colorButton_enableAutomatic: true,
    colorButton_enableMore: true,
    toolbar: [
      {
        name: 'basicstyles',
        items: [
          'Bold', 'Italic', 'Underline', 'Strike',
          'Subscript', 'Superscript', 'TextColor','Font','FontSize', 'BGColor', '-',
          'RemoveFormat',
        ]
      },
      {
        name: 'paragraph',
        items: [
          'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote',
          'CreateDiv', '-',
          'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
          '-', 'BidiLtr', 'BidiRtl'
        ]
      },
      {name: 'links', items: ['Link', 'Unlink']},
      {
        name: 'document',
        items: [
          'Source', '-'
        ]
      }
    ]
  };

  editorChanged() {

  }

  saveAndclose(event: any) {
    if (!this.monEditor.value) {
      this.monEditor.value = 'Veuillez remplir cette carte.';
      this.newFeed.content = this.monEditor.value;
    } else {
      this.newFeed.content = this.monEditor.value; }

    this.dialogRef.close(this.newFeed);
    this.newFeed.order = event.target.order.value;
  }

  justClose() {
    this.dialogRef.close(null);

  }
}
