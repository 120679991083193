export enum OperatorFilterEnum {
  'eq' = 1,
  'ne'= 2,
  'gt'= 3,
  'gte' = 4,
  'lt' = 5,
  'lte' = 6,
  'contains'= 7,
  'not_contains' = 8,
  'in' = 9,
  'not_in' = 10,
  'since' = 30,
  'ago' = 31,
  'empty' = 40,
  'not_empty'= 41
}
