import {Injectable} from '@angular/core';
import {TicketField} from '../../form-fields/fields.interface';
import {FormType} from '../../form-fields/form-type.enum';

/**
 * Service qui fournit la liste des champs selon le type de formulaire (demandeur | agent)
 */
@Injectable({ providedIn: 'root' })
export class FormService {
  private contactFields = [
    <TicketField> {
      name: 'title',
      mandatory: true,
      component: 'TitleTicketComponent'
    },
    <TicketField> {
      name: 'description',
      mandatory: true,
      component: 'DescriptionTicketComponent'
    },
    <TicketField> {
      name: 'phone',
      mandatory: false,
      component: 'PhoneTicketComponent'
    },
    <TicketField> {
      name: 'files',
      mandatory: false,
      component: 'FilesTicketComponent'
    },
    <TicketField> {
      name: 'category',
      mandatory: false,
      defaultValue: 2,
      filters: [{key: 'display_to_submitter', value: true.toString()}],
      component: 'AutocompleteCategorieComponent'
    },
    <TicketField> {
      name: 'contacts',
      mandatory: true,
      component: 'AddContactsComponent'
    }];

  private agentFields = [
    <TicketField> {
      name: 'title',
      mandatory: true,
      component: 'TitleTicketComponent'
    },
    <TicketField> {
      name: 'description',
      mandatory: true,
      component: 'DescriptionTicketComponent'
    },
    <TicketField> {
      name: 'applicant',
      mandatory: false,
      component: 'ApplicantComponent'
    },
    <TicketField> {
      name: 'files',
      mandatory: false,
      component: 'FilesTicketComponent'
    },
    <TicketField> {
      name: 'category',
      mandatory: false,
      defaultValue: 2,
      component: 'AutocompleteCategorieComponent'
    },
    <TicketField> {
      name: 'contacts',
      mandatory: true,
      component: 'AddContactsComponent'
    }
  ];

  /**
   * Obtient la liste des champs à afficher selon le type de formulaire
   * @param {FormType} formType
   * @param {TicketField[]} extendedFields : liste de champs supplémentaires à ajouter au formulaire AGENT
   */
  getFieldsForm(formType: FormType, extendedFields: TicketField[]) {
    switch (formType) {
      case FormType.contact:
        return this.contactFields;
      case FormType.agent:
        return [...this.agentFields, ...extendedFields];
    }
  }
}
