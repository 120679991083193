import {Injectable} from '@angular/core';
import {IndividuHelpDesk} from './individu-helpdesk.model';
import {environment} from '@Env/environment';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class IndividuHelpDeskService {
  private url = `${environment.baseUrl}/helpdesk/individu/`;

  constructor(private http: HttpClient) {
  }

  list() {
    return this.http.get<IndividuHelpDesk[]>(this.url);
  }

  searchByString(val: string) {
    const params = {};
    params['has_in_name']= val;
    return this.http.get<IndividuHelpDesk[]>(this.url, {params: params});
  }

  get(id: number) {
    return this.http.get<IndividuHelpDesk>(`${this.url}${id}/`);
  }


}
