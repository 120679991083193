import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {NewTicketComponent} from './new-ticket/new-ticket.component';
import {OverviewTicketsComponent} from './overview-tickets/overview-tickets.component';
import {PersonnelComponent} from './personnel.component';
import {FlexLayoutModule} from '@angular/flex-layout';

import { QuillModule } from 'ngx-quill';


import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PersonnelRoutingModule} from './personnel-routing.module';
import {HelpdeskCommonModule} from '../common/helpdesk-common.module';
import { TicketViewEncapsulerComponent } from './ticket-view-encapsuler/ticket-view-encapsuler.component';
import {PieceJointeUploaderHDModule} from '../common/piece-jointe-uploader/piece-jointe-uploader.module';
import { MatAngularModule } from '@Common/mat-angular.module';
import {CommonModuleP8} from "@Common/common-p8.module";
// import { AddContactsComponent } from './new-ticket/add-contacts/add-contacts.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,

    FormsModule,
    ReactiveFormsModule,
    PersonnelRoutingModule,
    HelpdeskCommonModule,
    PieceJointeUploaderHDModule,
    MatAngularModule,
    CommonModuleP8,
    QuillModule.forRoot()
  ],
  declarations: [
    NewTicketComponent,
    OverviewTicketsComponent,
    PersonnelComponent,
    TicketViewEncapsulerComponent,
    // AddContactsComponent,

  ],
  exports:[
    OverviewTicketsComponent
  ]
})
export class PersonnelModule { }
