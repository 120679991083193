import {Pipe, PipeTransform} from '@angular/core';
/*
 * Conversion retour chariot (\n, \r, \r\n) en <br/>
*/
@Pipe({ name: 'newline' })
export class NewlinePipe implements PipeTransform {
  transform(value: string, args: string[] = null): any {
    if (value) {
      return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }
    return value;
  }
}
