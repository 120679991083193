import {Component, Input, OnDestroy, OnInit} from '@angular/core';


import {NavComponent} from "@Common/home/navbar/dynamic-core-components/component-interface.component";
import {Subscription, BehaviorSubject, timer, forkJoin} from "rxjs";

import { Router} from "@angular/router";
import {PubSubService} from "../pub-sub.service";
import {TicketService} from "../services";
import {SubSink} from "@Common/core/utils/subsink";
import {ParameterService} from "../services/parameter/parameter.service";


@Component({
  templateUrl: './notifications-component.html',
  styles: []
})
export class NotifsHDNavComponent implements NavComponent, OnInit, OnDestroy {
  @Input() data: any;

  subRefreshTimer: Subscription;
  subPub: Subscription;

  subject: BehaviorSubject<any[]> = new BehaviorSubject(null);

  nbNotifs: number = 0;

  minute = 60000;
  delay = 0;

  subSink = new SubSink();

  constructor(private router: Router,
              private pubsubService: PubSubService,
              private paramService: ParameterService,
              private ticketSvc: TicketService) {

  }

  ngOnInit() {
    this.loadParameter();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
    if (this.subRefreshTimer) {
      this.subRefreshTimer.unsubscribe();
    }
  }

  loadParameter() {
    this.subSink.sink = forkJoin([
        this.paramService.getByName('notif-new'),
        this.paramService.getByName('notif-delay')])
     .subscribe(
      (val) => {
        const refresh = +val[0][0].value;
        const delay = +val[1][0].value;

        this.initTimer(refresh, delay);
      });
  }

  /**
   * TODO : voir si l'on peut utiliser le service LiveUpdateService à la place
   * @param value
   * @param delay
   */
  initTimer(value: number, delay: number = 0) {
    this.delay = delay;

    if (this.subRefreshTimer) {
      this.subRefreshTimer.unsubscribe();
    }

    const source = timer(value * this.minute, value * this.minute);
    this.subRefreshTimer = source.subscribe(() => this.getNotifs(delay));

    this.getNotifs(delay);
  }

  getNotifs(delay: number) {
    this.nbNotifs = 0;

    let urlQS = new Map<string, any>();
    urlQS.set('delay', delay);

    this.subSink.sink = this.ticketSvc.getCountMyCategories(urlQS).subscribe((data) => {
      if (data) {
        console.log(data);

        this.nbNotifs = data.count;
        this.subject.next(data);

        // if(data && data.list.length > 0) {
        //   this.notifs = data.list;
        //   this.nbNotifs = data.total;
        //
        //   this.subject.next(this.notifs);
        //   // this.pubsubService.publish(typeEvents.consultsOffDay, { notifs: this.notifs, total: this.nbNotifs });
        // } else
        //   this.notifs = [];
      }
    });
  }

  getToolTip() {
    let tooltip = '';
    let prefix = 'Nombre de tickets de vos catégories ';

    if (this.delay >= 0) {
      tooltip = `${prefix} du jour`;
    }

    if (this.delay === -1) {
      tooltip = `${prefix} depuis hier`;
    }

    if (this.delay < -1) {
      tooltip = `${prefix} depuis ${Math.abs(this.delay)} jours`;
    }

    // tooltip += ', aller sur le panier de tickets';
    return tooltip;
  }

  goPanier() {
    this.router.navigateByUrl('/solver/ux-content');
  }
}
