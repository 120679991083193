import {BehaviorSubject, Observable} from 'rxjs';
import {Ticket} from './ticket.model';
import {DataSource} from '@angular/cdk/collections';

export class TicketDataSource extends DataSource<Ticket> {
  public dataChange: BehaviorSubject<Ticket[]> = new BehaviorSubject<Ticket[]>([]);


  get data(): Ticket[] {
    return this.dataChange.value;
  }

  constructor() {
    super();
  }

  load(tickets: Ticket[]) {
    // tickets = tickets.sort((t1, t2) => t1.id - t2.id);
    this.dataChange.next(tickets);
  }

  update(newTickets: Ticket[]) {

    const oldTicketsToKeep = this.filterOldToKeep(this.dataChange.value.slice(), newTickets);
    const newTicketsToAdd = this.filterNewToAdd(this.dataChange.value.slice(), newTickets);


    let result: Ticket[] = [];
    result = result.concat(oldTicketsToKeep).concat(newTicketsToAdd);
    // result = result.sort((t1, t2) => t1.id - t2.id);


    this.dataChange.next(result);
  }

  filterOldToKeep(oldTickets: Ticket[], newTickets: Ticket[]): Ticket[] {
    return oldTickets
      .filter(oldticket => newTickets
        .find(newticket => newticket.id === oldticket.id));
  }

  filterNewToAdd(oldTickets: Ticket[], newTickets: Ticket[]): Ticket[] {
    return newTickets
      .filter(newticket => !oldTickets
        .find(oldticket => newticket.id === oldticket.id));
  }

  remove(ticket: Ticket, tickets: Ticket[]): Ticket[] {

    const index = tickets.indexOf(ticket);
    if (index > -1) {
      tickets = tickets.splice(index, 1);
    }
    return tickets;
  }


  connect(): Observable<Ticket[]> {
    return this.dataChange;
  }

  disconnect(): void {
  }


}
