import { Injectable } from '@angular/core';

import {CategorieModel} from './categorie.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CategorieService {
  private url = `${environment.baseUrl}/api_faq/categories/`;
  constructor(private http: HttpClient) { }

  list(offset: number, limit: number, pere: number= null, order: string = null) {
      const params = new HttpParams()
        .set('ordering', (order ? order.toString() : ''))
        .set('by_parent', (pere ? pere.toString() : ''))
        .set('offset', offset.toString())
        .set('limit', limit.toString());
    return this.http.get<CategorieModel[]>(this.url, {params});
  }

  get(id: number) {
    return this.http.get<CategorieModel>(`${this.url}${id}/`);
  }

  reload(url: string) {
    return this.http.get<CategorieModel[]>(url);
  }

  getParent(categorie_id: number) {
    const params = new HttpParams()
      .set('categorie', categorie_id.toString());
    return this.http.get<CategorieModel[]>(`${this.url}get_parent/`, {params});
  }

  getRoot(offset: number, limit: number) {
    const params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString());
    return this.http.get<CategorieModel[]>(`${this.url}get_root/`, {params});
  }
}
