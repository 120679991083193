<div fxLayout="column" class="comment-canvas" [style.background-color]="bgColor">

  <div class="comment-header" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="mat-card-subtitle">
      <span class="mat-body-1 name">{{ comment?.individu?.first_name }} {{ comment?.individu?.last_name }}</span>
      <span>{{ comment?.created | date: 'medium' }}</span>
    </span>
    <span class="fill-space"></span>
    <ng-container *ngIf="comment?.publique && !(modeEdit || isMine)">
      <div class="comment-type">publique</div> <mat-icon class="comment-type">people</mat-icon>
    </ng-container>
    <ng-container *ngIf="!comment?.publique && !(modeEdit || isMine)">
      <div class="comment-type">note interne</div> <mat-icon class="comment-type">person</mat-icon>
    </ng-container>
    <button mat-icon-button
            *ngIf="modeEdit"
            matTooltip="Enregistrer"
            (click)="updateComment()">
      <mat-icon>save</mat-icon>
    </button>
    <button mat-icon-button
            matTooltip="Modifier"
            *ngIf="isMine && !modeEdit"
            (click)="toggleEditMode()">
      <mat-icon>create</mat-icon>
    </button>
    <button mat-icon-button
            matTooltip="Annuler"
            *ngIf="modeEdit"
            (click)="toggleEditMode()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="comment-body" *ngIf="!modeEdit" [innerHTML]="comment.content"></div>
  <div class="comment-body" *ngIf="modeEdit" >
    <quill-editor [(ngModel)]="comment.content"
                  [modules]="modules"
                  [formats]="formats">
    </quill-editor>
  </div>
  <app-attached-files [layout]="layoutFiles"
                             [piecesJointes]="comment?.pieces_jointes"
                             [truncateName]="20"
                             [preview]="previewFiles"></app-attached-files>
</div>


<mat-divider></mat-divider>

