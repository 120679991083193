import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanLoad, Route, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {IndividuSafeService} from '../common/services/individu_helpdesk/individu.service';

@Injectable({   providedIn: 'root' })
export class SolverGuardService implements CanLoad {


  constructor(private individuService: IndividuSafeService) {
  }

  canLoad(route: Route): Observable<boolean> {
    return this.isSolver();

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.isSolver();
  }


  private isSolver(): Observable<boolean> {
    return Observable.create(observer => {
      this.individuService.individu$.subscribe(_individu => {
          observer.next(this.individuService.hasRole(_individu, 'solver'));
          observer.complete();
        }
      );
    });
  }
}
