<div fxLayout="column" class="comment-canvas" [style.background-color]="bgColor">

  <div class="comment-header" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="mat-card-subtitle">
<!--      <span class="mat-body-1 name">{{ annotation.agent_obj.first_name }} {{ annotation.agent_obj.last_name }}</span>-->
      <span *ngIf="!annotation.dt_updated">{{ annotation.dt_created | date: 'medium' }}</span>
      <span *ngIf="annotation.dt_updated">{{ annotation.dt_updated | date: 'medium' }}</span>
    </span>
    <span class="fill-space"></span>
    <button mat-icon-button
            *ngIf="modeEdit"
            matTooltip="Enregistrer"
            (click)="updateAnnotation()">
      <mat-icon>save</mat-icon>
    </button>
    <button mat-icon-button
            matTooltip="Supprimer"
            *ngIf="isMine && !modeEdit"
            (click)="deleteAnnotation()">
      <mat-icon>delete</mat-icon>
    </button>
    <button mat-icon-button
            matTooltip="Modifier"
            *ngIf="isMine && !modeEdit"
            (click)="toggleEditMode()">
      <mat-icon>create</mat-icon>
    </button>
    <button mat-icon-button
            matTooltip="Annuler"
            *ngIf="modeEdit"
            (click)="toggleEditMode()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="comment-body" *ngIf="!modeEdit" [innerHTML]="annotation.content"></div>
  <div class="comment-body" *ngIf="modeEdit" >
    <quill-editor [(ngModel)]="annotation.content"
                  [modules]="modules"
                  [formats]="formats">
    </quill-editor>
  </div>
</div>
<mat-divider></mat-divider>
