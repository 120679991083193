import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Ticket, TicketService} from "../../../common/services";
import {SubSink} from "@Common/core/utils/subsink";
import {Subject} from "rxjs";
import {TicketChangeStream} from "../../../common/ticket-change-stream.model";

@Component({
  selector: 'app-ux-ticket-view-modale',
  templateUrl: './ux-ticket-view-modale.component.html',
  styleUrls: ['./ux-ticket-view-modale.component.scss']
})
export class UxTicketViewModaleComponent implements OnInit {
  ticketId: number;
  ticket: Ticket;
  subSink = new SubSink();
  isVisible: boolean = false;

  ticketChangeStream: Subject<TicketChangeStream> = new Subject<TicketChangeStream>();

  constructor(private ticketSvc: TicketService,
              @Inject(MAT_DIALOG_DATA) public data: number,
              public dialogRef: MatDialogRef<UxTicketViewModaleComponent>) {
    this.ticketId = data;
  }

  ngOnInit() {
    this.subSink.sink = this.ticketSvc.get(this.ticketId).subscribe((ticket) => {
      this.ticket = ticket;
      this.isVisible = true;
    });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
