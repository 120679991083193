import {Component, Input, OnInit} from '@angular/core';
import {allowedExtensionsForPreview, PieceJointe, PiecejointeDownloadService, Ticket} from '../../services';
import * as FileSaver from 'file-saver';
import {SubSink} from '@Common/core/utils/subsink';
import {ImagePreviewComponent} from '../../image-preview/image-preview.component';
import {MatDialog} from '@angular/material/dialog';

/**
 * Affichage d'une liste de fichiers avec l'option prévisualisation
 */
@Component({
  selector: 'app-attached-files',
  templateUrl: './attached-files.component.html',
  styleUrls: ['./attached-files.component.scss']
})
export class AttachedFilesComponent implements OnInit {
  @Input() piecesJointes: PieceJointe[];
  /**
   * Preview des fichiers ?
   */
  @Input() preview = true;
  /**
   * Nom du fichier tronqué ?
   * 0 : non sinon tronqué de truncateName
   */
  @Input() truncateName = 0;
  /**
   * Disposition de la liste des fichiers
   */
  @Input() layout: 'vertical' | 'horizontal' = 'horizontal';
  subSink = new SubSink();
  constructor(public dialog: MatDialog,
              private pieceJointeDownload: PiecejointeDownloadService) { }

  ngOnInit(): void {
  }
  getLayout() {
    return this.layout === 'horizontal' ? 'row' : 'column';
  }
  getPadding() {
    if (this.layout === 'horizontal') {
      return '0 2px 0 0';
    }
    return '0 0 2px 0';
  }
  downloadPieceJointe(file: PieceJointe) {
    this.subSink.sink = this.pieceJointeDownload.getFile(file.id).subscribe(data => {
      FileSaver.saveAs(data, file.filename);
    });
  }
  popUp(_blob: any) {
    this.dialog.open(ImagePreviewComponent, { data: _blob });
  }
  popUpPreviewImage(file: PieceJointe) {
    this.subSink.sink = this.pieceJointeDownload.getFile(file.id).subscribe(data => {
      if (data) {
        this.popUp(data);
      }
    });
  }
  pieceJointeIsImageOrPdf(file: PieceJointe) {
    if (file && file.filename) {
      const ext = file.filename.split('.').pop();
      return (allowedExtensionsForPreview.indexOf(ext.toLowerCase()) !== -1);
    }
    return false;
  }
}
