import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';

import {AppComponent} from './app.component';
import {CoreModule} from '@Common/core/core.module';

import {CommonModuleP8} from '@Common/common-p8.module';
import {AppRoutingModule} from './app-routing';

import {HandlerError} from '@Common/handler-error';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AcceuilComponent} from './acceuil/acceuil.component';
import {CommonModule, registerLocaleData} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PieceJointeUploaderModule} from '@Common/widgets/piece-jointe-uploader/piece-jointe-uploader.module';
import {AnonymousComponent} from './anonymous/anonymous.component';
import {HelpdeskGuardsModule} from './helpdesk-guards/helpdesk-guards.module';
import {HelpdeskCommonModule} from './common/helpdesk-common.module';
import { MatAngularModule } from '@Common/mat-angular.module';
import {PubSubService} from './common/pub-sub.service';
import {PersonnelModule} from './client/personnel.module';
import { MatTreeModule } from '@angular/material/tree';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {HelpdeskHeaderFilterValueComponent} from './common/widgets/header-filter/helpdesk-header-filter-value.component';
import {HelpdeskHeaderFilterListComponent} from './common/widgets/header-filter/helpdesk-header-filter-list.component';
import {HelpdeskHeaderFilterDateComponent} from './common/widgets/header-filter/helpdesk-header-filter-date.component';
import {HelpdeskHeaderFilterSearchAndSelectComponent} from './common/widgets/header-filter/helpdesk-header-filter-search-and-select.component';
import {HelpdeskHeaderFilterListMultiSelectComponent} from './common/widgets/header-filter/helpdesk-header-filter-list-multi-select.component';
import { NotesVersionsComponent } from './acceuil/notes-versions/notes-versions.component';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

@NgModule({
    declarations: [
        AppComponent,
        AcceuilComponent,
        AnonymousComponent,
        HelpdeskHeaderFilterValueComponent,
        HelpdeskHeaderFilterListComponent,
        HelpdeskHeaderFilterDateComponent,
        HelpdeskHeaderFilterSearchAndSelectComponent,
        HelpdeskHeaderFilterListMultiSelectComponent,
        NotesVersionsComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        CoreModule,
        HelpdeskGuardsModule,
        CommonModuleP8,
        AppRoutingModule,
        FlexLayoutModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PieceJointeUploaderModule,
        HelpdeskCommonModule,
        MatAngularModule,
        PersonnelModule,
        MatTreeModule,
        MatInputModule,
        MatFormFieldModule,
    ],
    providers: [
        PubSubService,
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        { provide: ErrorHandler, useClass: HandlerError },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
