import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {environment} from '@Env/environment';
import {AuthService} from '@Services/auth/auth.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import {Individu} from '@Services/index';
import {IndividuHelpDesk} from "./individu-helpdesk.model";

@Injectable({   providedIn: 'root' })
export class IndividuSafeService {
  private url = `${environment.baseUrl}/helpdesk/individu_ldap_safe/`;
  private individuSource = new ReplaySubject<Individu>(1);
  individu$ = this.individuSource.asObservable();

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.userActivate$.pipe(filter(user => !!user), mergeMap(user => this.get(user.username))).subscribe(individu => {
      this.individuSource.next(individu as Individu);
    });
  }

  create(individu: Individu) {
    return this.http.post<Individu>(this.url, individu).pipe(tap(data => this.list()));
  }

  getOrCreate(uid: string) {
    return this.http.get<Individu>(`${this.url}${uid}/get_or_create/`);
  }

  list(extraQS: Map<string, any> = null) {
    const options = this.getURLParams(extraQS);

    return this.http.get<Individu[]>(this.url, {params: options});
  }

  get(uid: string) {
    return this.http.get<Individu>(`${this.url}${uid}/`);
  }

  update(individu: Individu) {
    return this.http.put<Individu>(`${this.url}${individu.uid}/`, individu);
  }

  delete(individu: Individu) {
    return this.http.delete<Individu>(`${this.url}${individu.uid}/`);
  }

  /**
   * Recherche d'une liste d'uids dans individu
   * @param uids
   * @return IndividuHelpDesk[]
   */
  lookupIn(uids: string[]) {
    if (!uids) {
      return of([]);
    }
    const url = `${this.url}lookup_uids_in/`;
    const value = uids.reduce((acc, currentValue, i) => {
      return i === 0 ? currentValue : `${acc},${currentValue}`;
    }, '');
    let params = new HttpParams();
    params = params.append('in', value);
    return this.http.get<IndividuHelpDesk[]>(url, {params: params});
  }

  hasPermission(individu: Individu, application_id: string, code: string []): boolean {
    const list_permissions = individu.list_permissions;
    for (const permission of list_permissions) {
      if (permission.application_id === application_id && code.includes(permission.code)) {
        return true;
      }
    }
    return false;
  }

  hasRole(individu: Individu, role: string): boolean {
    for (const role_entite of individu.list_roles_entite) {
      if (role === role_entite.code) {
        return true;
      }
    }
    return false;
  }

  getIdRole(individu: Individu, role: string): number {
    for (const role_entite of individu.list_roles_entite) {
      if (role === role_entite.code || role_entite.code === 'gestionnaire-adm') {
        return role_entite.id;
      }
    }
    return null;
  }

  getClientIp(): Observable<string> {
    const url = `${this.url}get_client_ip/`;
    return this.http.get<string>(url);
  }

  private getURLParams(extraQS) {
    const urlQS = {};

    if(extraQS !== null) {
      extraQS.forEach((v, k) => {
        urlQS[k] = v;
      });
    }

    return urlQS;
  }

}
