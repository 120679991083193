import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {
  CommentService,
  CommentTicket,
  PieceJointe,
  ProfilEnum,
  ProfilModel,
  ProfilService,
  Status,
  StatusService,
  Ticket,
  TicketService
} from '../../services';
import {Subject} from 'rxjs';
import {AccountLevelService} from '../../account-level/account-level.service';
import {PubSubService} from '../../pub-sub.service';
import {typeEvents} from '../../toasty/type-events';
import {concatMap, finalize} from 'rxjs/operators';
import {DialogData, DialogValidationComponent} from '@Common/widgets/general/dialog-validation';
import {MatDialog} from '@angular/material/dialog';
import {SubSink} from '@Common/core/utils/subsink';
import {Individu, IndividuService} from '@Common/core/services';
import {ActionTicketChangeStream, TicketChangeStream} from '../../ticket-change-stream.model';
import {Annotation} from '../../services/ticket/annotation.model';
import {AnnotationService} from '../../services/ticket/annotation.service';
import {TabEditorIndex, TabListIndex} from '../../tabs.enums';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {SnackBarToastyService} from '@Common/widgets/general/snack-bar';
import {CommentTypeDisplay} from './comment-type.enum';


@Component({
  selector: 'app-ux-new-comment',
  templateUrl: './ux-new-comment.component.html',
  styleUrls: ['./ux-new-comment.component.scss']
})
export class UxNewCommentComponent implements OnInit, OnDestroy {
  @Input()
  ticketChangeStream: Subject<TicketChangeStream>;
  /**
   * Doit-on afficher les onglets note interne | annotation
   */
  @Input() displayTabType = CommentTypeDisplay.all;
  displayTypesEnum = CommentTypeDisplay;
  /**
   * Boutons en icones ? "Prendre en charge" / "Envoyer"
   */
  @Input() buttonsIcons = false;

  @Input()
  ticket: Ticket;

  @Output()
  commentadded: EventEmitter<CommentTicket> = new EventEmitter<CommentTicket>();

  statuses: Status[];
  statusesToDisplay: Status[];

  comment: CommentTicket = <CommentTicket>{};

  signature: ProfilModel;

  /**
   * Signal pour le démarrage de l'upload fichier dans un commentaire
   */
  uploaderConnector: Subject<any> = new Subject();
  resetFiles: Subject<any> = new Subject();
  hasPJs = false;
  uploading = false;

  subSink = new SubSink();

  /**
   * Indice de l'onglet suivant le type com / note / annotations
   */
  INDEX_COMM_PUBLIC = 0;
  INDEX_NOTE_INTERNE = 1;
  INDEX_ANNOTATIONS = 2;

  selectedTab = this.INDEX_COMM_PUBLIC;

  public user: any;

  formats = [
    'bold',
    'italic',
    'blockquote',
    'code-block',
    'list',
    'indent',
    'link',
  ];

  modules = {
    toolbar: {
      container: [
        ['bold', 'italic'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

        ['link'],         // link and image, video
      ],
    }
  };

  connecte: Individu;

  annotation: Annotation = {
    id: null,
    content: null,
    ticket: null,
    agent: null,
  };
  loading = false;
  private editor;

  constructor(public dialog: MatDialog, private elem: ElementRef,
              private snackService: SnackBarToastyService,
              private statusService: StatusService,
              private commentService: CommentService,
              private ticketService: TicketService,
              private accountLevel: AccountLevelService,
              private profilSvc: ProfilService,
              private individuSvc: IndividuService,
              private pubSubSvc: PubSubService,
              private annotationSvc: AnnotationService) {
  }

  ngOnInit() {
    this._setUser();
    this._fetchStatuses();
    this._getConnecteAndSignature();
    this._subTicketChangeStream();
    this._onTabEditorCommentOrAnnotation();
  }
  /**
   * Ajoute (manuellement) la signature au message
   * @param event
   */
  addSignature(event = null) {
    if (this.signature.settings.text) {
      if (this.comment.content) {
        this.comment.content += this.signature.settings.text;
      } else {
        this.comment.content = this.signature.settings.text;
      }
    }
  }
  ticketIsAssignmentedToMe(ticket: Ticket) {
    return this.user.username === ticket.affectation_object?.uid;
  }
  isStaff() {
    return this.accountLevel.isStaff();
  }
  /**
   * Action d'envoi d'un commentaire au changement de statut (menu statuts)
   * Si pas de commentaire à envoyer, seul le statut change
   * @param newstatus
   */
  sendCommentaireOrStatut(newstatus: Status = this.ticket.status_object) {
    this._autoAffectAndStatus(newstatus);
    this._sendCommentaire();
  }
  /**
   * Envoi commentaire ou annotation
   */
  sendCommentaireOrAnnotation() {
    if (this.selectedTab < this.INDEX_ANNOTATIONS) {
      this._sendCommentaire();
    }
    if (this.selectedTab === this.INDEX_ANNOTATIONS) {
      this._sendAnnotation();
    }
  }
  /**
   * S'affecter manuellement le ticket
   */
  assignMe() {
    const data = new DialogData();
    data.title = 'Affectation';
    data.message = `Confirmez-vous votre affectation à ce ticket ?`;

    const dialogRef = this.dialog.open(DialogValidationComponent, { data: data, disableClose: true});
    dialogRef.updatePosition({top: '100px'});
    this.subSink.sink = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.subSink.sink = this.ticketService.addAffectation(this.ticket)
          .pipe(
            concatMap((ticket) => this.ticketService.changeFirstStatus(ticket, this.statuses)),
            finalize(() => this.loading = false))
          .subscribe((_ticket: Ticket) => this._triggerChangedTicket(_ticket));
      }
    });
  }

  /**
   * Enlever son affectation
   */
  unassignMe() {
    const data = new DialogData();
    data.title = 'Enlever mon affectation';
    data.message = `Confirmez-vous de ne plus vouloir être affecté à ce ticket ?`;

    const dialogRef = this.dialog.open(DialogValidationComponent, { data: data, disableClose: true});
    dialogRef.updatePosition({top: '100px'});
    this.subSink.sink = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.subSink.sink = this.ticketService
          .removeAffectation(this.ticket)
          .pipe(finalize(() => this.loading = false))
          .subscribe((_ticket: Ticket) => this._triggerChangedTicket(_ticket));
      }
    });
  }

  onEditorCreated(quill) {
    this.editor = quill;
  }
  /**
   * Traitement fin d'upload de fichier
   * @param pjs
   */
  handleFilesUploaded(pjs: PieceJointe[]): void {
    this.comment.pieces_jointes = pjs;
    this.commentadded.emit(this.comment);
    this.hasPJs = false;
    this.uploading = false;
    this._shouldTicketAdvanceOnFirstComment(this.comment);
    this._doUpdateTicket();
    this.pubSubSvc.publish('loading', false);
    this.pubSubSvc.publish(typeEvents.addedComment, 'Commentaire bien ajouté');
    this.comment = <CommentTicket>{};
  }
  /**
   * Mise à jour si s'il y aura des fichiers à téléverser
   * @param pjs
   */
  setHasPjs(pjs: PieceJointe[]) {
    this.hasPJs = pjs.length > 0;
  }
  /**
   * Couleur de fond quill editor
   */
  getClass() {
    if (this.selectedTab === this.INDEX_NOTE_INTERNE) {
      return 'note_container';
    }
    if (this.selectedTab === this.INDEX_ANNOTATIONS) {
      return 'annotation_container';
    }
    return '';
  }

  /**
   * Placeholder texte quill editor
   */
  getPlaceholder() {
    switch (this.selectedTab) {
      case this.INDEX_COMM_PUBLIC:
        return 'Écrire votre réponse ici...';
      case this.INDEX_NOTE_INTERNE :
        return 'Écrire une note interne ici...';
      case this.INDEX_ANNOTATIONS :
        return 'Écrire une annotation ici...';
    }
    return '';
  }
  canDisplayTabType(type: CommentTypeDisplay) {
    // tslint:disable-next-line:no-bitwise
    return (this.displayTabType & type) === type;
  }
  private _triggerChangedTicket(_ticket) {
    const ticketChangeStream = <TicketChangeStream> {
      ticket: _ticket,
      action: ActionTicketChangeStream.save
    };
    this.ticket = _ticket;
    this.ticketChangeStream.next(ticketChangeStream);
  }
  /**
   * Init sub ticketChangeStream
   * @private
   */
  private _subTicketChangeStream() {
    if (this.ticketChangeStream) {
      this.ticketChangeStream.subscribe((_ticket: TicketChangeStream) => {
        this._removeStatusItSelf(_ticket.ticket.status_object);
      });
    }
  }

  /**
   * si signature automatique => mise directement dans le corps du commentaire
   * @param _configs
   * @private
   */
  private _init_signature(_configs) {
    if (_configs.length > 0) {
      this.signature = _configs.find((_config: ProfilModel) => _config.key === ProfilEnum.signature) as ProfilModel;
      if (this.signature && this.signature.settings.activate && this.signature.settings.auto) {
          this.comment.content = this.signature.settings.text;
      }
    }
  }
  /**
   * Obtient la signature de l'agent le cas échéant
   * @private
   */
  private _getConnecteAndSignature() {
    this.subSink.sink = this.individuSvc.individu$.subscribe(_individu => {
      this.connecte = _individu;
      const extra = new Map<string, string>();
      extra.set('owner', this.connecte.uid);
      this.subSink.sink = this.profilSvc
        .listAllItems(extra)
        .subscribe(_configs => this._init_signature(_configs.list));
    });
  }
  /**
   * Liste des statuts disponibles
   */
  private _fetchStatuses() {
    this.subSink.sink = this.statusService.list('', '').subscribe(data => {
        this.statuses = data;
        this._removeStatusItSelf(this.ticket.status_object);
      });
  }

  /**
   * Décodage HTML
   * @param input
   * @private
   */
  private _decodeHtml(input: string) {
    if (input) {
      return this._htmlDecode(input).replace(/[\n\r]+/g, '');
    }
    return '';
  }
  /**
   * On vérifie qu'il n'y a pas seulement la signature dans le commentaire
   * @private
   */
  private _hasOnlySignature() {
    let sign = this.signature && this.signature.settings && this.signature.settings.text || '';
    if (sign) {
      const comm = this._decodeHtml(this.comment.content.trim());
      sign = this._decodeHtml(sign.trim());
      if (sign === comm) {
        return true;
      }
    }
    return false;
  }

  /**
   * Init user à partir du localStorage
   * @private
   */
  private _setUser() {
    this.user = JSON.parse(localStorage.getItem('user'));
  }
  /**
   * Envoi d'une annotation
   */
  private _sendAnnotation() {
    if (this._hasOnlySignature()) {
      // this.snackService.toasty('Commentaire', 'Veuillez écrire un texte autre que la signature seule', typeToast.info);
      return;
    }
    this.annotation.ticket = this.ticket.id;
    this.annotation.content = this.comment.content;
    this.annotation.agent = this.user.username;
    this.pubSubSvc.publish('loading', true);
    this.subSink.sink = this.annotationSvc.create(this.annotation)
      .pipe(finalize(() => this.pubSubSvc.publish('loading', false)))
      .subscribe((annotation) => {
        this.pubSubSvc.publish(typeEvents.addedAnnotation, 'Annotation bien ajoutée');
        this._emitTicketChangeStream(this.ticket, ActionTicketChangeStream.load);
        this.comment = <CommentTicket>{};
      });
  }
  /**
   * Le statut avance-t-il au premier commentaire public et si le ticket n'est pas encore affecté ?
   * @param comment
   */
  private _shouldTicketAdvanceOnFirstComment(comment: CommentTicket) {
    if (this.isStaff() && comment.publique && ! this.ticket.affectation) {
      // Si le status a déjà changé on ne réaffecte pas le ticket
      if (this.ticket.status_object && this.ticketService.isFirstStatus(this.ticket.status_object, this.statuses)) {
        this.subSink.sink = this.ticketService
          .changeFirstStatus(this.ticket, this.statuses)
          .subscribe(data => this._autoAffectAndStatus(data.status_object));
      }
    }
  }
  /**
   * Démarrage upload fichier le cas échéant
   * @param comment
   */
  private _changeSubmitted(comment: CommentTicket) {
    if (comment) {
      this.uploaderConnector.next(comment);
      this.comment = comment;
    }
  }
  /**
   * Envoi d'un commentaire / création, public ou privé
   */
  private _sendCommentaire() {
    this.comment.publique = this.selectedTab <= this.INDEX_COMM_PUBLIC;
    this.comment.ticket = this.ticket.id;

    if (this.comment.content && this.comment.content.length > 0) {
      if (this._hasOnlySignature()) {
        return;
      }
      this.pubSubSvc.publish('loading', true);
      this.subSink.sink = this.commentService.create(this.comment)
        // .pipe(finalize(() => this.pubSubSvc.publish('loading', false)))
        .subscribe((data: CommentTicket) => {
          console.log('comment ! ', data);
          this._changeSubmitted(data);
          if (!this.hasPJs) {
            this.pubSubSvc.publish('loading', false);
            this.commentadded.emit(data);
            this.pubSubSvc.publish(typeEvents.addedComment, 'Commentaire bien ajouté');
            this._shouldTicketAdvanceOnFirstComment(data); // point chaud
            this.comment = <CommentTicket>{};
          } else {
            this.pubSubSvc.publish('loading', true);
            this.uploading = true;
          }
        }, (error) => this.pubSubSvc.publish('loading', false));
    }
  }
  /**
   * Enlève les balises HTML d'un contenu => contenu "raw" / brut
   * @param input
   */
  private _htmlDecode(input) {
    const e = document.createElement('textarea');
    e.innerHTML = input;
    return e.value;
  }
  /**
   * Emission ticket pour rechargement
   * @param _ticket
   * @param action
   * @private
   */
  private _emitTicketChangeStream(_ticket: Ticket, action: ActionTicketChangeStream = ActionTicketChangeStream.save) {
    const ticketChangeStream = <TicketChangeStream> {
      ticket: _ticket,
      action: action
    };
    if (this.ticketChangeStream) {
      this.ticketChangeStream.next(ticketChangeStream);
    }
  }
  /**
   * Mise à jour du ticket et émission de ticketChangeStream
   */
  private _doUpdateTicket() {
    this.pubSubSvc.publish('loading', true);
    this.subSink.sink = this.ticketService.update(this.ticket)
      .pipe(finalize(() => this.pubSubSvc.publish('loading', false)))
      .subscribe((_ticket: Ticket) => {
        this._emitTicketChangeStream(_ticket);
        this._fetchStatuses();
        this.resetFiles.next();
      });
  }
  /**
   * On enlève des propositions de statuts le statut en cours du ticket
   * @param s
   */
  private _removeStatusItSelf(s: Status) {
    const statuses = this.statuses?.map(x => Object.assign({}, x));
    const index = this.statuses?.findIndex(st => st.id === s.id);
    if (index > -1) {
      statuses.splice(index, 1);
    }
    this.statusesToDisplay = statuses;
  }
  /**
   * Modification du statut
   * Affectation automatique sur le connecté d'un ticket au changement de statut s'il n'existe pas d'affecté
   * ou au 1er commentaire public d'un agent
   *
   * @param {Status} status
   */
  private _autoAffectAndStatus(status: Status) {
    this.ticket.status = status.id;
    this.ticket.status_object = status;
    if (! this.ticket.affectation) {
      this.subSink.sink = this.ticketService.addAffectation(this.ticket).subscribe(_ticket => {
        this.ticket.affectation = _ticket.affectation;
        this.ticket.affectation_object = _ticket.affectation_object;
        this._doUpdateTicket();
      });
    } else {
      this._doUpdateTicket();
    }
  }
  /**
   * Ecoute du changement d'onglet dans la partie basse des commentaires / annotations
   * Se place sur le bon onglet : si commentaire alors liste des commentaires
   * @private
   */
  private _onTabEditorCommentOrAnnotation() {
    this.subSink.sink = this.pubSubSvc
      .on('tab_index_to_editors')
      .subscribe((index: number) => {
        switch (index) {
          case TabListIndex.annotations:
            this.selectedTab = TabEditorIndex.annotations;
            break;
          default:
            this.selectedTab = TabEditorIndex.commentaire;
            break;
        }
      });
  }
  onFocus(event: MatTabChangeEvent) {
    this.pubSubSvc.publish('tab_index_to_lists', event.index);
  }
  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
