import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ComponentItem} from '@Common/home/navbar/dynamic-core-components/component-item';
import {MenuNavComponent} from '@Common/home/navbar/components/menu-nav.component';
import {ComponentsService} from '@Services/navbar/components-navbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {LiveUpdateService} from './common/services/live-update/live-update.service';
import {SnackBarService} from './common/services/snack-bar/snack-bar.service';

import {Subscription} from 'rxjs';
import {PubSubService} from './common/pub-sub.service';
import {IndividuSafeService} from './common/services/individu_helpdesk/individu.service';
import {SnackBarDataModel, SnackBarToastyService, typeToast} from '@Common/widgets/general/snack-bar';
import {typeEvents} from './common/toasty/type-events';
import {NotifsHDNavComponent} from './common/notification/notifications-component';
import {ProfilEnum, ProfilModel, ProfilService, RoleHelpdeskEnum} from './common/services';
import {Individu} from '@Common/core/services';
import {SubSink} from '@Common/core/utils/subsink';
import {concatMap} from 'rxjs/operators';
import {HomeCart} from './common/services/profil/home-cart/home-cart.model';
import {Router} from '@angular/router';
@Component({
  selector: 'app-helpdesk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'Helpdesk';
  menu = [];
  showLoader = false;
  /**
   * Ne pas rediriger sur profil d'arrivée panier sur ces routes
   */
  NOT_REDIRECT_ROUTES = ['/solver', '/stats', '/helpdesk/ticket'];

  eventPubSub: Map<string, Subscription> = new Map<string, Subscription>([
    [typeEvents.addedComment,
      this.pubsubService.on(typeEvents.addedComment).subscribe((msg) => {
        this.toasty(msg, 'Commentaire', typeToast.success);
      })],
    [typeEvents.updatedComment,
      this.pubsubService.on(typeEvents.updatedComment).subscribe((msg) => {
        this.toasty(msg, 'Commentaire', typeToast.success);
      })],
    [typeEvents.addedAnnotation,
      this.pubsubService.on(typeEvents.addedAnnotation).subscribe((msg) => {
        this.toasty(msg, 'Annotation', typeToast.success);
      })],
    [typeEvents.updatedAnnotation,
      this.pubsubService.on(typeEvents.updatedAnnotation).subscribe((msg) => {
        this.toasty(msg, 'Annotation', typeToast.success);
      })]
    ]
  );
  connecte: Individu;
  subSink = new SubSink();
  constructor(private router: Router,
              private individuService: IndividuSafeService,
              private componentsService: ComponentsService,
              private liveService: LiveUpdateService,
              private snackBarSercice: SnackBarService,
              private snackSvc: SnackBarToastyService,
              public snackBar: MatSnackBar,
              private pubsubService: PubSubService,
              private profilSvc: ProfilService) {
  }

  ngOnInit(): void {
    this.fetchMenu();
    this.snackBarSercice.openstream.subscribe(bol => this.openSnackBar(bol));
    this.subSink.sink = this.pubsubService.on('loading')
      .subscribe((val) => {
        setTimeout(() => this.showLoader = val);
    });
  }

  fetchMenu() {
     this.subSink.sink = this.individuService.individu$
       .pipe(concatMap((_individu: Individu) => {
         this.connecte = _individu;
         return this._fetchProfils();
       }))
       .subscribe(_profils => {
        let staff = false;
        this.menu = [];
        if (this.isStaff(this.connecte )) {
          this.menu.push(
            {url: '/solver/ux-content', titre: 'Traiter les tickets', icon: 'assignment'},
            {url: '/solver/profil', titre: 'Profil', icon: 'account_box'},
            {url: '/stats', titre: 'Statistiques',  icon: 'insert_chart'},
          );
        }
        if (this.individuService.hasRole(this.connecte , RoleHelpdeskEnum.admin) ||
            this.individuService.hasRole(this.connecte , RoleHelpdeskEnum.superviseur)) {
          staff = true;
          this.menu.push(
            {url: '/conf', titre: 'Configuration', icon: 'settings'},
          );
        }
        const components = this.getComponents();
        if (staff) {
          components.push(new ComponentItem(NotifsHDNavComponent, {}, 'notifsHD'));
        }
        this.componentsService.setComponents(components);
        this._redirectAgent(_profils);
      }
    );
  }

  /**
   * Redirige vers les préférences d'arrivées de l'agent
   * pas d'utilisation de https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/indexOf car il fait
   * une comparaison stricte
   * @param _profils
   * @private
   */
  private _redirectAgent(_profils) {
    if (this._mustRedirect()) {
      const _profilsList = _profils.list as ProfilModel[];
      const _profil = _profilsList.find(_p => _p.key === ProfilEnum.home_cart);
      if (_profil) {
        if ((<HomeCart>_profil.settings).home === 'cart') {
          this.router.navigate(['solver/ux-content']);
        }
      }
    }
  }
  private _mustRedirect(): boolean {
    for (let i = 0; i < this.NOT_REDIRECT_ROUTES.length; i++) {
      const found = this.router.url?.toLowerCase()?.indexOf(this.NOT_REDIRECT_ROUTES[i]);
      if (found !== -1) {
        return false;
      }
    }
    return true;
  }
  private _fetchProfils(connecte: Individu = this.connecte) {
    const extra = new Map<string, string>();
    extra.set('owner', connecte.uid);
    return this.profilSvc.listAllItems(extra);
  }
  isStaff(individu) {
    return this.individuService.hasRole(individu, RoleHelpdeskEnum.dispatcher) ||
           this.individuService.hasRole(individu, RoleHelpdeskEnum.solver);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
    this.eventPubSub.forEach((value: Subscription, key: string) => {
        if (value) {
          value.unsubscribe();
        }
      }
    );
    this.subSink.unsubscribe();
  }

  openSnackBar(msg) {
    if (msg) {
      this.snackBar
        .open(msg, '', {duration: 2000})
        .afterDismissed()
        .subscribe(() =>
          this.snackBarSercice
            .endstream
            .next(true));

    }
  }

  public toasty(msg: string, title: string = '', type: typeToast = typeToast.success) {
    const config: SnackBarDataModel = {
      title: title,
      message: msg,
      type: type,
      showClose: true,
      duration: 2000
    };
    this.snackSvc.openSnackToasty(config);
    this.snackBarSercice.endstream.next(true);
  }

  getComponents() {
    return [
      new ComponentItem(MenuNavComponent, this.menu)
    ];
  }

  ngAfterViewInit(): void {

  }

}
