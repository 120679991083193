import {Component, OnDestroy, OnInit} from '@angular/core';

import {PriorityService} from '../../common/services';
import {TicketService} from '../../common/services';
import {AuthService} from '@Services/auth';

import {SnackBarService} from '../../common/services/snack-bar/snack-bar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SnackBarToastyService} from "@Common/widgets/general/snack-bar";

import {IndividuHelpDeskService} from "../../common/services/individu_helpdesk/individu-helpdesk.service";

import {IndividuService} from "@Common/core/services";
import {PubSubService} from "../../common/pub-sub.service";
import {FormType} from '../../common/form-fields/form-type.enum';
import {IndividuSafeService} from "../../common/services/individu_helpdesk/individu.service";

@Component({
  selector: 'app-new-ticket',
  templateUrl: './new-ticket.component.html',
  styleUrls: ['./new-ticket.component.css']
})
export class NewTicketComponent implements OnInit, OnDestroy {
  formType = FormType;

  constructor(private pubSubSvc: PubSubService,
              private priorityService: PriorityService,
              private individuBaseService: IndividuService,
              private individuService: IndividuHelpDeskService,
              private indSafe: IndividuSafeService,
              private ticketService: TicketService,
              private authService: AuthService,
              private snackBarService: SnackBarService,
              private snackSvc: SnackBarToastyService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
  }

  redirect() {
    this.router.navigate(['/'], {relativeTo: this.route.parent});
  }

  ngOnDestroy(): void {
  }
}
