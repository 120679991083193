<mat-card *ngIf="isVisible" fxLayout="column" class="wrapper-new-ticket">
  <div fxLayout="row">
    <div>
      <mat-card-title>
        <span>Visualisation d'un ticket en tant qu'agent</span>
      </mat-card-title>
    </div>
    <span fxFlex></span>
    <div>
      <button mat-button
              [matDialogClose]="true"
              matTooltip="Fermer la fenêtre">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-card-content fxLayoutGap="5%" fxFlex="100%">
    <app-ux-ticket-body fxLayout="column" fxFlex
                        *ngIf="ticket"
                        [ticket]="ticket"
                        [ticketChangeStream]="ticketChangeStream"
                        [inModale]="true"
    >
    </app-ux-ticket-body>
  </mat-card-content>
</mat-card>
