import { throwError as observableThrowError, Observable } from 'rxjs';
import { Pagination } from '../../../widgets/general/pagination';
import { AppUrlConfig } from './appUrlConfig';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, publish, refCount } from 'rxjs/operators';
import { TemplateObject, TemplateT } from './interfaces-http';

/**
 * Classe utilitaire services, avec generic, elle fournit les méthodes get, create, update, list (avec pagination), listAsync (sans pagination, BehaviorSubject pattern)
 *
 * Basée sur HttpClient, AppConfiguration (config de l'appli pour les urls, optionnel, peut être surchargé par la propriété Urls), keyConfig pour les urls à sélectionner dans AppConfiguration
 */
export abstract class ServiceBaseGeneric<T extends TemplateT> {
  pagination: Pagination;
  private _url: any = null;

  constructor(private httpSvc: HttpClient,
              private configUrl: AppUrlConfig) {
    this.pagination = new Pagination();
  }

  /**
   * Permet de surcharger AppUrlConfig si non implémentée, pour les urls
   * Doit être du type
   * {
         baseUrl: `${environment.baseUrl}/<module>/`,
         list: {
             templatesmails: 'url_metier/',
         }
      }
   * @param value
   * @constructor
   */
  public set Urls(value) {
    this._url = value;
  }

  public get Urls() {
    return this._url;
  }

  /**
   * Obtient la clé de config de la part de la classe dérivée
   */
  abstract getKeyConfig(): string;

  /*
   * METHODES utilitaires get, create (post), update (put), remove (delete), list, listAll
   */

  public get(id): Observable<T> {
    let url = '';
    if (this.Urls !== null)
      url = `${this.configUrl.url(this.getKeyConfig(), this.Urls)}${id}/`;
    else
      url = `${this.configUrl.url(this.getKeyConfig())}${id}/`;

    return this.httpSvc.get<T>(url).pipe(catchError((error: any) => this.throwObservable(error)));
  }

  public create(object: T): Observable<T> {
    let url = '';
    if (this.Urls !== null)
      url = `${this.configUrl.url(this.getKeyConfig(), this.Urls)}`;
    else
      url = `${this.configUrl.url(this.getKeyConfig())}`;

    return this.httpSvc.post<T>(url, JSON.stringify(object)).pipe(catchError((error: any) => this.throwObservable(error)));

  }

  public update(object: T): Observable<T> {
    let url = '';
    if (this.Urls !== null)
      url = `${this.configUrl.url(this.getKeyConfig(), this.Urls)}${object.id}/`;
    else
      url = `${this.configUrl.url(this.getKeyConfig())}${object.id}/`;

    return this.httpSvc.put<T>(url, object).pipe(catchError((error: any) => this.throwObservable(error)));
  }

  public remove(object: T): Observable<T> {
    let url = '';
    if (this.Urls !== null)
      url = `${this.configUrl.url(this.getKeyConfig(), this.Urls)}${object.id}/`;
    else
      url = `${this.configUrl.url(this.getKeyConfig())}${object.id}/`;

    return this.httpSvc.delete<T>(url).pipe(catchError((error: any) => this.throwObservable(error)));

  }

  /**
   * Liste avec pagination, recherche sur mot clé possible
   *
   * @param {number} limit : nombre d'éléments à ramener
   * @param {number} offset : à partir de l'index offset
   * @param {string} keyword [optionnel] mot de recherche
   * @param extraQS: URLSearchParams : d'autres crières en querystring
   * @returns {Observable<Pagination>}
   */
  public list(limit = 0, offset = 0, keyword = '', extraQS: Map<string, any> = null): Observable<Pagination> {
    let params = this.getUrlHttpParams(extraQS);

    if (limit > 0) {
      params = params.set('limit', limit.toString()).set('offset', offset.toString());
    }

    if (keyword && keyword !== '') {
      params = params.set('search', keyword);
    }

    let url = '';
    if (this.Urls !== null)
      url = this.configUrl.url(this.getKeyConfig(), this.Urls);
    else
      url = this.configUrl.url(this.getKeyConfig());

    return this.httpSvc.get(url, {params})
      .pipe(map(response => {

        if (limit > 0) {
          const list = response as TemplateObject;
          this.pagination.total = list.count;
          this.pagination.list = list.results;
          this.pagination.totalView = this.pagination.list.length;
        } else {
          const list = response as any[];
          this.pagination.total = list.length;
          this.pagination.list = list;
          this.pagination.totalView = list.length;
        }
        return this.pagination;
      })).pipe(catchError((error: any) => this.throwObservable(error)));
  }

  /**
   * Caching items for listAll()
   * @type {Observable<T[]>}
   */
  private listAllItems: Observable<T[]> = null;

  /**
   * Liste sans pagination
   * @param extraQS : Map<key,value> : map clé 'key' (paramétre querystring) et sa valeur
   * @param withCache : cache all elements
   * @returns {Observable<any>}
   */
  public listAll(extraQS: Map<string, any> = null, withCache?: boolean): Observable<T[]> | Observable<T> {
    let params = this.getUrlHttpParams(extraQS);

    let url = '';
    if (this.Urls !== null)
      url = this.configUrl.url(this.getKeyConfig(), this.Urls);
    else
      url = this.configUrl.url(this.getKeyConfig());

    const urlGetting = this.httpSvc.get<T>(url, {params}).pipe(catchError((error: any) => this.throwObservable(error)));

    if (withCache) {
      if (!this.listAllItems) {
        this.listAllItems = urlGetting.pipe(publish(), refCount());
        // this.listAllItems = urlGetting.publishReplay(1).refCount();
        return this.listAllItems as Observable<T[]>;
      }

      return this.listAllItems as Observable<T[]>;
    } else
      return urlGetting;
  }


  /**
   * Clear all items for listAll()
   */
  clearCacheAllItems() {
    this.listAllItems = null;
  }

  throwObservable(error: any) {
    return observableThrowError(error.error || `Server error : ${error} ${JSON.stringify(error)}`);
  }

  protected getUrlHttpParams(extraQS: Map<string, string>): HttpParams {
    let urlQS = new HttpParams();

    if (extraQS !== null) {
      extraQS.forEach((v, k) => {
        urlQS = urlQS.set(k, v);
      });
    }

    return urlQS;
  }

  /**
   * Prépare un URLSearchParam avec une 'limit' et un 'offset' pour la pagination
   *
   * @param limit
   * @param offset
   * @returns {URLSearchParams}
   */
  protected getPaginationQS(limit: number, offset: number) {
    let urlQS = {};

    if (limit > 0) {
      urlQS['limit'] = limit.toString();
      urlQS['offset'] = offset.toString();
    }

    return urlQS;
  }
}
