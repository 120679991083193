import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ArticleModel } from './article.model';
import { environment } from '../../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  private url = `${environment.baseUrl}/api_faq/articles/`;

  constructor(private http: HttpClient) {
  }

  list(categorie_id: number, limit: number) {
    const params = {
      'categorie_id': `${categorie_id}`,
      'limit': `${limit}`
    };
    return this.http.get<ArticleModel[]>(this.url, {params: params});
  }

  listByOrder(field_name: string, order: string, limit: number) {
    const params = {
      field_name: field_name,
      order: order,
      limit: `${limit}`
    };
    return this.http.get<ArticleModel[]>(this.url, {params: params});
  }


  search(term: string) {
    let urlQS = {};
    if (term) {
      urlQS['search'] = term;
    }
    return this.http.get<ArticleModel[]>(this.url, {params: urlQS});
  }

  reload(url: string): Observable<ArticleModel[]> {
    return this.http.get<ArticleModel[]>(url);
  }

  get(id: number) {
    return this.http.get<ArticleModel>(`${this.url}${id}/`);
  }

  update(articleObj: ArticleModel, update_vues: number = null) {
    const params = new HttpParams().set('update_vues', update_vues.toString());

    return this.http.put<ArticleModel>(`${this.url}${articleObj.id}/`, articleObj, {params});
  }

}
