/**
 * Created by juggernaut on 14/07/2017.
 */
export interface PieceJointe {
  id: number;
  datafile: File;
  object_id: number;
  content_type: number;
  user: number;
  name: string;
  filename: string;
}

export const allowedExtensionsForPreview = ['png', 'jpg', 'jpeg', 'gif', 'pdf'];

// export interface PieceJointeToUpload {
//   pieceJointe: PieceJointe;
//   tempId: number;
// }
