import {Injectable} from '@angular/core';
import {DaoGeneric} from '@Services/base/daoService';

import {environment} from '@Env/environment';
import {HttpClient} from '@angular/common/http';
import {Action} from './action.model';


@Injectable({  providedIn: 'root' })
export class ActionLogService extends DaoGeneric<Action> {
  private url = `${environment.baseUrl}/helpdesk/actions/`;

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getRootUrl(): string {
    return this.url;
  }
}
