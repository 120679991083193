import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Ticket, TicketService} from '../../../services';
import {IndividuHelpDeskService} from '../../../services/individu_helpdesk/individu-helpdesk.service';
import {finalize} from 'rxjs/operators';
import {IndividuHelpDesk} from '../../../services/individu_helpdesk/individu-helpdesk.model';
import {SubSink} from '@Common/core/utils/subsink';
import {Individu, IndividuService} from '@Common/core/services';

/**
 * Affichage des subscribers (personnes en copie) pour la vue demandeur
 */
@Component({
  selector: 'app-ux-subscription-display',
  templateUrl: './ux-subscription-display.component.html',
  styleUrls: ['./ux-subscription-display.component.scss']
})
export class UxSubscriptionDisplayComponent implements OnInit, OnDestroy {
  @Input()
  ticket: Ticket;

  subscriptions: IndividuHelpDesk[] = [];

  loading = false;

  individu: Individu;

  subSink = new SubSink();

  constructor(private individuBaseService: IndividuService,
              private individuService: IndividuHelpDeskService,
              private ticketService: TicketService) {
    this.subSink.sink = this.individuBaseService.individu$.subscribe((_individu) => {
      this.individu = _individu;
    });
  }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.loading = true;
    this.subSink.sink = this.ticketService
      .getSubscribers(this.ticket.id)
      .pipe(finalize(() => this.loading = false))
      .subscribe(data => {
        if (data && data.length > 1) {
          data.forEach((_ind: IndividuHelpDesk) => {
            if (this.ticket.individu_object && _ind.uid !== this.ticket.individu_object.uid) {
              this.subscriptions.push(_ind);
            }
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
