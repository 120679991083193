export enum FlagColorEnum {
  all = 1000,
  unselect = 2000,
  black = 0,
  blue = 1,
  green = 2,
  purple = 3,
  orange = 4,
  red = 5,
  cyan = 6,
  pink = 7,
  yellow = 8
}
