import {Component, HostBinding, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {PiecejointeDownloadService} from '../services/piecejointe/piecejointe-download.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit {


  supportedImages = ['image/jpg', 'image/png', 'image/jpeg']
  supportedPdf = ['application/pdf']
  isImage = false;
  isPdf = false;

  virtualUrl: any;


  constructor(public dialogRef: MatDialogRef<ImagePreviewComponent>,
              private pieceJointeDownload: PiecejointeDownloadService,
              public sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (!this.data) {
      this.data = null;
      return;
    }
    this.createImageFromBlob(this.data);
  }


  createImageFromBlob(image: Blob) {
    this.setFormat(image.type);
    this.virtualUrl = this.trusttUrlHtml(window.URL.createObjectURL(image));
  }

  trusttUrlHtml(object) {
    object = this.sanitizer.bypassSecurityTrustResourceUrl(object);
    return object;
  }


  justClose() {
    this.dialogRef.close(null);
  }

  private setFormat(type: string) {
    if (this.supportedImages.indexOf(type) > -1) {
      this.isImage = true;
      return
    }
    if (this.supportedPdf.indexOf(type) > -1) {
      this.isPdf = true;
      return
    }


  }
}
