
import {throwError as observableThrowError, Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpHeaders} from "@angular/common/http";

import {Pagination} from "../../../widgets/general/pagination";
import {AppUrlConfig} from "./appUrlConfig";

import { catchError, map, publish, refCount } from "rxjs/operators";

import {TemplateObject} from "./interfaces-http";

/**
 * TODO : il est préférable d'utiliser ./daoService/generic.dao.ts, version + simple
 *
 * Classe utilitaire services, elle fournit les méthodes get, create, update, list (avec pagination), listAsync (sans pagination, BehaviorSubject pattern)
 *
 * Basée sur HttpClient, AppConfiguration (config de l'appli pour les urls, optionnel, peut être surchargé par la propriété Urls), keyConfig pour les urls à sélectionner dans AppConfiguration
 */
export abstract class ServiceBase {
  pagination: Pagination;
  private _url: any = null;

  constructor(private httpSvc: HttpClient,
              private configUrl: AppUrlConfig) {
    this.pagination = new Pagination();
  }

  /**
   * Permet de surcharger AppUrlConfig si non implémentée, pour les urls
   * Doit être du type
   * {
         baseUrl: `${environment.baseUrl}/<module>/`,
         list: {
             templatesmails: 'url_metier/',
         }
      }
   * @param value
   * @constructor
   */
  public set Urls(value) {
    this._url = value;
  }

  public get Urls() {
    return this._url;
  }


  /**
   * Obtient la clé de config de la part de la classe dérivée
   */
  abstract getKeyConfig() : string;

  /**
   * Obtient un objet (GET)
   * @param id l'id de l'objet à obtenir
   * @return {Observable<any>}
   */
  public get(id): Observable<any> {
    const url = this.getUrl(id);

    return this.httpSvc.get(url).pipe(catchError((error: any) =>  this.throwObservable(error)));
  }

  /**
   * Création d'un objet (POST)
   * @param object
   * @return {Observable<any>}
   */
  public create(object) : Observable<any> {
    const url = this.getUrl();

    return this.httpSvc.post(url, JSON.stringify(object)).pipe(catchError((error: any) => this.throwObservable(error)));
  }

  /**
   * Màj d'un objet (PUT)
   *
   * @param object : doit contenir la propriété 'id'
   * @return {Observable<any>}
   */
  public update(object): Observable<any> {
    const url = this.getUrl(object.id);

    return this.httpSvc.put(url, object).pipe(catchError((error: any) =>  this.throwObservable(error)));
  }

  /**
   * Suppression d'un objet
   * @param object : doit contenir la propriété 'id'
   * @returns {Observable<any>}
   */
  public remove(object): Observable<any> {
    const url = this.getUrl(object.id);

    return this.httpSvc.delete(url).pipe(catchError((error: any) =>  this.throwObservable(error)));
  }

  /**
   * Liste avec pagination, recherche sur mot clé possible
   *
   * @param {number} limit : nombre d'éléments à ramener
   * @param {number} offset : à partir de l'index offset
   * @param {string} keyword [optionnel] mot de recherche
   * @param extraQS : Map<key,value> : extra paras querystring : map clé 'key' (paramétre querystring) et sa valeur
   * @returns {Observable<Pagination>}
   */
  public list(limit = 0, offset = 0, keyword = '', extraQS: Map<string,any> = null): Observable<Pagination> {
    let params: HttpParams = this.getUrlHttpParams(extraQS);
    if (limit > 0) {
      params = params.set('limit', limit.toString()).set('offset', offset.toString());
    }

    if (keyword && keyword !== '') {
      params = params.set('search', keyword);
    }

    const url = this.getUrl();

    return this.httpSvc.get(url, {params})
      .pipe(map(response => this.getPagination(response, limit)
      )).pipe(catchError((error: any) =>  this.throwObservable(error)));
  }

  /**
   * List avec pagination, permet de prendre en compte le sorting
   * @param {string} sort [optionnel] : champ à trier
   * @param {string} order [optionnel][obligatoire si sort] : sens du tri ('asc' |'desc')
   * @param {number} page
   * @param {number} limit : nombre d'éléments à ramener
   * @param {number} offset : à partir de l'index offset
   * @param {Map<string, string>} extraParams
   * @param {string} keyword
   * @return {Observable<Pagination>}
   */
  public listItems(sort: string, order: string, page: number, limit: number, offset: number, extraParams: Map<string, string> = null, keyword = ''): Observable<Pagination> {
    let params = this.getParams(limit, offset, keyword, extraParams);

    if(sort && sort !== '') {
      params = this.getSorting(sort, order, params);
    }

    const url = this.getUrl();

    return this.httpSvc
      .get(url, {params: params})
      .pipe(map(response => this.getPagination(response, limit)));
  }

  /**
   * Caching items for listAll()
   * @type {any}
   */
  private listAllItems: any = null;

  /**
   * Liste sans pagination
   * @param extraQS : Map<key,value> : map clé 'key' (paramétre querystring) et sa valeur
   * @param withCache : cache all elements
   * @returns {Observable<any>}
   */
  public listAll(extraQS: Map<string,any> = null, withCache?: boolean): Observable<any> {
    let params = this.getUrlHttpParams(extraQS);

    const url = this.getUrl();

    const urlGetting = this.httpSvc.get(url, {params}).pipe(catchError((error: any) =>  this.throwObservable(error)));
    if(withCache) {
      if (!this.listAllItems) {
        this.listAllItems = urlGetting.pipe(publish(), refCount());
        return this.listAllItems;
      }

      return this.listAllItems;
    } else
      return urlGetting;
  }


  /**
   * Clear all items for listAll()
   */
  clearCacheAllItems() {
    this.listAllItems = null;
  }

  throwObservable(error: any) {
    return observableThrowError(error.error || `Server error : ${error} ${JSON.stringify(error)}`)
  }

  /**
   * Obtient l'URL
   * @param {any} id [optionnel] id nécessaire pour le get/update/remove
   * @return {string}
   */
  private getUrl(id = null) {
    let url = '';

    if(this.Urls !== null)
      url =  this.configUrl.url(this.getKeyConfig(), this.Urls);
    else
      url = this.configUrl.url(this.getKeyConfig());

    if(id !== null) {
      url = `${url}${id}/`;
    }

    return url;
  }

  private getPagination(response, limit) {
    const pagination: Pagination = new Pagination();

    if('results' in response && limit > 0) {
      const list = response as TemplateObject;
      pagination.total = list.count;
      pagination.list = list.results;
      pagination.totalView = pagination.list.length;
    } else {
      const list = response as any[];
      pagination.total = list.length;
      pagination.list = list;
      pagination.totalView = list.length;
    }

    return pagination;
  }

  private getParams(limit: number, offset: number, keyword: string, extraQS: Map<string,any> = null) {
    let params: HttpParams = this.getUrlHttpParams(extraQS);
    if (limit > 0) {
      params = params.set('limit', limit.toString()).set('offset', offset.toString());
    }

    if (keyword && keyword !== '') {
      params = params.set('search', keyword);
    }

    return params;
  }

  private getSorting(sort: string, order: string, params: HttpParams) {
    let orderDirection = '';
    let orderField = '';
    if(order) {
      switch(order) {
        case 'asc':
          orderDirection = '';
          break;
        case 'desc':
          orderDirection = '-';
          break;
      }
    }

    if(sort) {
      orderField = `${orderDirection}${sort}`;
      params = params.set('ordering', orderField);
    }

    return params;
  }

  protected getUrlHttpParams(extraQS: Map<string, string>): HttpParams {
    let urlQS = new HttpParams();

    if(extraQS !== null) {
      extraQS.forEach((v,k) => {
        urlQS = urlQS.set(k, v);
      })
    }

    return urlQS;
  }

  /**
   * Prépare un URLSearchParam avec une 'limit' et un 'offset' pour la pagination
   *
   * @param limit
   * @param offset
   * @returns {URLSearchParams}
   */
  protected getPaginationQS(limit: number, offset: number) {
    let urlQS = new HttpParams();

    if (limit > 0) {
      urlQS = urlQS.set('limit', limit.toString()).set('offset', offset.toString());
    }

    return urlQS;
  }
}

