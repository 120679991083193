import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {TicketField} from './fields.interface';
import {PieceJointe, PriorityService, Ticket, TicketService} from '../services';
import {forkJoin, Observable, Subject} from 'rxjs';
import {IndividuHelpDesk} from '../services/individu_helpdesk/individu-helpdesk.model';
import {SnackBarToastyService, typeToast} from '@Common/widgets/general/snack-bar';
import {PubSubService} from '../pub-sub.service';
import {AuthService, IndividuService} from '@Common/core/services';
import {IndividuHelpDeskService} from '../services/individu_helpdesk/individu-helpdesk.service';
import {SubSink} from '@Common/core/utils/subsink';
import {concatMap, finalize, first, tap} from 'rxjs/operators';

import {TitleTicketComponent} from './title/title-ticket.component';
import {FormType} from './form-type.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {FormService} from '../services/forms/forms.service';
import {IndividuSafeService} from '../services/individu_helpdesk/individu.service';
import {CategorieHelpDeskService} from '../services/categorie/categorie.service';
import {CategorieHelpDesk} from '../services/categorie/categorie.model';
import {TicketsLiesService} from '../services/tickets-lies/tickets-lies.service';
import {TicketsLies} from '../services/tickets-lies/tickets-lies.model';
import {parameters} from '../services/parameter/parameters';
import {ParameterService} from '../services/parameter/parameter.service';

/**
 * Formulaire pour champs de saisie d'un dépôt de ticket :
 * - pour demandeur (contact)
 * ou
 * - agent traitant (gestionnaire)
 */
@Component({
  selector: 'app-form-create-ticket',
  templateUrl: './form-create-ticket.component.html',
  styleUrls: ['./form-create-ticket.component.scss']
})
export class FormCreateTicketComponent implements OnInit, OnDestroy {
  @Input() labelButton = 'CREER TICKET';
  @Input() typeForm: FormType; // agent | contact
  @Input() extendedFields: TicketField[] = [];

  @Input()
  set ticket(ticket: any) {
    if (ticket) {
      this._ticket = ticket;
    }
  }

  get ticket() {
    return this._ticket;
  }
  formType = FormType;
  @Output() onSaved: EventEmitter<Ticket> = new EventEmitter<Ticket>();
  @Output() onCanceled = new EventEmitter();

  @ViewChild('title') title: TitleTicketComponent;

  _ticket = <Ticket> {
    title: '',
    description: '',

    submitter_email: '',

    status: null,
    priority: null,
    categorie: null,

    ip: '',
    telephone: ''
  };

  fields: TicketField[] = [];
  subscriptions: IndividuHelpDesk[] = [];
  subSink = new SubSink();
  submitted: boolean;
  uploaderConnector: Subject<any> = new Subject();
  uploading$: Subject<boolean> = new Subject<boolean>();
  ip = '';
  categories: CategorieHelpDesk[] = [];
  categorieSelected: CategorieHelpDesk;
  ticketALier: TicketsLies[] = [];
  hasPJs = false;
  uploading = false;
  selectedDemandeurCategorie: CategorieHelpDesk = null;
  phoneRequired = false;
  catRequired = false;
  filesRequired = false;
  contactsRequired = false;
  descriptionHelp = '';
  parameters = parameters;
  constructor(private pubSubSvc: PubSubService,
              private priorityService: PriorityService,
              private indSafe: IndividuSafeService,
              private individuBaseService: IndividuService,
              private individuService: IndividuHelpDeskService,
              private categorieService: CategorieHelpDeskService,
              private ticketService: TicketService,
              private authService: AuthService,
              private snackSvc: SnackBarToastyService,
              private formSvc: FormService,
              private router: Router,
              private route: ActivatedRoute,
              private ticketsLiesSvc: TicketsLiesService,
              private parameterService: ParameterService) {
  }

  ngOnInit() {
    this.getAllParamValue();
    this.subSink.sink = this.indSafe.getClientIp().subscribe((_data) => { this.ip = _data; });
    this._makeFields(this.typeForm);
    this._loadCategories();
  }
  /**
   * Suivant le formulaire, toutes le catégories sont chargées ou seulement selon le filtre
   */
  private _loadCategories() {
    const categoryField = this.fields.find(_field => _field.name === 'category');
    if (categoryField) {
      const extrasParams: Map<string, string> = new Map<string, string>();
      if (categoryField.filters && categoryField.filters.length > 0) {
        categoryField.filters.forEach(_filter => extrasParams.set(_filter.key, _filter.value));
      }
      this.subSink.sink = this.categorieService
        .list_simple(extrasParams)
        .subscribe(_cats => this.categories = this.categorieService.sortCategories(_cats));
    }
  }

  private _makeFields(type) {
    this.fields = this.formSvc.getFieldsForm(type, this.extendedFields);
  }

  getAllParamValue(): void {
    this._getDescriptionHelpParam();
    if (this.typeForm === FormType.contact) {
      // Ces champs ne sont pas obligatoires en cliquant sur le bouton Créer un ticket depuis le menu de traitement des tickets
      // Il faut prendre en compte les paramètres si on clique sur le bouton Nouvelle demande depuis l'accueil
      this._getPhoneRequiredParam();
      this._getCatRequiredParam();
      this._getFilesParam();
      this._getContactsParam();
    }
  }

  private _getDescriptionHelpParam(): void {
    this.subSink.sink = this.parameterService.getByName(this.parameters.descriptionHelp)
      .subscribe((data) => this.descriptionHelp = data[0].value);
  }

  private _getPhoneRequiredParam(): void {
    this._getParamValue(this.parameters.phoneRequired, (value) => this.phoneRequired = value);
  }

  private _getCatRequiredParam(): void {
    this._getParamValue(this.parameters.categorieRequired, (value) => this.catRequired = value);
  }

  private _getContactsParam(): void {
    this._getParamValue(this.parameters.contactsRequired, (value) => this.contactsRequired = value);
  }

  private _getFilesParam(): void {
    this._getParamValue(this.parameters.filesRequired, (value) => this.filesRequired = value);
  }

  private _getParamValue(paramName: string, callback: (value: boolean) => void): void {
    this.parameterService.getByName(paramName).pipe(
      first(),
      tap(data => {
        if (data && data.length > 0) {
          callback(data[0].value === '1');
        }
      })
    ).subscribe();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  updateTicket() {

  }
  isButtonEnabled() {
    if (this.submitted) { // le ticket est en cours de sauvegarde
      return false;
    }
    if (! this.ticket.description) { // description obligatoire pour tout le monde
      return false;
    }
    if (!this.title?.isControlValid()) { // titre obligatoire pour tout le monde
      return false
    }
    if (this.uploading) { // pj en cours d'upload
      return false;
    }
    if (this.typeForm === FormType.contact) { // Création par bouton Nouvelle demande
      if (this.phoneRequired && !this.ticket.telephone) { // telephone obligatoire
        return false;
      }
      if (this.catRequired && !this.ticket.categorie) { // categorie obligatoire
        return false;
      }
      if (this.filesRequired && !this.hasPJs) { // pj obligatoire
        return false;
      }
      if (this.contactsRequired && this.subscriptions?.length === 0) { // contact obligatoire
        return false;
      }
    }
    return true;
  }
  cancel() {
    this.onCanceled.emit();
  }
  private _emitAfterSaved(_ticket: Ticket) {
    this.onSaved.emit(_ticket);
    this.snackSvc.toasty('Ticket', 'Ticket bien créé', typeToast.success);
  }
  /**
   * Sauvegarde du ticke et lancement upload fichiers le cas échéant
   */
  save(): void {
    this.submitted = true;
    this.ticket.ip = this.ip.substring(0, 255);
    this.subSink.sink = this.ticketService.create(this.ticket)
      .pipe(finalize(() => this.submitted = false),
            concatMap((_ticket) => this.addSubscribers(_ticket)),
            concatMap((_ticket) => this.createTicketsLies(_ticket)))
      .subscribe((_ticket) => {
        this._changeSubmitted(_ticket);
        if (!this.hasPJs) {
          this._emitAfterSaved(_ticket);
        } else {
          this.pubSubSvc.publish('loading', true);
          this.uploading = true;
        }
      });
  }

  /**
   * Création des liaisons avec le ticket source ts
   * @param ts
   */
  createTicketsLies(ts: Ticket) {
    return new Observable<Ticket>(observer => {
      if (this.ticketALier.length === 0) {
        observer.next(ts);
        observer.complete();
      } else {
        const observables: Observable<any>[] = [];
        for (const tl of this.ticketALier) {
          tl.source = tl.source || ts.id;
          tl.target = tl.target || ts.id;
          observables.push(this.ticketsLiesSvc.create(tl));
        }
        this.subSink.sink = forkJoin(observables)
          .subscribe(() => {
            observer.next(ts);
            observer.complete();
          });
      }
    });
  }
  /**
   * Démarrage upload fichier le cas échéant
   * @param ticket
   */
  private _changeSubmitted(ticket: Ticket) {
    if (ticket) {
      this.uploaderConnector.next(ticket);
      this.ticket = ticket;
    }
  }
  /**
   * Détection s'il y a des fichiers à téléverser après la sauvegarde du ticket
   * @param pjs
   */
  hasPjs(pjs: PieceJointe[]) {
    this.hasPJs = pjs.length > 0;
  }
  /**
   * Fin téléchargement fichier
   * @param pjs
   */
  handleFilesUploaded(pjs: PieceJointe[]): void {
    this.hasPJs = false;
    this.uploading = false;
    this._emitAfterSaved(this.ticket);
    this.pubSubSvc.publish('loading', false);
  }

  /**
   * Ajout de CC au ticket
   * @param ticket
   */
  addSubscribers(ticket: Ticket): Observable<Ticket> {
    return new Observable<Ticket>(observer => {
      const len = this.subscriptions.length;

      if (len === 0) {
        observer.next(ticket);
        observer.complete();
      }

      const requests = [];
      this.subscriptions.forEach((_cc: IndividuHelpDesk) => {
        requests.push(this.ticketService.addSubscriber(ticket, _cc.uid));
      });
      this.subSink.sink = forkJoin(requests)
        .subscribe(() => {
          observer.next(ticket);
          observer.complete();
      });
    });
  }

  onDescriptionUpdated(event: string) {
    this.ticket.description = event;
  }

  onTitleUpdated(event: string) {
    this.ticket.title = event;
  }

  onPhoneUpdated(event: string) {
    this.ticket.telephone = event;
  }
  onApplicantUpdated(event: IndividuHelpDesk) {
    this.ticket.individu = event?.uid;
  }
  onSelectDemandeurCategory(event) {
    this.categorieSelected = event.value;
    this.ticket.categorie = this.categorieSelected ? this.categorieSelected.id : null;
  }
  onSelectedCategory(ticket: Ticket) {
    this.ticket.categorie = ticket.categorie;
  }
  onSubscribers(subscribers: IndividuHelpDesk[]) {
    this.subscriptions = subscribers;
  }

  onTicketsALier(ticketALier: TicketsLies[]) {
    this.ticketALier = ticketALier;
  }
  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

}
