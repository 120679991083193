<form #ticketForm="ngForm" fxLayout="column" fxFlex="100%" fxLayoutAlign="center">
  <div fxLayoutAlign="end end">
    <span>les champs marqués par un <strong class="mandatory-field">*</strong> sont obligatoires</span>
  </div>
  <div style="background: white; padding: 5px;" fxLayout="column" fxLayoutGap="7px">
    <div *ngFor="let field of fields">
      <ng-container *ngIf="field.name==='title'">
        <app-title-ticket #title
                          (titleUpdated)="onTitleUpdated($event)"></app-title-ticket>
      </ng-container>

      <ng-container *ngIf="field.name==='description'">
        <app-description-ticket  #description
                                 (descriptionUpdated)="onDescriptionUpdated($event)"
                                 [descriptionHelp]="descriptionHelp"
                                 [placeholder]="'Veuillez décrire votre demande, indiquez également votre bureau et téléphone'"></app-description-ticket>
      </ng-container>

      <ng-container *ngIf="field.name==='phone'">
        <app-phone-ticket #phone
                          [phoneRequired]="phoneRequired"
                          (phoneUpdated)="onPhoneUpdated($event)"></app-phone-ticket>
      </ng-container>

      <ng-container *ngIf="field.name==='applicant'">
        <app-applicant-ticket #applicant
          (applicantUpdated)="onApplicantUpdated($event)"></app-applicant-ticket>
      </ng-container>

      <ng-container *ngIf="field.name === 'files'">
          <mat-card>
            <mat-card-subtitle><span class="mandatory-field" *ngIf="filesRequired">* &nbsp;</span> Joindre des fichiers (limite à 5 Mo maximum chacun)</mat-card-subtitle>
            <mat-card-actions align="end">
              <app-piece-jointe-uploader-hd [accept]="'.pdf,.txt,.htm,.jpg,.png'"
                                            [filesRequired]="filesRequired"
                                            [multiple]="true"
                                            [maxFileSize]="5000000"
                                            [connector]="uploaderConnector"
                                            [uploading$]="uploading$"
                                            (onFinished)="handleFilesUploaded($event)"
                                            (onUpdatedFiles)="hasPjs($event)"
                                            [disabled]="!ticketForm.form.valid || submitted">
              </app-piece-jointe-uploader-hd>
            </mat-card-actions>
          </mat-card>

      </ng-container>

      <ng-container *ngIf="field.name === 'contacts'">
        <app-add-contacts [contactsRequired]="contactsRequired" (subscribers)="onSubscribers($event)"></app-add-contacts>
      </ng-container>

      <ng-container *ngIf="field.name === 'ticketslies'">
        <mat-expansion-panel [expanded]="false" style="margin-top: 5px;">
          <mat-expansion-panel-header>Tickets liés</mat-expansion-panel-header>
          <app-ux-tickets-lies-ticket (ticketsALier)="onTicketsALier($event)"></app-ux-tickets-lies-ticket>
        </mat-expansion-panel>
      </ng-container>
      <!-- catégorie pour demandeur ou agent -->
      <ng-container *ngIf="typeForm === formType.contact &&
                           field.name === 'category' && categories?.length > 0">

        <mat-expansion-panel [expanded]="true" style="margin-top: 5px;">
          <mat-expansion-panel-header>
            <mat-panel-title><span class="mandatory-field" *ngIf="catRequired">* &nbsp;</span> Catégorie</mat-panel-title>
            <mat-panel-description>pour une meilleure prise en charge, veuillez choisir la catégorie</mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field appearance="outline" fxFlex="calc(90% - 25px)">
            <mat-select [placeholder]="'Sélectionner la catégorie souhaitée'"
                        [required]="catRequired"
                        [matTooltip]="'Sélectionner la catégorie souhaitée'"
                        [(ngModel)]="selectedDemandeurCategorie"
                        (selectionChange)="onSelectDemandeurCategory($event)">
              <mat-option [value]="null" *ngIf="selectedDemandeurCategorie" matTooltip="sans catégorie"><i>Je ne sais pas</i></mat-option>
              <mat-option *ngFor="let categorie of categories" [value]="categorie" [matTooltip]="categorie?.description || ''">
                {{ categorie.label }}
              </mat-option>
            </mat-select>
            <mat-hint *ngIf="categorieSelected">{{categorieSelected?.description}}</mat-hint>
          </mat-form-field>
        </mat-expansion-panel>
      </ng-container>
      <ng-container *ngIf="typeForm === formType.agent &&
                           field.name === 'category' && categories?.length > 0">
        <mat-expansion-panel [expanded]="true" style="margin-top: 5px;">
          <mat-expansion-panel-header>Catégorie</mat-expansion-panel-header>
            <app-autocomplete-categorie [class]="'text-for-navbar'"
                                        [appearance]="'outline'"
                                        (mouseenter)="stopPropagation($event)"
                                        [categories]="categories"
                                        [placeholder]="'Choisir une catégorie, vous pouvez taper quelques lettres'"
                                        [ticket]="ticket"
                                        (onselect)="onSelectedCategory($event)">
            </app-autocomplete-categorie>
        </mat-expansion-panel>
      </ng-container>

    </div>

  </div>

    <div fxLayout="column" >
      <div fxLayoutGap="5px" fxFlexAlign="end">
        <button mat-raised-button (click)="cancel()" [disabled]="submitted || uploading">
          Annuler
        </button>
        <button mat-raised-button color="primary" (click)="save()"
                [disabled]="! isButtonEnabled()">
          {{labelButton}}
        </button>
      </div>
    </div>

</form>
