// Imports
import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  NgZone,
  forwardRef,

  AfterViewInit,

  SimpleChanges,
  OnChanges, OnDestroy, AfterViewChecked
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {UtilsService} from '@Services/core/utils/utils.service';

declare var CKEDITOR: any;

/**
 * CKEditor component
 *
 * fork CKEditorComponent ng2-ckeditor https://github.com/chymz/ng2-ckeditor
 *
 * ajout parameter plugin (parametre [parameter] du component)
 *
 * Inclure dans le index.html du projet la ligne suivante (asset) :
 *
 * <script src="assets/ckeditor/ckeditor.js"></script>
 *
 * ou via le CDN :
 * <script src="https://cdn.ckeditor.com/4.7.3/full/ckeditor.js"></script>
 *
 *
 * Usage :
 *  <ckeditor-p8 [(ngModel)]="data" [parameter]="true|false" [config]="{...}" debounce="500"></ckeditor-p8>
 */
@Component({
  selector: 'app-ckeditor-p8',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CKEditorCustomComponentP8),
      multi: true
    }
  ],
  template: `<textarea #host></textarea>`
})
export class CKEditorCustomComponentP8 implements OnChanges, OnDestroy, AfterViewChecked, AfterViewInit {

  @Input() config: any;
  @Input() readonly: boolean;
  @Input() debounce: string;
  @Input() parameter: boolean = false;

  @Output() change = new EventEmitter();
  @Output() editorChange = new EventEmitter();
  @Output() ready = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Output() focus = new EventEmitter();
  @Output() contentDom = new EventEmitter();

  @Output() fileUploadRequest = new EventEmitter();
  @Output() fileUploadResponse = new EventEmitter();
  @Output() paste = new EventEmitter();
  @Output() drop = new EventEmitter();

  @ViewChild('host', { static: true }) host: any;

  _value = '';
  instance: any;
  debounceTimeout: any;
  zone: NgZone;

  /**
   * Constructor
   */
  constructor(zone: NgZone) {
    this.zone = zone;

  }

  get value(): any { return this._value; }
  @Input() set value(v) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.readonly && this.instance) {
      this.instance.setReadOnly(changes.readonly.currentValue);
    }
  }

  /**
   * On component destroy
   */
  ngOnDestroy() {
    if (this.instance) {
      setTimeout(() => {
        this.instance.removeAllListeners();
        if (CKEDITOR.instances && CKEDITOR.instances[this.instance.name]) {
          CKEDITOR.instances[this.instance.name].destroy();
        }
        this.instance.destroy();
        this.instance = null;
      });
    }
  }

  /**
   * On component view init
   */
  ngAfterViewInit() {
    this.ckeditorInit(this.config || {});
  }

  /**
   * On component view checked
   */
  ngAfterViewChecked() {
    this.ckeditorInit(this.config || {});
  }

  /**
   * Value update process
   */
  updateValue(value: any) {
    this.zone.run(() => {
      this.value = value;

      this.onChange(value);

      this.onTouched();
      this.change.emit(value);
    });
  }

  /**
   * CKEditor init
   */
  ckeditorInit(config: any) {
    if (typeof CKEDITOR === 'undefined') {
      console.warn('CKEditor 4.x is missing (http://ckeditor.com/)');
    } else {
      // Check textarea exists
      if (this.instance || !this.documentContains(this.host.nativeElement)) {
        return;
      }
    }

    if (this.readonly) {
      config.readOnly = this.readonly;
    }

    // plugin parameter
    if (this.parameter) {
      // config.extraPlugins = 'widget,parameter';

      config.removePlugins = 'forms';
      config.extraPlugins = 'parameter';
      config.allowedContent = true;

      // CKEDITOR.plugins.addExternal('widgetselection', '/assets/scripts/ckeditor/widgetselection/', 'plugin.js');
      // CKEDITOR.plugins.addExternal('lineutils', '/assets/scripts/ckeditor/lineutils/', 'plugin.js');
      // CKEDITOR.plugins.addExternal('widget', '/assets/scripts/ckeditor/widget/', 'plugin.js');
      CKEDITOR.plugins.addExternal('parameter', '/assets/scripts/ckeditor/parameter/', 'plugin.js');
    }

    this.instance = CKEDITOR.replace(this.host.nativeElement, config);

    // Set initial value
    this.instance.setData(this.value);

    // listen for instanceReady event
    this.instance.on('instanceReady', (evt: any) => {
      if (this.instance.getData() !== this.value) {
        this.instance.setData(this.value);
      }

      // send the evt to the EventEmitter
      this.ready.emit(evt);

      // hack pour modifier le backgroundImage qui contient l'icone d'ajout
      // sinon il pointe sur https://cdn.ckeditor.com/4.7.3/full/assets/scripts/ckeditor/parameter/icons/add-field.png?t=H8DA
      const elt =  <HTMLElement>document.querySelector('.cke_button__insertparameter_icon');
      if (elt) {
        const sequence = UtilsService.generateSequence();
        elt.style.backgroundImage = `url(assets/scripts/ckeditor/parameter/icons/add-field.png?t=${sequence})`;
      }
    });

    // CKEditor change event
    this.instance.on('change', (evt: any) => {
      this.onTouched();
      const value = this.instance.getData();

      // Debounce update
      if (this.value !== value) {
        if (this.debounce) {
          if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
          }

          this.debounceTimeout = setTimeout(() => {
            this.updateValue(value);
            this.debounceTimeout = null;
          }, parseInt(this.debounce, 10));

          // Live update
        } else {
          this.updateValue(value);
        }
      }

      // Original ckeditor event dispatch
      this.editorChange.emit(evt);
    });

    // CKEditor blur event
    this.instance.on('blur', (evt: any) => {
      this.blur.emit(evt);
    });

    // CKEditor focus event
    this.instance.on('focus', (evt: any) => {
      this.focus.emit(evt);
    });

    // CKEditor contentDom event
    this.instance.on('contentDom', (evt: any) => {
      this.contentDom.emit(evt);
    });

    // CKEditor fileUploadRequest event
    this.instance.on('fileUploadRequest', (evt: any) => {
      this.fileUploadRequest.emit(evt);
    });

    // CKEditor fileUploadResponse event
    this.instance.on('fileUploadResponse', (evt: any) => {
      this.fileUploadResponse.emit(evt);
    });

    // CKEditor paste event
    this.instance.on('paste', (evt: any) => {
      this.paste.emit(evt);
    });

    // CKEditor drop event
    this.instance.on('drop', (evt: any) => {
      this.drop.emit(evt);
    });

    // Add Toolbar Groups to Editor. This will also add Buttons within groups.
    // this.toolbarGroups.forEach(group => {
    //   group.initialize(this);
    // });
    // // Add Toolbar Buttons to Editor.
    // this.toolbarButtons.forEach(button => {
    //   button.initialize(this);
    // });
  }


    /**
     * Implements ControlValueAccessor
     */
    writeValue(value: any) {
      this._value = value;

      if (this.instance) {
        this.instance.setData(value);
      }
    }
    onChange(_: any) {}
    onTouched() {}
    registerOnChange(fn: any) {
      this.onChange = fn;
    }
    registerOnTouched(fn: any) {
      this.onTouched = fn;
    }

  private documentContains(node: Node) {
      return document.contains ? document.contains(node) : document.body.contains(node);
    }
}
