<div fxLayout="column" *ngIf="subscriptions && subscriptions.length > 0" [style.background-color]="'#fbfaf9'">
  <mat-card>
    <mat-card-subtitle>Contacts qui ont accès au ticket</mat-card-subtitle>
    <mat-card-content>
      <mat-chip-list>
        <mat-chip *ngFor="let sub of subscriptions">
          {{sub.last_name}} {{sub.first_name}}
        </mat-chip>
      </mat-chip-list>
    </mat-card-content>
  </mat-card>
</div>
