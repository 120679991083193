import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SubSink} from "@Common/core/utils/subsink";
import {TicketsLiesService} from "../../../services/tickets-lies/tickets-lies.service";
import {TicketsLies} from "../../../services/tickets-lies/tickets-lies.model";
import {Ticket, TicketService} from "../../../services";
import {UntypedFormControl} from "@angular/forms";
import {Observable, of} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap} from "rxjs/operators";
import {Pagination} from "@Common/widgets/general/pagination";
import {DialogData, DialogValidationComponent} from "@Common/widgets/general/dialog-validation";
import {MatDialog} from "@angular/material/dialog";
import {UxNewTicketAgentModaleComponent} from "../../../../solver/ux-content-holder/ux-new-ticket-agent-modale/ux-new-ticket-agent-modale.component";
import {UxTicketViewModaleComponent} from "../../../../solver/ux-content-holder/ux-ticket-view-modale/ux-ticket-view-modale.component";
import {TypeLiaisonComponent} from "../../../widgets/widget/type-liaison/type-liaison.component";


@Component({
  selector: 'app-ux-tickets-lies-ticket',
  templateUrl: './ux-tickets-lies-ticket.component.html',
  styleUrls: ['./ux-tickets-lies-ticket.component.scss']
})
export class UxTicketsLiesTicketComponent implements OnInit, OnDestroy {
  @Input() ticket: Ticket;
  @Output() ticketsALier: EventEmitter<TicketsLies[]> = new EventEmitter<TicketsLies[]>();

  public ticketsLies: TicketsLies[] = [];
  public linksLabel: {'id': number, 'source':string, 'target': string}[];
  public linksGroupByLabel: Map<string, TicketsLies[]> = new Map<string, TicketsLies[]>();

  public ticketControl = new UntypedFormControl('');
  public ticketsSearch: Observable<Ticket[]>;
  public loading: boolean;

  private subSink: SubSink = new SubSink();
  public placeholder_search: string = 'Rechercher un ticket par son N° ou son titre';

  constructor(private dialog: MatDialog,
              private ticketsLiesSvc: TicketsLiesService,
              private ticketSvc: TicketService) {
  }

  ngOnInit() {
    this.subSink.sink = this.ticketsLiesSvc
      .getLiaisonsTypes()
      .subscribe(_data => {
        this.linksLabel = _data['links_labels'];
        this._fetchAll();
      });
    this._attachEvent();
  }

  /**
   * Regroupe les liaisons par label
   * @private
   */
  private _updateLinksGroupByLabel() {
    this.linksGroupByLabel = this.ticketsLies.reduce(
      (acc, val) => {
        const key = this._getLinkLabelFromTL(val);
        acc.set(key, (acc.get(key) || []).concat(val));
        return acc;
      },
      new Map<string, TicketsLies[]>());
  }

  /**
   * Retourne le label de la liaison link
   * @param link: liaison entre 2 tickets
   * @private
   */
  private _getLinkLabelFromTL(link: TicketsLies) {
    const ticketId = this._getCurrentTicketId();
    const pos = (link.source === ticketId)?"source":"target";
    const labels = this.linksLabel.find(item => item.id === link.type_link);
    return labels[pos];
  }

  /**
   * Met à jour la liste des liaisons dont le ticket courant est la source ou la cible
   * @private
   */
  private _fetchAll() {
    if (this.ticket) {
      const filtres = new Map<string, string>();
      filtres.set("ticket", this.ticket.id.toString());
      this.subSink.sink = this.ticketsLiesSvc
        .listAllItems(filtres)
        .subscribe(_data => {
          this.ticketsLies = _data.list;
          this._updateLinksGroupByLabel();
        });
    }
  }

  private _getCurrentTicketId() {
    return (this.ticket)?this.ticket.id:0;
  }

  /**
   * Supprime la liaison tl
   * @param tl: liaison
   * @param idx: index
   */
  delete_tl(tl: TicketsLies) {
    const dialogData = new DialogData();
    dialogData.title = 'Tickets liés';
    dialogData.message = 'Souhaitez-vous supprimer cette laison ?';
    const dialogRef = this.dialog.open(DialogValidationComponent, {data: dialogData, disableClose: true});
    dialogRef.updatePosition({top: '100px'});

    this.subSink.sink = dialogRef
      .afterClosed()
      .subscribe(result => {
        if (result) {
          if (this.ticket) {
            this.subSink.sink = this.ticketsLiesSvc.delete(tl).subscribe(() => this._fetchAll());
          } else {
            // Le ticket courant est en cours de création
            const idx = this.ticketsLies.findIndex(
              (item) => {
                return tl.target === item.target &&
                  tl.source === item.source &&
                  tl.type_link === item.type_link;
              }
            )
            this.ticketsLies.splice(idx, 1);
            this._updateLinksGroupByLabel();
            this._updateTicketALier();
          }
        }
      });
  }

  /**
   * Affiche dans une modale le ticket ticketId
   * @param tl: tickets liés
   */
  previewTicketLie(tl: TicketsLies) {
    const ticketId: number = (this.ticket.id === tl.target) ? tl.source : tl.target;
    const dialogRef = this.dialog.open(UxTicketViewModaleComponent, {data: ticketId, disableClose: true});
    dialogRef.updatePosition({top: '100px'});
    dialogRef.updateSize('1024px', '768px');
    this.subSink.sink = dialogRef
      .afterClosed()
      .subscribe(result => {});
  }

  /**
   * Créer l'observable ticketsSearch qui contiendra la liste des tickets correspondant au filtre saisie
   * @private
   */
  private _attachEvent() {
    this.ticketsSearch = this.ticketControl.valueChanges
      .pipe(
        debounceTime(350), distinctUntilChanged(),
        filter((searchTerm) => searchTerm.length >= 1),
        switchMap(val => {
          this.loading = true;
          return this.ticketSvc.searchTickets(val);
        }),
        map((pagination: Pagination) => {
          this.loading = false;
          return pagination.list;
        }),
        catchError(err => of<Ticket[]>([])));
  }

  /**
   * Créer une liaison entre le ticket courant et le ticket passé en paramètre si la liaison n'existe pas
   * @param ticket
   */
  onSelectTL(ticket: Ticket) {
    if (ticket) {
      const find = this.ticketsLies.find((_tl) => _tl.target === ticket.id || _tl.source === ticket.id);
      if (!find) {
        const dialogRef = this.dialog.open(TypeLiaisonComponent, {
          data: {
            linksLabel: this.linksLabel,
            currentTicket: this._getCurrentTicketId(),
            otherTicket: ticket.id
          },
          disableClose: true
        });
        dialogRef.updatePosition({top: '100px'});
        this.subSink.sink = dialogRef
          .afterClosed()
          .subscribe(result => {
            if (result) {
              if (this.ticket) {
                this.subSink.sink = this.ticketsLiesSvc
                  .create(result)
                  .subscribe(() => this._fetchAll());
              } else {
                // Le ticket courant est en cours de création
                this.ticketsLies.push(result);
                this._updateLinksGroupByLabel();
                this._updateTicketALier();
              }
            }
          });
      }
    }
    this._attachEvent();
  }

  /**
   * Met à jour les liaisons lorsque le ticket courant est en cours de création
   * @private
   */
  private _updateTicketALier() {
    this.ticketsALier.emit(this.ticketsLies);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  /**
   * Créer un nouveau ticket à lier avec le ticket courant
   */
  createTicketLie() {
    const dialogRef = this.dialog.open(UxNewTicketAgentModaleComponent);
    dialogRef.updatePosition({top: '100px'});
    this.subSink.sink = dialogRef
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.onSelectTL(result);
        }
      });
  }

  currentTicketIsSource(tl: TicketsLies) {
    return tl.source === this._getCurrentTicketId();
  }
  currentTicketIsTarget(tl: TicketsLies) {
    return tl.target === this._getCurrentTicketId();
  }

  getTargetTitle(tl: TicketsLies){
    if(this.ticket) {
      return `${tl.target_obj.title.substring(0, 30)} ${tl.target_obj.title.length > 30 ? '...' : ''} (${tl.target})`;
    }
    else {
      return `En cours de création (${tl.target})`;
    }
  }

  getSourceTitle(tl: TicketsLies){
    if(this.ticket) {
      return `${tl.source_obj.title.substring(0, 30)} ${tl.source_obj.title.length > 30 ? '...' : ''} (${tl.source})`;
    }
    else {
      return `En cours de création (${tl.source})`;
    }
  }
}
