<mat-dialog-actions>
  <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="50px">
    <div mat-dialog-title>
      Type de liaison entre le ticket courant {{data['currentTicket']}} et le ticket {{data['otherTicket']}}
    </div>

    <mat-select
      placeholder="Type de liaison ..."
      (selectionChange)="selectLink($event)">
      <mat-option *ngFor="let label of linkLabels"
                  [matTooltip]="getLabel(label)"
                  [value]="label">
        {{label}}
      </mat-option>
    </mat-select>

    <div fxFlexAlign="end">
      <button mat-raised-button
              [matDialogClose]="false">
        Annuler
      </button>
      <button mat-raised-button color="primary"
              (click)="save()">
        Créer
      </button>
    </div>
  </div>
</mat-dialog-actions>
