<div fxLayout="column"
     fxFlex
     class="main-canvas"
     [ngClass]="{'main-canvas-modale': inModale}">
  <div *ngIf="ticket" #ticketWrapper fxLayout="column" class="ticket-wrapper" fxFlex>
    <div class="overflow-container">
      <div class="overflow-content">
        <div *ngIf="isStaff" fxLayout="row" fxLayoutAlign="space-between center" style="margin-left: 10px;">
          <app-ux-ticket-flags [ticket]="ticket"
                               [msgCreate]="'Le ticket a bien été mis en favori'"
                               [msgUpdate]="'La couleur de drapeau du favori a bien été mise à jour'"
                               [msgDelete]="'Le ticket a bien été supprimé des favoris'"></app-ux-ticket-flags>

          <app-ux-navigation-previous-next-ticket
            [ticket]="ticket" [ticketsIds]="ticketsIds"
            (next)="onNextPrevious($event)" (previous)="onNextPrevious($event)"></app-ux-navigation-previous-next-ticket>

        </div>

        <div class="ticket-canvas">
          <mat-card>
            <mat-card-content>
              <div fxLayout="row" class="title-row">
                <span class="mat-subheading-1 title">
                  <p8-layout-settings-header [card]="false" [titlePath]="[getTicketNumber(), ticket?.title]" [separator]="':'">
<!--                    <p8-hd-nav-bar-button no-card *ngIf="displayToggleNavBar" (openedChange)="toggleSidenav($event)" [opened]="opened"></p8-hd-nav-bar-button>-->
                  </p8-layout-settings-header>
                </span>
                <span fxFlex></span>
                <span class="mat-subheading-1">{{ticket?.status_object?.label}}</span>
              </div>
              <div class="mat-card-subtitle">
                <span class="mat-body-1 name">
                  {{ticket.individu_object.first_name}} {{ticket.individu_object.last_name}}
                  <span *ngIf="isStaff">({{ticket.individu_object?.uid}})</span>
                </span>
                <span>{{ ticket.created | date:"medium" }}</span>
                <span *ngIf="ticket.submitter_email">&nbsp; <a href="mailto:{{ticket.submitter_email}}">{{ticket.submitter_email}}</a></span>
                <span *ngIf="ticket.telephone && isStaff" matTooltip="{{ticket.telephone}}">&nbsp; {{ticket.telephone}}</span>
              </div>
              <div class="description-canvas" [innerHTML]="ticket.description"></div>

              <app-attached-files [layout]="'horizontal'" [piecesJointes]="ticket?.pieces_jointes"></app-attached-files>
            </mat-card-content>
          </mat-card>
        </div>

        <mat-divider></mat-divider>

        <mat-expansion-panel *ngIf="! isStaff && ticket">
          <mat-expansion-panel-header>
            Informations contacts et prise en charge
          </mat-expansion-panel-header>
          <app-ux-affectation-display [ticket]="ticket"></app-ux-affectation-display>
          <app-ux-subscription-display [ticket]="ticket"></app-ux-subscription-display>
        </mat-expansion-panel>
        <!-- onglets commentaires / annotations / message direct / historique -->

        <mat-tab-group [(selectedIndex)] = "selectedTab"
                       (focusChange)="onFocus($event)">
          <!-- commentaires public / privés -->
          <mat-tab>
            <ng-template matTabLabel>
              <span matBadge="{{comments ? comments.length : 0}}" matBadgeOverlap="false">
                {{isStaff?'Fil de commentaires / Notes internes':'Fil de commentaires'}}
              </span>
            </ng-template>
            <mat-progress-bar *ngIf="loadingComments" class="p8-cm_data-table__progress-bar"
                              mode="indeterminate"></mat-progress-bar>
            <div *ngIf="comments && comments.length > 0" fxLayout="column" [style.background-color]="'#fbfaf9'">
              <app-ux-comment
                *ngFor="let comment of comments"
                [previewFiles]="true"
                [comment]="comment">
              </app-ux-comment>
            </div>
          </mat-tab>
          <!-- annotations sur le ticket -->
          <mat-tab *ngIf="this.showAnnotations">
            <ng-template matTabLabel>
              <span (click)="synchronisationAnnotationsTabs($event)" matBadge="{{annotations ? annotations.length : 0}}" matBadgeOverlap="false">Annotations privées</span>
            </ng-template>
            <mat-progress-bar *ngIf="loadingAnnotations" class="p8-cm_data-table__progress-bar"
                              mode="indeterminate"></mat-progress-bar>
            <div *ngIf="selectedTab == 1 && annotations && annotations.length > 0 && this.showAnnotations" fxLayout="column" [style.background-color]="'#fbfaf9'">
              <app-ux-annotation
                *ngFor="let annotation of annotations"
                [ticket]="ticket"
                [ticketChangeStream]="ticketChangeStream"
                [annotation]="annotation">
              </app-ux-annotation>
            </div>
          </mat-tab>
          <!-- envoi notif mail -->
          <mat-tab *ngIf="this.showNotifMail">
            <ng-template matTabLabel><span>Message direct aux agents traitants ou groupes</span></ng-template>
            <app-ux-ticket-message
              [ticket]="ticket"
              [ticketChangeStream]="ticketChangeStream"></app-ux-ticket-message>
          </mat-tab>
          <!-- historique du ticket -->
          <mat-tab label="Historique" *ngIf="showHistoric">
            <div class="overflow-historic">
              <app-ux-history-sidebar *ngIf="selectedTab === 3"
                [ticket]="ticket"
                [ticketChangeStream]="ticketChangeStream">
              </app-ux-history-sidebar>
            </div>
          </mat-tab>
        </mat-tab-group>

      </div>
    </div>
  </div>

  <app-ux-new-comment
    *ngIf="ticket"
    [ticket]="ticket"
    [ticketChangeStream]="ticketChangeStream"
    (commentadded)="doCommentAdd($event)">
  </app-ux-new-comment>
</div>
