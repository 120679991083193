import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";

/**
 * Transforme une chaîne (value) en un valeur "slug" pour une URL par exemple
 *
 */
@Pipe({ name: 'slugify'})
export class SlugifyPipe implements PipeTransform  {
  constructor() {}

  transform(value, caseString: 'lower' | 'upper' = null) {
    if (! value) {
      return;
    }

    let slug = value.trim();

    if ( caseString ) {
      switch (caseString) {
        case 'upper':
          slug = slug.toUpperCase();
          break;
        default:
        case 'lower' :
          slug = slug.toLowerCase();
          break;
      }
    } else {
      slug = slug.toLowerCase();
    }

    slug = slug.replace(/[^a-zA-Z0-9\s-]/g, ' ');
    slug = slug.replace(/[\s-]+/g, '-');

    return slug;
  }
}
