import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {Ticket} from '../common/services';

import {NewsFeedService} from '../common/services/news-feed/newsFeedService';
import {NewsFeed} from '../common/services/news-feed/news-feed';
import {ArticleService} from '@Services/faq';
import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';

import {DOCUMENT} from '@angular/common';
import {AccountLevelService} from '../common/account-level/account-level.service';
import {IndividuSafeService} from '../common/services/individu_helpdesk/individu.service';

import { environment } from '@Env/environment';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {NewsFeedComponent} from '../common/news-feed/news-feed.component';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-helpdesk-acceuil',
  templateUrl: './acceuil.component.html',
  styleUrls: ['./acceuil.component.css']
})
export class AcceuilComponent implements OnInit, OnDestroy {
  @ViewChild(NewsFeedComponent) newsFeedComponent;
  _ticket = <Ticket> {
    title: 'Testing if affche',
    status: 1,
    description: '',
    priority: null,
    submitter_email: ''
  };
  newsFeeds: NewsFeed[];

  isAdmin = false;
  isDispatcher = false;
  isSolver = false;
  isSuperviseur = false;

  specialCase = false;
  notes = true;

  term = new UntypedFormControl();

  last_order: number;

  subs: Subscription[] = [];

  environment = environment;
  selectedTabIndex = 0;

  constructor(public dialog: MatDialog,
              private nfService: NewsFeedService,
              private individuService: IndividuSafeService,
              private articleSvc: ArticleService,
              private accountLevel: AccountLevelService,
              @Inject(DOCUMENT) private document: any) {

  }

  ngOnInit() {
    this.isAdmin = this.accountLevel.isAdmin();
    this.isDispatcher = this.accountLevel.isDispatcher();
    this.isSolver = this.accountLevel.isSolver();
    this.isSuperviseur = this.accountLevel.isSuperviseur();
    this.fetchNewsFeed();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.newsFeeds, event.previousIndex, event.currentIndex);
    this.newsFeeds.forEach(nf => {
      nf.order = this.newsFeeds.indexOf(nf);
      this.newsFeedComponent.updateNewsFeed(nf);
    });
  }

  isAdminOrisSuperviseur() {
    return (this.accountLevel.isAdmin() || this.accountLevel.isSuperviseur());
  }

  isStaff() {
    return this.accountLevel.isStaff();
  }

  getNewsFeeds($event) {
    this.fetchNewsFeed(!!$event);
  }

  fetchNewsFeed(archived= false) {
    this.subs.push(this.nfService.list().pipe(
      map(nf => nf.filter(f => f.archived === archived))
    ).subscribe(nFeeds => {
      this.newsFeeds = nFeeds;
      this.checkSpecialCase();
    }));
  }
  checkSpecialCase() {
    if (!this.newsFeeds) {
      return;
    }

    if (this.newsFeeds.length === 0 && !this.isAdmin) {
      this.specialCase = true;
    }
  }

  createFeed() {
    const feed = <NewsFeed> {
      content: 'Veuillez remplir cette carte.',
      archived: false,
      order: this.last_order
    };

    if (!this.newsFeeds) {
      this.newsFeeds = [];
    }

    this.newsFeeds.unshift(feed);
  }

  addActualite() {
    this.subs.push(this.nfService.list().subscribe(value => {
      this.last_order = Math.max.apply(Math, value.map(function(o) { return o.order; })) + 1;
      this.createFeed();
    }));
  }

  goArticle(article) {
    const urlRoot = 'http://localhost:4213';
    // const urlFaqArticle = `${urlRoot}/faq/faqdetail/${article.id}`;
    const urlFaqArticle = `${urlRoot}/faqdetail/${article.id}`;
    this.document.location.href = urlFaqArticle;
    // window.location.href = urlFaqArticle;

    // this.location.prepareExternalUrl(urlFaqArticle);
    // this.location.go(urlFaqArticle);

    // this.router.navigateByUrl(urlFaqArticle);
    // this.router.navigate(['faqdetail', article.id]);
  }

  ngOnDestroy(): void {
    this.subs.forEach(_sub => _sub.unsubscribe());
  }
}
