import {Subject, Subscription, throwError as observableThrowError} from 'rxjs';
import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output} from '@angular/core';
import {PieceJointe} from '../../services';
import {PiecejointeService} from '../../services';
import {AuthService} from '@Services/auth';
import {catchError} from 'rxjs/operators';
import {FileSizePipe} from "@Common/widgets/pipes/file-size";
import {SnackBarToastyService, typeToast} from "@Common/widgets/general/snack-bar";
import {Upload} from "../../services/piecejointe/upload.interface";
import {SubSink} from "@Common/core/utils/subsink";

@Component({
  selector: 'app-piece-jointe-upload',
  templateUrl: './piece-jointe-upload.component.html',
  styleUrls: ['./piece-jointe-upload.component.css'],

})
export class PieceJointeUploadComponent implements OnInit, OnDestroy {
  @Input()
  pieceJointe: PieceJointe;

  @Input()
  launchSignal: Subject<any>;

  @Input()
  coupled: boolean;

  @Output()
  onDestroy: EventEmitter<PieceJointe> = new EventEmitter<PieceJointe>();

  @Output()
  onFinished: EventEmitter<PieceJointe> = new EventEmitter<PieceJointe>();

  @Output()
  onProgress: EventEmitter<Upload> = new EventEmitter<Upload>();

  @Input()
  maxFileSize: number;


  state = '';
  warning = false;
  error = false;
  succes = false;

  unique: number;
  sent = false;
  loadingMask = 'loadingMask';
  loading = false;
  subscriptionSignal: Subscription;
  message: string;
  tooltip: string;

  warningColor = '#FFCC00';
  errorColor = 'red';
  succesColor = 'lightblue';

  objectToUpload: any;
  upload: Upload;
  subSink = new SubSink();

  constructor(@Inject(LOCALE_ID) private locale: string,
              private snackSvc: SnackBarToastyService,
              private pieceJointeService: PiecejointeService) {
  }

  ngOnInit() {
    this.subscriptionSignal = this.launchSignal.subscribe(objectToLaunch => this.gotLaunchSignal(objectToLaunch));
    this.checkFileSize();
  }

  checkFileSize() {
    if (this.maxFileSize) {
      this.warning = this.maxFileSize < this.pieceJointe.datafile.size;
    }

    if (this.warning) {
      const fp = new FileSizePipe(this.locale);
      this.state = this.warningColor;
      const fileSize = fp.transform(this.pieceJointe.datafile.size, 2, this.locale);
      // tslint:disable-next-line:max-line-length
      this.tooltip = `Le fichier choisi est trop grand, il ne sera pas pris en compte. La taille maximale supportée est ${this.maxFileSize / 1000000} Mo. Le fichier que vous avez choisi a une taille de ${fileSize}`;
      this.snackSvc.toasty('Pièce jointe', this.tooltip, typeToast.warning, 5500);
      this.destroyMe();
    }
  }

  gotLaunchSignal(obj: any): void {
    this.objectToUpload = obj;
    this.uploadFile();
  }

  destroyMe() {
    this.onDestroy.emit(this.pieceJointe);
  }

  uploadFile() {
    if (this.sent) {
      return;
    }

    this.loading = true;
    // this part is just for help desk
    this.pieceJointe.object_id = this.objectToUpload.id;
    this.pieceJointe.content_type = this.objectToUpload.content_type;

    this.subSink.sink = this.pieceJointeService
      .upload(this.pieceJointe).subscribe((upload: Upload) => {
        this.upload = upload;
        this.onProgress.emit(upload);
        if (upload.state === 'DONE') {
          this.loading = false;
          this.doSucces(this.pieceJointe);
        }
        },
        error => {
          this.loading = false;
          this.doError(error);
        });
  }

  doSucces(pieceJointe): void {
    this.sent = true;
    this.succes = true;
    this.state = this.succesColor;
    this.tooltip = 'Votre fichier est bien sauvegardé';
    this.onFinished.emit(pieceJointe);
  }

  doError(error: any): void {
    this.error = false;
    this.state = this.errorColor;
    this.tooltip = error;
    this.onFinished.emit(null);
  }

  ngOnDestroy(): void {
    this.subscriptionSignal.unsubscribe();
    this.subSink.unsubscribe();
  }


}
