import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { QuillModule } from 'ngx-quill';
import {CommentComponent} from './comment/comment.component';

import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommmentsHolderComponent } from './commments-holder/commments-holder.component';
import {CommonModuleP8} from '@Common/common-p8.module';
import { LiveUpdateComponent } from './live-update/live-update.component';
import {PieceJointeUploaderHDModule} from './piece-jointe-uploader/piece-jointe-uploader.module';
import { HistoriqueComponent } from './historique/historique.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { CustomHiddenDirective} from './directives/activate-drop.directive';
import { StefDynamicDirective } from './directives/stef-dynamic.directive';
import { NewsFeedComponent } from './news-feed/news-feed.component';

import { PopUpCkeditorComponent } from './news-feed/pop-up-ckeditor/pop-up-ckeditor.component';
import {WarrningCloseComponent} from './warrning-close/warrning-close.component';
import { ColorPickerPopupComponent } from './color-picker-popup/color-picker-popup.component';

import { MatAngularModule } from '@Common/mat-angular.module';

import {PubSubService} from '@Common/core/services';
import {CustomPaginatorService} from './custom-paginator/custom-paginator.service';
import { MineswepperComponent } from './mineswepper/mineswepper.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {UxSidebarTicketComponent} from './ux-ticket-vue/ux-sidebar-ticket/ux-sidebar-ticket.component';
import {UxCommentComponent} from './ux-ticket-vue/ux-comment/ux-comment.component';
import {UxTicketVueComponent} from './ux-ticket-vue/ux-ticket-vue.component';
import { UxTicketBodyComponent } from './ux-ticket-vue/ux-ticket-body/ux-ticket-body.component';
import { UxNewCommentComponent } from './ux-ticket-vue/ux-new-comment/ux-new-comment.component';
import { UxSidebarTicketTreeComponent } from './ux-ticket-vue/ux-sidebar-ticket/ux-sidebar-ticket-tree/ux-sidebar-ticket-tree.component';

import { UxAffectationComponent } from './ux-ticket-vue/ux-sidebar-ticket/ux-affectation/ux-affectation.component';
import { UxSubscriptionSidebarComponent } from './ux-ticket-vue/ux-sidebar-ticket/ux-subscription-sidebar/ux-subscription-sidebar.component';
import { UxHistorySidebarComponent } from './ux-ticket-vue/ux-sidebar-ticket/ux-history-sidebar/ux-history-sidebar.component';
import { UxConfirmationChangePopupComponent } from './ux-ticket-vue/ux-sidebar-ticket/ux-sidebar-ticket-tree/ux-confirmation-change-popup/ux-confirmation-change-popup.component';
import {AccountLevelService} from './account-level/account-level.service';
import { UxPriorityComponent } from './ux-ticket-vue/ux-sidebar-ticket/ux-priority/ux-priority.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { UxDeclareWrongDispatchComponent } from './ux-declare-wrong-dispatch/ux-declare-wrong-dispatch.component';
import { UxLinkComponent } from './ux-ticket-vue/ux-sidebar-ticket/ux-link/ux-link.component';
import {RouterModule} from '@angular/router';
import { SelectAutocompleteComponent } from './select-autocomplete/select-autocomplete.component';
import { UxAffectationAgentComponent } from './ux-ticket-vue/ux-sidebar-ticket/ux-affectation/ux-affectation-agent/ux-affectation-agent.component';
import {NavBarButtonComponent} from './nav-bar-button/nav-bar-button.component';

import {ChartsModule} from 'ng2-charts';
import {OrderByPipe} from './pipes/order-by.pipe';
import {UxSubscriptionDisplayComponent} from './ux-ticket-vue/ux-sidebar-ticket/ux-subscription-display/ux-subscription-display.component';
import { UxTicketNumberComponent } from './ux-ticket-vue/ux-sidebar-ticket/ux-ticket-number/ux-ticket-number.component';
import {NotifsHDNavComponent} from './notification/notifications-component';
import {AddContactsComponent} from './form-fields/contacts/add-contacts/add-contacts.component';
import { TitleTicketComponent } from './form-fields/title/title-ticket.component';
import { DescriptionTicketComponent } from './form-fields/description/description-ticket.component';
import { FilesTicketComponent } from './form-fields/files/files-ticket.component';
import { CategoryTicketComponent } from './form-fields/category/category-ticket.component';
import { FormCreateTicketComponent } from './form-fields/form-create-ticket.component';
import {AutocompleteCategorieComponent} from './autocomplete-categorie/autocomplete-categorie.component';
import { UxTicketIpComponent } from './ux-ticket-vue/ux-sidebar-ticket/ux-ticket-ip/ux-ticket-ip.component';
import { UxTicketAutoClosedComponent } from './ux-ticket-vue/ux-sidebar-ticket/ux-ticket-auto-closed/ux-ticket-auto-closed.component';
import { UxNavigationPreviousNextTicketComponent } from './ux-ticket-vue/ux-navigation-previous-next-ticket/ux-navigation-previous-next-ticket.component';
import { UxTicketFlagsComponent } from './ux-ticket-vue/ux-ticket-flags/ux-ticket-flags.component';
import {ListFiltersComponent} from './filters/list-filters/list-filters.component';
import { UxAnnotationComponent } from './ux-ticket-vue/ux-annotation/ux-annotation.component';
import {FilterComponent} from './filters/filter/filter.component';
import { FilterStepperComponent } from './filters/filter-stepper/filter-stepper.component';
import { WidgetsFilterComponent } from './widgets/widgets-filter/widgets-filter.component';
import {ListWidgetsComponent} from './widgets/list-widgets/list-widgets.component';
import {WidgetComponent} from './widgets/widget/widget.component';
import {TileComponent} from './widgets/graphs/tuile/tuile.component';
import {TileConfigComponent} from './widgets/widget/tile/tile.component';
import { ChartComponent } from './widgets/graphs/chart/chart.component';
import {ChartConfigComponent} from './widgets/widget/chart/chart.component';
import {WidgetProfilComponent} from './widgets/widget/profil/widget-profil.component';
import {WidgetConfigComponent} from './widgets/widget/config/widget-config.component';
import {FilterConfigComponent} from './filters/filter/config/filter-config.component';
import {FilterProfilComponent} from './filters/filter/profil/filter-profil.component';
import {ListWidgetsModaleFilterComponent} from './filters/list-widgets/list-widgets.component';
import { SearchSelectDemandeurComponent } from './search-select-demandeur/search-select-demandeur.component';
import { UxTicketMessageComponent } from './ux-ticket-vue/ux-ticket-message/ux-ticket-message.component';
import { QuillEditorComponent } from './quill-editor/quill-editor.component';
import {EllipsisComponent} from './ellipse/ellipse.component';
import {UxTicketsLiesTicketComponent} from './ux-ticket-vue/ux-sidebar-ticket/ux-tickets-lies-ticket/ux-tickets-lies-ticket.component';
import { PhoneTicketComponent } from './form-fields/phone/phone-ticket.component';
import { TypeLiaisonComponent } from './widgets/widget/type-liaison/type-liaison.component';
import { SearchSelectIndividuComponent } from './search-select-individu/search-select-individu.component';
import { UxAffectationDisplayComponent } from './ux-ticket-vue/ux-sidebar-ticket/ux-affectation-display/ux-affectation-display.component';
import {AttachedFilesComponent} from './ux-ticket-vue/ux-attached-files/attached-files.component';
import { ApplicantTicketComponent } from './form-fields/applicant/applicant-ticket.component';
import { UxGroupSubscriptionComponent } from './ux-ticket-vue/ux-sidebar-ticket/ux-group-subscription/ux-group-subscription.component';
import { ListSharedFiltersComponent } from './filters/list-shared-filters/list-shared-filters.component';
import { SortFilterComponent } from './filters/sort-filter/sort-filter.component';
import { HelpdeskHeaderFilterDurationComponent } from './widgets/header-filter/helpdesk-header-filter-duration/helpdesk-header-filter-duration.component';

@NgModule({
    imports: [
        CommonModule,
        CommonModuleP8,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        PieceJointeUploaderHDModule,
        MatAngularModule,
        ReactiveFormsModule,
        MatGridListModule,
        MatInputModule,
        MatFormFieldModule,
        QuillModule.forRoot(),
        RouterModule,
        MatInputModule,
        MatFormFieldModule,
        ChartsModule,
    ],
    declarations: [
        CommentComponent,
        CommmentsHolderComponent,
        LiveUpdateComponent,
        HistoriqueComponent,
        SubscriptionComponent,
        CustomHiddenDirective,
        StefDynamicDirective,
        NewsFeedComponent,
        PopUpCkeditorComponent,
        WarrningCloseComponent,
        ColorPickerPopupComponent,
        MineswepperComponent,
        UxCommentComponent,
        UxSidebarTicketComponent,
        UxTicketVueComponent,
        UxTicketBodyComponent,
        UxNewCommentComponent,
        UxSidebarTicketTreeComponent,
        UxAffectationComponent,
        UxSubscriptionSidebarComponent,
        UxHistorySidebarComponent,
        UxConfirmationChangePopupComponent,
        UxPriorityComponent,
        ImagePreviewComponent,
        UxDeclareWrongDispatchComponent,
        UxLinkComponent,
        SelectAutocompleteComponent,
        NavBarButtonComponent,
        UxAffectationAgentComponent,
        ChartComponent,
        OrderByPipe,
        UxSubscriptionDisplayComponent,
        UxTicketNumberComponent,
        NotifsHDNavComponent,
        AddContactsComponent,
        TitleTicketComponent,
        DescriptionTicketComponent,
        FilesTicketComponent,
        CategoryTicketComponent,
        FormCreateTicketComponent,
        AutocompleteCategorieComponent,
        UxTicketIpComponent,
        UxTicketAutoClosedComponent,
        UxNavigationPreviousNextTicketComponent,
        UxTicketFlagsComponent,
        ListFiltersComponent,
        FilterComponent,
        UxAnnotationComponent,
        FilterStepperComponent,
        FilterConfigComponent,
        FilterProfilComponent,
        WidgetComponent,
        WidgetProfilComponent,
        WidgetConfigComponent,
        WidgetsFilterComponent,
        ListWidgetsComponent,
        TileConfigComponent,
        TileComponent,
        ChartConfigComponent,
        ChartComponent,
        ListWidgetsModaleFilterComponent,
        SearchSelectDemandeurComponent,
        UxTicketMessageComponent,
        QuillEditorComponent,
        EllipsisComponent,
        UxTicketsLiesTicketComponent,
        PhoneTicketComponent,
        TypeLiaisonComponent,
        SearchSelectIndividuComponent,
        UxAffectationDisplayComponent,
        AttachedFilesComponent,
        ApplicantTicketComponent,
        UxGroupSubscriptionComponent,
        ListSharedFiltersComponent,
        SortFilterComponent,
        HelpdeskHeaderFilterDurationComponent,
    ],
    exports: [
        LiveUpdateComponent,
        CustomHiddenDirective,
        StefDynamicDirective,
        NewsFeedComponent,
        UxTicketVueComponent,
        UxTicketBodyComponent,
        SelectAutocompleteComponent,
        NavBarButtonComponent,
        OrderByPipe,
        UxSubscriptionDisplayComponent,
        NotifsHDNavComponent,
        AddContactsComponent,
        TitleTicketComponent,
        DescriptionTicketComponent,
        FilesTicketComponent,
        CategoryTicketComponent,
        FormCreateTicketComponent,
        AutocompleteCategorieComponent,
        ListFiltersComponent,
        FilterConfigComponent,
        FilterProfilComponent,
        FilterComponent,
        FilterStepperComponent,
        WidgetComponent,
        WidgetProfilComponent,
        WidgetConfigComponent,
        WidgetsFilterComponent,
        ListWidgetsComponent,
        TileConfigComponent,
        TileComponent,
        ChartConfigComponent,
        ChartComponent,
        ListWidgetsModaleFilterComponent,
        UxAnnotationComponent,
        EllipsisComponent,
        UxTicketsLiesTicketComponent,
        CommentComponent,
        UxNewCommentComponent,
        UxCommentComponent,
        AttachedFilesComponent,
        ListSharedFiltersComponent
    ],
    providers: [
        PubSubService,
        AccountLevelService,
        { provide: MatPaginatorIntl, useClass: CustomPaginatorService }
    ]
})
export class HelpdeskCommonModule {
}
