import { Injectable } from '@angular/core';
import { AppUrlConfig, ServiceBaseGeneric } from '../../base';
import { HttpClient } from '@angular/common/http';
import { ArticleModel } from '../';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatsService extends ServiceBaseGeneric<ArticleModel> {
  private static urlConfig = 'stats';

  constructor(private http: HttpClient,
              private config: AppUrlConfig) {
    super(http, config);
  }

  getKeyConfig() {
    return StatsService.urlConfig;
  }

  listByOrder(field_name: string, order: string, limit: number): Observable<ArticleModel[]> {
    const url = `${this.config.url(this.getKeyConfig())}`;

    const params = {
      field_name: field_name,
      order: order,
      limit: `${limit}`
    };
    return this.http.get<ArticleModel[]>(url, {params: params});
  }

  reload(url: string): Observable<ArticleModel[]> {
    return this.http.get<ArticleModel[]>(url);
  }

}
