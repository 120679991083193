import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {PieceJointe} from '../services';
import {Upload} from '../services/piecejointe/upload.interface';

@Component({
  selector: 'app-piece-jointe-uploader-hd',
  templateUrl: './piece-jointe-uploader.component.html',
  styleUrls: ['./piece-jointe-uploader.component.css']
})
export class PieceJointeUploaderHDComponent implements OnInit, OnDestroy {
  @Input()
  disabled: boolean;

  @Input()
  accept: string;

  @Input()
  filesRequired = false;

  @Input()
  multiple: boolean;

  @Input()
  maxFileSize = 5000000;

  /**
   * Déclenchement upload fichier pour l'objet concerné (ticket, comment) donc après que l'objet ait été créé
   */
  @Input()
  connector: Subject<any>;

  /**
   * Ràz des fichiers et indicateurs
   */
  @Input()
  reset: Subject<any>;
  /**
   * Etat de l'uploading, optionnel
   */
  @Input()
  uploading$: Subject<boolean>;
  /**
   * A la fin du téléversement des fichiers, émission
   */
  @Output()
  onFinished: EventEmitter<PieceJointe[]> = new EventEmitter<PieceJointe[]>();

  @Output()
  onProgress: EventEmitter<Upload[]> = new EventEmitter<Upload[]>();

  /**
   * A la mise à jour de la liste des fichiers à téléverser lors du traitement
   */
  @Output()
  onUpdatedFiles: EventEmitter<PieceJointe[]> = new EventEmitter<PieceJointe[]>();

  piecesJointes: PieceJointe[] = [];
  piecesUploaded: PieceJointe[] = [];
  subscriptionSignal: Subscription;
  resetSignal: Subscription;
  launcher: Subject<any> = new Subject<any>();
  collector: number;
  files: FileList;
  upload: Upload;
  uploadTotal: Upload = {state: 'IN_PROGRESS', progress: 0};
  uploadFiles: Upload[];

  @ViewChild('fileInput', { static: true }) fileInput;

  constructor() {
  }

  ngOnInit() {
    this.subscriptionSignal = this.connector.subscribe(objectToAtach => this._startUpload(objectToAtach));
    if (this.reset) {
      this.resetSignal = this.reset.subscribe(() => this.resetData());
    }
  }
  getMaxMo() {
    return `${this.maxFileSize / 1000000} Mo`;
  }
  trackByFn(index, item) {
    return index;
  }

  /**
   * Ajout de la liste des fichiers qui seront à téléverser par l'API
   * @param event
   */
  preparePJUpload(event: Event) {
    this.files = this.fileInput.nativeElement.files;
    for (let i = 0; i < this.files.length; i++) {
      this.piecesJointes.push(this.getPj(this.files.item(i)));
      this.onUpdatedFiles.emit(this.piecesJointes);
    }
  }
  /**
   * Mapping file => PieceJointe datafile
   * @param file
   */
  getPj(file: any): PieceJointe {
    return <PieceJointe> { datafile: file };
  }

  /**
   * Event sur la fin de l'upload du composant fils app-piece-jointe-upload
   * @param _pieceJointe
   */
  collectFiles(_pieceJointe) {
    this.collector++;
    if (_pieceJointe) {
      this.piecesUploaded.push(_pieceJointe);
    }
    if (this.collector === this.piecesJointes.length) {
      this._announceParent();
    }
  }

  /**
   * Event au composant parent pour annoncer que le fichier a fini d'être téléversé
   * @private
   */
  private _announceParent() {
    this.onFinished.emit(this.piecesUploaded);
    if (this.uploading$) {
      this.uploading$.next(false);
    }
    this.resetData();
  }

  /**
   * Calcul de la progression total avec l'ensemble des fichiers en cours de téléversement
   * @private
   */
  private _calculateProgress() {
    let progress = 0;
    this.uploadFiles.forEach((_upload: Upload, _idx: number) => progress += _upload.progress);
    this.uploadTotal.state = this.uploadFiles.every((_upload: Upload) => _upload.state === 'DONE') ? 'DONE' : 'PENDING';
    this.uploadTotal.progress = progress / this.piecesJointes.length;
  }

  /**
   * Lancement des téléversements
   * @param objectToAtach
   * @private
   */
  private _startUpload(objectToAtach: any) {
    this.collector = 0;
    if (this.piecesJointes && this.piecesJointes.length > 0) {
      this.launcher.next(objectToAtach);
      if (this.uploading$) {
        this.uploading$.next(true);
      }
    } else {
      this._announceParent();
    }
  }

  /**
   * Event de la progression du téléversement du composant fils app-piece-jointe-upload
   * Calcul total de la progression de l'ensemble des fichiers en cours de téléversement
   * @param upload
   * @param idx
   */
  progressFile(upload: Upload, idx) {
    if (!this.uploadFiles) {
      this.uploadFiles = new Array(this.piecesJointes.length);
    }
    this.uploadFiles[idx] = upload;
    this._calculateProgress();
    this.onProgress.emit(this.uploadFiles);
  }
  /**
   * Suppression d'un fichier dans la liste à téléverser
   * @param event
   */
  childDestroy(event) {
    const idx = this.piecesJointes.indexOf(event);
    if (idx !== -1) {
      this.piecesJointes.splice(idx, 1);
      this.onUpdatedFiles.emit(this.piecesJointes);
    }
  }
  /**
   * Lancement hors Input() du téléversement des fichiers par l'API
   */
  launchUpload() {
    this.launcher.next();
  }

  ngOnDestroy(): void {
    this.subscriptionSignal.unsubscribe();
    if (this.reset) {
      this.resetSignal.unsubscribe();
    }
  }
  private resetData() {
    this.piecesJointes = [];
    this.piecesUploaded = [];
    this.files = null;
    this.uploadTotal = {state: 'IN_PROGRESS', progress: 0};
    this.uploadFiles = null;
    this.onUpdatedFiles.emit(this.piecesJointes);
  }
}
