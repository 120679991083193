import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PieceJointeUploaderHDComponent} from './piece-jointe-uploader.component';
import {PieceJointeUploadComponent} from './piece-jointe-upload/piece-jointe-upload.component';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import { MatAngularModule } from '../../../common/mat-angular.module';
import {CommonModuleP8} from '@Common/common-p8.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        MatAngularModule,
        CommonModuleP8
    ],
  declarations: [
    PieceJointeUploaderHDComponent,
    PieceJointeUploadComponent
  ],
  exports: [
    PieceJointeUploaderHDComponent
  ]
})
export class PieceJointeUploaderHDModule { }
