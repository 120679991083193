import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommentService, PiecejointeDownloadService, Ticket} from '../../services';
import {typeEvents} from '../../toasty/type-events';
import {IndividuService} from '@Common/core/services';
import {PubSubService} from '../../pub-sub.service';
import {Annotation} from '../../services/ticket/annotation.model';
import {AnnotationService} from '../../services/ticket/annotation.service';
import {finalize} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import {DialogData, DialogValidationComponent} from '@Common/widgets/general/dialog-validation';
import {SubSink} from "@Common/core/utils/subsink";
import {Subject} from "rxjs";
import {ActionTicketChangeStream, TicketChangeStream} from "../../ticket-change-stream.model";

@Component({
  selector: 'app-ux-annotation',
  templateUrl: './ux-annotation.component.html',
  styleUrls: ['./ux-annotation.component.scss']
})
export class UxAnnotationComponent implements OnInit, OnDestroy {
  @Input()
  annotation: Annotation;

  @Input()
  ticket: Ticket;

  @Input()
  ticketChangeStream: Subject<TicketChangeStream>;

  bgColor = 'None';
  modeEdit = false;
  isMine = false;

  formats = [
    'bold',
    'italic',
    'blockquote',
    'code-block',
    'list',
    'indent',
    'link',
  ];

  modules = {

    toolbar: [
      ['bold', 'italic'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

      ['link']                         // link and image, video
    ]
  };

  subSink = new SubSink();

  constructor(private pieceJointeDownload: PiecejointeDownloadService,
              private commentService: CommentService,
              private individuService: IndividuService,
              private pubSubSvc: PubSubService,
              private annotationSvc: AnnotationService,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.subSink.sink = this.individuService.individu$.subscribe(_ind => {
      if (_ind.uid === this.annotation.agent) {
        this.isMine = true;
      }
    });
  }

  emitTicketChangeStream(_ticket: Ticket, action: ActionTicketChangeStream = ActionTicketChangeStream.load) {
    const ticketChangeStream = <TicketChangeStream> {
      ticket: _ticket,
      action: action
    };
    if (this.ticketChangeStream) {
      this.ticketChangeStream.next(ticketChangeStream);
    }
  }

  toggleEditMode() {
    this.modeEdit = !this.modeEdit;
  }

  updateAnnotation() {
    this.subSink.sink = this.annotationSvc
      .update(this.annotation)
      .pipe(finalize(() => this.pubSubSvc.publish('loading', false)))
      .subscribe(ann => {
        this.annotation = ann;
        this.modeEdit = false;
        this.pubSubSvc.publish(typeEvents.updatedAnnotation, 'Annotation bien modifié');
        this.emitTicketChangeStream(this.ticket);
      });
  }

  deleteAnnotation() {
    const data = new DialogData();
    data.title = 'Supprimer l\'annotation';
    data.message = 'Êtes vous sûr de supprimer l\'annotation ?';

    const dialogRef = this.dialog.open(DialogValidationComponent, {
      data: data
    });
    dialogRef.updatePosition({top: '50px'});
    this.subSink.sink = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.annotationSvc
          .delete(this.annotation)
          .pipe(finalize(() => this.pubSubSvc.publish('loading', false)))
          .subscribe(() => {
            this.pubSubSvc.publish(typeEvents.updatedAnnotation, 'Annotation bien supprimée');
            this.emitTicketChangeStream(this.ticket);
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
