import {Injectable} from '@angular/core';
import {environment} from '@Env/environment';
import {PieceJointe} from './piecejointe.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Upload} from './upload.interface';
import {scan} from 'rxjs/operators';
import {calculateState, initialState} from './upload.helper';

@Injectable({ providedIn: 'root' })
export class PiecejointeService {
  private url = `${environment.baseUrl}/helpdesk/piecejointe/`;
  private tempId = 0;

  constructor(private http: HttpClient) {
  }
  private _setFormData(pieceJointe: PieceJointe) {
    const formData: FormData = new FormData();
    formData.append('datafile', pieceJointe.datafile, pieceJointe.datafile.name);
    if (pieceJointe.object_id) {
      formData.append('object_id', pieceJointe.object_id.toString());
    }
    if (pieceJointe.content_type) {
      formData.append('content_type', pieceJointe.content_type.toString());
    }
    if (pieceJointe.user) {
      formData.append('user', pieceJointe.user.toString());
    }
    return formData;
  }
  upload(pieceJointe: PieceJointe): Observable<Upload> {
    const formData: FormData = this._setFormData(pieceJointe);
    return this.http
      .post(this.url, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(scan(calculateState, initialState));
  }
  create(pieceJointe: PieceJointe) {
    const formData: FormData = this._setFormData(pieceJointe);
    return this.http.post<any> (this.url, formData);
  }

  getIdUnique(): number {
    this.tempId++;
    return this.tempId;
  }

}
