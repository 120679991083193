<div fxLayout="{{getLayout()}}" *ngIf="piecesJointes?.length > 0">
  <div *ngFor="let pj of piecesJointes" [ngStyle]="{padding: getPadding()}">
    <button
      mat-raised-button
      [matTooltip]="pj.filename"
      (click)="downloadPieceJointe(pj)">
      <mat-icon>file_download</mat-icon>
      <span *ngIf="truncateName <= 0">{{pj.filename}}</span>
      <span *ngIf="truncateName > 0">{{pj.filename | truncate: truncateName}}</span>
    </button>
    <button mat-icon-button *ngIf="preview && pieceJointeIsImageOrPdf(pj)"
            matTooltip="Apercu image ou PDF"
            (click)="popUpPreviewImage(pj)">
      <mat-icon color="accent">zoom_in
      </mat-icon>
    </button>
  </div>
</div>
