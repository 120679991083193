import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {environment} from '@Env/environment';
import {CommentTicket} from './comment-ticket.model';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CommentService {
  private url = `${environment.baseUrl}/helpdesk/comment/`;
  private commentServiceSource = new ReplaySubject<CommentTicket[]>(1);
  commentService$ = this.commentServiceSource.asObservable();

  constructor(private http: HttpClient) {
  }

  create(comment: CommentTicket) {

    return this.http.post<CommentTicket>(this.url, JSON.stringify(comment));
  }


  list(ticketID?: number) {
    if (ticketID) {
      const search = {
        ticket: `${ticketID}`,
      };
      return this.http.get<CommentTicket[]>(this.url, {params: search});
    }
    return this.http.get<CommentTicket[]>(this.url);
  }

  get(id: number) {
    return this.http.get<CommentTicket>(`${this.url}${id}/`);
  }

  update(comment: CommentTicket){
    return this.http.put<CommentTicket>(`${this.url}${comment.id}/`, comment);

  }

  delete(comment: CommentTicket) {
    return this.http.delete<CommentTicket>(`${this.url}${comment.id}/`);

  }
}
