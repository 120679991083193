import { Injectable } from '@angular/core';
import { ReplaySubject } from "rxjs";
import {NewsFeed} from './news-feed';
import {environment} from '@Env/environment';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';

@Injectable({   providedIn: 'root' })
export class NewsFeedService {
  private url = `${environment.baseUrl}/helpdesk/newsfeed/`;
  private newsFeedSource = new ReplaySubject<NewsFeed[]>(1);
  newsFeed$ = this.newsFeedSource.asObservable();

  constructor(private http: HttpClient) {
  }

  create(newsFeed: NewsFeed) {
    return this.http
      .post<NewsFeed>(this.url, newsFeed)
      .pipe(tap(data => this.list()));
  }
  list() {
      return this.http.get<NewsFeed[]>(this.url);
  }
  get(id: number){
    return this.http.get<NewsFeed>(`${this.url}${id}/`);
  }
  update(newsFeed: NewsFeed) {
    return this.http.put<NewsFeed>(`${this.url}${newsFeed.id}/`, newsFeed);
  }
  delete(newsFeed: NewsFeed) {
    return this.http.delete<NewsFeed>(`${this.url}${newsFeed.id}/`);
  }
}
