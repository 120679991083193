import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Ticket} from '../../common/services';

import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TicketService} from '../../common/services';
import {SubSink} from "@Common/core/utils/subsink";
import {TicketChangeStream} from "../../common/ticket-change-stream.model";

@Component({
  selector: 'app-ticket-view-encapsuler',
  templateUrl: './ticket-view-encapsuler.component.html',
  styleUrls: ['./ticket-view-encapsuler.component.scss']
})
export class TicketViewEncapsulerComponent implements OnInit {
  ticket: Ticket;

  @Input()
  ticketChangeStream: Subject<TicketChangeStream> = new Subject<TicketChangeStream>();

  subsink = new SubSink();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private ticketService: TicketService) {
  }

  ngOnInit() {
    this.subsink.sink = this.route.paramMap.subscribe((params: ParamMap) => {
      const _id = params.get('id');

      if (_id) {
        this.fetchTicket(_id);
      }else {
        this.router.navigate(['/']);
      }
    });
  }

  fetchTicket(id) {
    this.subsink.sink = this.ticketService.get(id)
      .subscribe(_ticket => {
        this.ticket = _ticket;
      }, (error) => {
        if (error.status === 404) {
          this.router.navigate(['/']);
        }
    });
  }

}
