<div fxLayout="row" class="mat-elevation-z2">
  <mat-progress-bar
    *ngIf="uploadFiles && uploadFiles.length > 0"
    [mode]="uploadTotal.state == 'PENDING' ? 'buffer' : 'determinate'"
    [value]="uploadTotal.progress"
  ></mat-progress-bar>
</div>
<div fxLayout="row">
  <input hidden
         type="file"
         #fileInput
         name="fileInput"
         [multiple]="multiple"
         [required]="filesRequired"
         (change)="preparePJUpload($event)">
  <app-piece-jointe-upload
    *ngFor="let p of piecesJointes; trackBy: trackByFn; let idx = index"
    [pieceJointe]="p"
    (onDestroy)="childDestroy($event)"
    [launchSignal]="launcher"
    (onFinished)="collectFiles($event)"
    (onProgress)="progressFile($event, idx)"
    [maxFileSize]="maxFileSize">
  </app-piece-jointe-upload>
  <button *ngIf="! uploadFiles || (uploadTotal && uploadTotal.state === 'DONE')" mat-icon-button
          color="accent"
          matTooltip="Joindre un document ({{getMaxMo()}} par fichier)"
          (click)="fileInput.click()">
    <mat-icon>attach_file</mat-icon>
  </button>
</div>


