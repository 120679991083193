import {Injectable} from '@angular/core';
import {environment} from '@Env/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class PiecejointeDownloadService {

  private url = `${environment.baseUrl}/helpdesk/piecejointes/`;

  constructor(private http: HttpClient) {
  }


  getFile(id: number) {
    return this.http.get<Blob>(this.url, {
      params: {id: id.toString()},
      responseType: 'blob' as 'json'
    });
  }


}





