import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({   providedIn: 'root' })
export class SnackBarService {

  public openstream: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public endstream: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor() {
  }

  send(message: string): void {
    this.openstream.next(message);
  }

}
