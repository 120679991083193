import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TicketsLies} from "../../../services/tickets-lies/tickets-lies.model";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-type-liaison',
  templateUrl: './type-liaison.component.html',
  styleUrls: ['./type-liaison.component.scss']
})
export class TypeLiaisonComponent implements OnInit {

  public linkLabels: Set<string> = new Set();
  public linkType: string;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: {linksLabel: {'id': number, 'source':string, 'target': string}[],currentTicket: number,otherTicket: number},
              public dialogRef: MatDialogRef<TypeLiaisonComponent>) {
  }

  ngOnInit() {
    this.data['linksLabel'].map(
      (item) => {
        this.linkLabels.add(item.source);
        this.linkLabels.add(item.target);
      }
    );
  }

  save() {
    if (!this.linkType) {
      return;
    }
    // A: current ticket
    // B: other ticket
    // A Bloque B -> source:A target:B type_link: 2
    // A Bloqué par B -> source:B target:A type_link: 2
    const tl = <TicketsLies>{id: 0, source: 0, target: 0, type_link: 0};
    // if current ticket is the source ticket
    const findLinkDst = this.data['linksLabel'].find(item => item.source === this.linkType);
    if (findLinkDst)
    {
      tl.source = this.data['currentTicket'];
      tl.target = this.data['otherTicket'];
      tl.type_link = findLinkDst.id;
      this.dialogRef.close(tl);
      return;
    }
    // if current ticket is the target ticket
    const findLinkSrc = this.data['linksLabel'].find(item => item.target === this.linkType);
    if (findLinkSrc)
    {
      tl.source = this.data['otherTicket'];
      tl.target = this.data['currentTicket'];
      tl.type_link = findLinkSrc.id;
      this.dialogRef.close(tl);
      return;
    }
    // raise error ?
    this.dialogRef.close();
  }

  selectLink($event: MatSelectChange) {
    this.linkType = $event.value;
  }

  getLabel(label: string) {
    // label is one of this.linkLabels
    let verb = `${label.toLowerCase()} le`;

    if (label.endsWith('par')) {
      verb = `est ${label.toLowerCase()} le`;
    }
    if (label.endsWith('à')) {
      verb = `est ${label.slice(0, -2).toLowerCase()} au`;
    }

    return `Le ticket courant ${this.data['currentTicket']} ${verb} ticket ${this.data['otherTicket']}`;
  }
}
