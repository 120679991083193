import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Ticket, TicketService} from '../../../services';
import {Action} from '../../../services/action/action.model';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {finalize} from "rxjs/operators";
import {TicketChangeStream} from "../../../ticket-change-stream.model";
import {SubSink} from "@Common/core/utils/subsink";
import {Subject} from "rxjs";

@Component({
  selector: 'app-ux-history-sidebar',
  templateUrl: './ux-history-sidebar.component.html',
  styleUrls: ['./ux-history-sidebar.component.scss']
})
export class UxHistorySidebarComponent implements OnInit, OnDestroy {
  @Input() ticket: Ticket;
  @Input() ticketChangeStream: Subject<TicketChangeStream>;

  @ViewChild(MatSort) sort: MatSort;

  subSink = new SubSink();

  displayedColumns = ['individu', 'description', 'performed'];
  actions: Action[];
  dataSource = new MatTableDataSource();
  loading = false;

  constructor(private ticketService: TicketService) {
  }

  ngOnInit() {
    this.fetchData();

    if (this.ticketChangeStream) {
      this.subSink.sink = this.ticketChangeStream.subscribe((_changeStream: TicketChangeStream) => {
        this.fetchData(_changeStream.ticket);
      });
    }
  }

  fetchData(ticket = this.ticket) {
    this.loading = true;
    this.subSink.sink = this.ticketService.fetchHistorique(ticket)
      .pipe(finalize(() => this.loading = false))
      .subscribe(actions => {
        this.dataSource.data = actions;
        this.actions = actions;
      });

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

}
