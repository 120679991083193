import {Injectable} from '@angular/core';
import {environment} from '@Env/environment';
import {CategorieHelpDesk} from './categorie.model';
import {Individu} from '@Services/index';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CategorieNode} from './categorie-node';
import {CategorieStatus} from './categorie-status';
import {IndividuHelpDesk} from '../individu_helpdesk/individu-helpdesk.model';
import {of} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategorieHelpDeskService {
  root = 0;
  constructor(private http: HttpClient) {
  }
  private url = `${environment.baseUrl}/helpdesk/categorie/`;
  create(categorieHelpDesk: CategorieHelpDesk) {
    return this.http.post<CategorieHelpDesk>(this.url, JSON.stringify(categorieHelpDesk));
  }

  sortCategories(categories: CategorieHelpDesk[]) {
    return categories.sort((cat1: CategorieHelpDesk, cat2: CategorieHelpDesk) => {
      return (cat1.label > cat2.label) ? 1 : ((cat2.label > cat1.label) ? -1 : 0);
    });
  }

  /**
   * Les catégories (liste simple) d'un agent
   * @param indiv
   */
  byAgent(indiv: IndividuHelpDesk) {
    const url = `${this.url}categories_agent/`;
    const param = {};
    param['agent'] = indiv.uid;
    return this.http.get<CategorieHelpDesk[]>(url, {params: param});
  }
  /**
   * Les catégories d'un agent et les tickets associés
   * @param indiv
   */
  byIndiv(indiv: Individu) {
    const param = {};
    param['by_individu'] = indiv.uid;
    return this.http.get<CategorieHelpDesk[]>(this.url, {params: param});
  }

  getUserCategorieWithChildren() {
    return this.http.get<CategorieHelpDesk[]>(`${this.url}get_categories_with_children/`);
  }

  /**
   * ATTENTION : tres lourde, a eviter sauf besoin de telecharger la base utilisée, list_simple est à utiliser en priorité
   */
  list() {
    return this.http.get<CategorieHelpDesk[]>(this.url);
  }

  /**
   * Charge uniquement les catégories, sans les objets "nested" associés
   * @param {Map<string, string>} extras : optionnel, filtre à appliquer
   */
  list_simple(extras: Map<string, string> = null) {
    const params = this._getUrlHttpParams(extras);
    return this.http.get<CategorieHelpDesk[]>(`${this.url}list_simple/`, {params: params});
  }

  /**
   * Tous les agents affectés à au moins une catégorie
   */
  get_agents(categorieId = null) {
    const param = {};
    if (categorieId) {
      param['categorie'] = categorieId;
    }

    return this.http.get<IndividuHelpDesk[]>(`${this.url}agents/`, {params: param});
  }

  get_root() {
    return this.http.get<CategorieHelpDesk>(`${this.url}get_root/`);
  }
  get_root_id() {
    if (this.root !== 0) {
      return of(this.root);
    } else {
      return this.get_root().pipe(
      switchMap(res => {
        this.root = res.id;
        return of(this.root);
      }));
    }
  }

  /**
   * L'arbo / structure à partir du nœud "id"
   * Si "id" vide, retournera toute la structure des nœuds du connecté
   * @param {number} id
   */
  get_tree(id: number = null) {
    const param = {};
    if (id) {
      param['id'] = id;
    }
    return this.http.get<CategorieNode[]>(`${this.url}get_tree/`, {params: param});
  }

  /**
   * Mes catégories dans lesquelles je suis affecté et où il existe des tickets
   */
  getMyCategories() {
    return this.http.get<CategorieStatus[]>(`${this.url}get_my_categories/`);
  }
  setMyCategories(categories_sub: number[]) {
    return this.http.patch<CategorieHelpDesk>(`${this.url}set_my_categories/`, categories_sub);
  }

  /**
   * Mes catégories dans lesquelles je suis affecté
   */
  getMyCategoriesAll() {
    return this.http.get<CategorieHelpDesk[]>(`${this.url}get_my_categories_all/`);
  }

  get(id: number) {
    return this.http.get<CategorieHelpDesk>(`${this.url}${id}/`);
  }

  update(categorieHelpDesk: CategorieHelpDesk) {
    return this.http.put<CategorieHelpDesk>(`${this.url}${categorieHelpDesk.id}/`, categorieHelpDesk);

  }

  delete(categorieHelpDesk: CategorieHelpDesk) {
    return this.http.delete<CategorieHelpDesk>(`${this.url}${categorieHelpDesk.id}/`);

  }

  getChildren(categorie: CategorieHelpDesk) {
    return this.http.get<CategorieHelpDesk[]>(`${this.url}${categorie.id}/get_children/`);
  }

  getAncestors(categorie: CategorieHelpDesk) {
    return this.http.get<CategorieHelpDesk[]>(`${this.url}${categorie.id}/get_ancestors/`);
  }
  /**
   * Création des HttpParams à partir du Map<>
   * @param {Map<string, string>} extraQS
   * @return {HttpParams}
   * @private
   */
  protected _getUrlHttpParams(extraQS: Map<string, string>): HttpParams {
    let urlQS = new HttpParams();

    if (extraQS !== null) {
      extraQS.forEach((v, k) => {
        urlQS = urlQS.set(k, v);
      });
    }

    return urlQS;
  }
}
