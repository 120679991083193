<div class="new-comment-canvas" *ngIf="ticket">
  <quill-editor [(ngModel)]="comment.content"
                [modules]="modules"
                [formats]="formats"
                [placeholder]="getPlaceholder()"
                [ngClass]="getClass()">
  </quill-editor>

  <div fxLayout="row"  fxLayout.xs="column" fxLayoutGap.xs="10px"
       class="comment-toolbar">
    <div fxLayoutAlign="start" fxFlex="auto">
      <mat-tab-group [(selectedIndex)] = "selectedTab"
                     (focusChange)="onFocus($event)">
        <mat-tab label="Réponse publique" *ngIf="canDisplayTabType(displayTypesEnum.public)"></mat-tab>
        <mat-tab label="Note interne" *ngIf="isStaff() && canDisplayTabType(displayTypesEnum.note)"></mat-tab>
        <mat-tab label="Annotation" *ngIf="isStaff() && canDisplayTabType(displayTypesEnum.annotation)"></mat-tab>
      </mat-tab-group>

      <span *ngIf="signature && signature?.settings?.activate && signature?.settings?.text">
        <button mat-icon-button matTooltip="ajouter ma signature" (click)="addSignature($event)">
          <mat-icon >open_in_browser</mat-icon>
        </button>
      </span>
    </div>

    <div fxLayoutAlign="end" class="buttons-group">
      <span class="fill-space"></span>

      <app-piece-jointe-uploader-hd *ngIf="selectedTab < INDEX_ANNOTATIONS"
        [accept]="'.pdf,.txt,.htm,.jpg,.png'"
        [multiple]="true"
        [maxFileSize]="5000000"
        [connector]="uploaderConnector"
        (onFinished)="handleFilesUploaded($event)"
        (onUpdatedFiles)="setHasPjs($event)"
        [reset]="resetFiles">
      </app-piece-jointe-uploader-hd>

      <span class="fill-space"></span>
      <!-- boutons actions mode texte ou icons -->
      <ng-container [ngSwitch]="buttonsIcons">
        <ng-container *ngSwitchCase="false">
          <!-- boutons texte -->
          <button *ngIf="!ticket.affectation && isStaff()"
                  mat-button
                  [disabled]="uploading"
                  color="primary"
                  (click)="assignMe()">Prendre en charge</button>
          <button mat-raised-button
                  style="margin-right: 2px;"
                  color="primary"
                  [disabled] = "!comment.content || uploading"
                  (click)="sendCommentaireOrAnnotation()">ENVOYER</button>
        </ng-container>
        <!-- boutons icones -->
        <ng-container *ngSwitchCase="true">
          <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
          <button *ngIf="!ticket.affectation && isStaff()"
                  style="margin-right: 2px;"
                  mat-raised-button
                  mat-icon-button
                  [disabled]="uploading || loading"
                  color="primary"
                  matTooltip="prendre en charge"
                  (click)="assignMe()"><mat-icon>add_circle</mat-icon></button>
          <button *ngIf="ticketIsAssignmentedToMe(ticket) && isStaff()"
                  style="margin-right: 2px;"
                  [disabled]="loading"
                  mat-raised-button
                  mat-icon-button
                  color="primary"
                  matTooltip="ne plus prendre en charge"
                  (click)="unassignMe()"><mat-icon>do_not_disturb_off</mat-icon></button>
          <button style="margin-right: 2px;"
                  mat-raised-button
                  color="primary"
                  mat-icon-button
                  [disabled] = "!comment.content || uploading"
                  matTooltip="envoyer"
                  (click)="sendCommentaireOrAnnotation()"><mat-icon>send</mat-icon></button>
        &nbsp;</ng-container>
      </ng-container>
        <!-- changement de statut -->
      <button mat-mini-fab
              class="mat-elevation-z4"
              *ngIf="isStaff()"
              color="primary"
              [matMenuTriggerFor]="menu"><mat-icon>expand_less</mat-icon>
      </button>

      <mat-menu #menu="matMenu" >
        <button mat-menu-item
                *ngFor="let s of statusesToDisplay"
                [disabled]="uploading"
                (click)="sendCommentaireOrStatut(s);">
          <span [style.color]="s.color">{{s.label}}</span>
        </button>
      </mat-menu>
  </div>

  </div>
</div>
