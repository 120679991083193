import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IndividuHelpDesk} from '../../services/individu_helpdesk/individu-helpdesk.model';
import {SubSink} from '@Common/core/utils/subsink';
import {LdapUser} from '@Services/ldap/ldap/ldap-user.model';
import {IndividuService} from '@Common/core/services';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-applicant-ticket',
  templateUrl: './applicant-ticket.component.html',
  styleUrls: ['./applicant-ticket.component.scss']
})
export class ApplicantTicketComponent implements OnInit {
  @Output() applicantUpdated: EventEmitter<IndividuHelpDesk> = new EventEmitter<IndividuHelpDesk>();
  loading = false;
  individuApplicant: IndividuHelpDesk;
  subSink = new SubSink();

  constructor(private individuBaseService: IndividuService) {
  }

  ngOnInit(): void {
  }

  addSelectIndividu(ind: IndividuHelpDesk) {
    this.individuApplicant = ind;
    this.applicantUpdated.emit(this.individuApplicant);
  }

  onSelectLdapIndiv(ldapUser: LdapUser) {
    this.loading = true;
    const uid = ldapUser.uid[0];
    const urlApiIndividu = '/helpdesk/individu_ldap_safe/';
    this.subSink.sink = this.individuBaseService
      .getOrCreate(uid, urlApiIndividu)
      .pipe(finalize(() => this.loading = false))
      .subscribe(_individu => this.addSelectIndividu(_individu));
  }

  deleteApplicant() {
    this.individuApplicant = null;
    this.applicantUpdated.emit(this.individuApplicant);
  }
}
