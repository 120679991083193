import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommentService, CommentTicket} from '../../services';
import {PieceJointe} from '../../services';
import * as FileSaver from 'file-saver';
import {PiecejointeDownloadService} from '../../services';
import {IndividuService} from '@Common/core/services';
import {PubSubService} from '../../pub-sub.service';
import {typeEvents} from '../../toasty/type-events';
import {SubSink} from '@Common/core/utils/subsink';

@Component({
  selector: 'app-ux-comment',
  templateUrl: './ux-comment.component.html',
  styleUrls: ['./ux-comment.component.scss']
})
export class UxCommentComponent implements OnInit, OnDestroy {
  @Input() comment: CommentTicket;
  @Input() layoutFiles: 'vertical' | 'horizontal' = 'horizontal';
  @Input() previewFiles = false;
  bgColor = 'None';
  modeEdit = false;
  isMine = false;

  formats = [
    'bold',
    'italic',
    'blockquote',
    'code-block',
    'list',
    'indent',
    'link',
  ];

  modules = {

    toolbar: [
      ['bold', 'italic'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

      ['link']                         // link and image, video
    ]
  };

  subSink = new SubSink();

  constructor(private pieceJointeDownload: PiecejointeDownloadService,
              private commentService: CommentService,
              private individuService: IndividuService,
              private pubSubSvc: PubSubService) {
  }

  ngOnInit() {
    if (this.comment.publique) {
      this.bgColor = 'None';
    } else {
      this.bgColor = '#FFF6D9';
    }
    this.subSink.sink = this.individuService.individu$.subscribe(_ind => {
      if (_ind.uid === this.comment.individu.uid) {
        this.isMine = true;
      }
    });
  }

  getBgColor() {

  }
  downloadPieceJointe(file: PieceJointe) {
    this.subSink.sink = this.pieceJointeDownload.getFile(file.id).subscribe(data => {
      FileSaver.saveAs(data, file.filename);
    });
  }

  toggleEditMode() {
    this.modeEdit = !this.modeEdit;
  }

  updateComment() {
    this.subSink.sink = this.commentService
      .update(this.comment)
      .subscribe(com => {
        this.comment = com;
        this.modeEdit = false;
        this.pubSubSvc.publish(typeEvents.updatedComment, 'Commentaire bien modifié');
      });

  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
