import {Injectable} from "@angular/core";
import {DaoGeneric} from "@Services/base/daoService";
import {environment} from "@Env/environment";
import {HttpClient} from "@angular/common/http";
import {Widget} from "./widget.model";
import {Seuil} from "../index";

@Injectable({ providedIn: 'root' })
export class WidgetsService extends DaoGeneric<Widget> {
  private url = `${environment.baseUrl}/helpdesk/widgets/`;

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getRootUrl(): string {
    return this.url;
  }

  /**
   * Obtient la valeur calculé du widget (count de la tuile, data du chart), calcul dynamique avec son filtre
   * @param id
   */
  getWidgetData(id: number) {
    const url = `${this.url}${id}/widget_load/`;

    return this.httpClient.get<any>(url);
  }

  /**
   * Tri des seuils de la tuile et calcul le seuil à prendre selon la valeur trouvée pour la tuile
   * @param {Widget} widget : le widget original
   * @return {Seuil} le calcul effectué : color, icon
   */
  calculateWidgetTile(widget: Widget) {
    let temp: Seuil = undefined;

    const sort = widget.settings.seuils.sort((a: Seuil, b: Seuil) => {
      return a.seuil - b.seuil;
    });

    for (let i = 0; i < sort.length; i++) {
      const seuil: Seuil = sort[i];

      if (widget.value.seuil >= seuil.seuil) {
        temp = seuil;
      } else {
        widget.value.color = seuil.color;
        widget.value.icon = seuil.icon;
        break;
      }
    }

    if (temp !== undefined) {
      widget.value.color = temp.color;
      widget.value.icon = temp.icon;
    }

    return widget;
  }

  /**
   * Obtient les conditions du filtre utilisé et du widget
   * @param widgetId
   */
  getStringifyConditions(widgetId: number) {
    const url = `${this.url}/${widgetId}/get_widget_conditions_stringify/`;
    return this.httpClient.get<any>(url);
  }

  /**
   * Duplication de filtre
   * @param {number} id : id du widget à dupliquer
   * @param {string} label : nouveau libellé du filtre
   */
  duplicateWidget(id: number, label = null) {
    const url = `${this.url}${id}/duplicate_widget/`;
    const body = {
      label: label
    };
    return this.httpClient.post<Widget>(url, body);
  }
}
