import {Injectable} from "@angular/core";
import {DaoGeneric} from "@Services/base/daoService";
import {environment} from "@Env/environment";
import {HttpClient} from "@angular/common/http";
import {TicketsLies} from "./tickets-lies.model";
import {catchError, map, shareReplay} from "rxjs/operators";
import {Observable, of} from "rxjs";

@Injectable({providedIn: 'root'})
export class TicketsLiesService extends DaoGeneric<TicketsLies> {
  private url = `${environment.baseUrl}/helpdesk/ticketslies/`;
  private _cacheLiaisonsTypes$: Observable<any>;

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getRootUrl(): string {
    return this.url;
  }

  getLiaisonsTypes() {
    if (!this._cacheLiaisonsTypes$) {
      const url = `${this.url}laisons_types/`;
      this._cacheLiaisonsTypes$ = this.httpClient
        .get(url)
        .pipe(
          shareReplay({ bufferSize: 1, refCount: true }),
          catchError((error: any) => this._throwObservable(error))
        );
    }
    return this._cacheLiaisonsTypes$;
  }
}
