import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormType} from '../../../common/form-fields/form-type.enum';
import {Ticket, TicketService} from "../../../common/services";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TicketField} from "../../../common/form-fields/fields.interface";

@Component({
  selector: 'app-ux-new-ticket-agent-modale',
  templateUrl: './ux-new-ticket-agent-modale.component.html',
  styleUrls: ['./ux-new-ticket-agent-modale.component.scss']
})
export class UxNewTicketAgentModaleComponent implements OnInit {
  formType = FormType;
  extendedFields: TicketField[] = this.data || [];

  constructor(
    private ticketSvc: TicketService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TicketField[],
    public dialogRef: MatDialogRef<UxNewTicketAgentModaleComponent>) {
  }

  ngOnInit() {
  }

  redirect(ticket: Ticket) {
    this.dialogRef.close(ticket);
    this.ticketSvc.ticketCreated(ticket);
  }

  cancel() {
    this.dialogRef.close();
  }
}
