import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Ticket} from '../../services';
import {UntypedFormControl, Validators} from '@angular/forms';
import {SubSink} from '@Common/core/utils/subsink';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-title-ticket',
  templateUrl: './title-ticket.component.html',
  styleUrls: ['./title-ticket.component.scss']
})
export class TitleTicketComponent implements OnInit, OnDestroy {
  @Input() value = '';
  @Input() placeholder = 'Objet de la demande (4 caractères minimum)';
  @Input() min = 4;
  @Input() max = 199;

  @Output() titleUpdated = new EventEmitter<string>();

  submitted = false;

  control = new UntypedFormControl({value: this.value, disabled: this.submitted}, Validators.required );

  title = '';

  _ticket = <Ticket> {
    title: '',
    status: null,
    description: '',
    priority: null,
    submitter_email: '',
  };

  subSink = new SubSink();

  constructor() { }

  isControlValid() {
    return this.control.valid;
  }

  ngOnInit() {
    this.subSink.sink = this.control.valueChanges.
    pipe(debounceTime(500), distinctUntilChanged()).
    subscribe((_value) => this.titleUpdated.emit(_value));
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

}
