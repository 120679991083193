import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SubSink} from '@Common/core/utils/subsink';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, isEmpty, map} from 'rxjs/operators';
import {Individu, IndividuService} from '@Common/core/services';

@Component({
  selector: 'app-phone-ticket',
  templateUrl: './phone-ticket.component.html',
  styleUrls: ['./phone-ticket.component.scss']
})
export class PhoneTicketComponent implements OnInit, OnDestroy {
  @Output() phoneUpdated = new EventEmitter<string>();
  @Input() phoneRequired;
  control = new UntypedFormControl();

  subSink = new SubSink();

  constructor(private IndividuSvc: IndividuService) { }

  ngOnInit() {
    this.subSink.sink = this.control.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map(number => {
          if (number) {
            return number.replace(/[^+\d .-]/g, '');
          }
          return '';
        }))
      .subscribe((_value: string) => {
        this.control.setValue(_value);
        this.phoneUpdated.emit(_value);
      });
    this.subSink.sink = this.IndividuSvc.individu$
      .subscribe((ind: Individu) => {
        if (ind) {
          this.control.setValue(ind.phone_pro);
        }
      });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
