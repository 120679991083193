import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route, CanLoad} from '@angular/router';
import { Observable } from 'rxjs';
import {IndividuSafeService} from '../common/services/individu_helpdesk/individu.service';

@Injectable({   providedIn: 'root' })
export class StaffGuard implements CanActivate, CanLoad {
  constructor(private individuService: IndividuSafeService) {
  }

  canLoad(route: Route): Observable<boolean> {
    return this.isStaffObservable();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.isStaffObservable();
  }


  private isStaffObservable(): Observable<boolean> {
    return Observable.create(observer => {
      this.individuService.individu$.subscribe(_individu => {
          observer.next(this.isStaff(_individu));
          observer.complete();
        }
      );
    });
  }

  private isStaff(individu){
    if(this.individuService.hasRole(individu, 'dispatcher') ||
        this.individuService.hasRole(individu, 'solver')){
        return true;
    }else{
      return false;
    }
  }
}
