<div fxLayoutAlign="end center" *ngIf="ticketsIds && ticketsIds.length > 0">
  <button mat-raised-button
          [disabled]="! hasPrevious"
          matTooltip="{{getTooltip('first')}}"
          (click)="onFirst($event)">
    <mat-icon color="primary">first_page</mat-icon>
  </button>
  <button mat-raised-button
          [disabled]="! hasPrevious"
          matTooltip="{{getTooltip('prev')}}"
          (click)="onPrevious($event)">
    <mat-icon color="primary">navigate_before</mat-icon>
  </button>
  <button mat-raised-button
          [disabled]="! hasNext"
          matTooltip="{{getTooltip('next')}}"
          (click)="onNext($event)">
    <mat-icon color="primary">navigate_next</mat-icon>
  </button>
  <button mat-raised-button
          [disabled]="! hasNext"
          matTooltip="{{getTooltip('last')}}"
          (click)="onLast($event)">
    <mat-icon color="primary">last_page</mat-icon>
  </button>
</div>
<!--<mat-divider></mat-divider>-->
