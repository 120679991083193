import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Ticket} from "../../services";

@Component({
  selector: 'app-ux-navigation-previous-next-ticket',
  templateUrl: './ux-navigation-previous-next-ticket.component.html',
  styleUrls: ['./ux-navigation-previous-next-ticket.component.scss']
})
export class UxNavigationPreviousNextTicketComponent implements OnInit, OnChanges {
  @Input() ticket: Ticket;
  @Input() ticketsIds: number[] = [];

  @Output() previous: EventEmitter<number> = new EventEmitter<number>();
  @Output() next: EventEmitter<number> = new EventEmitter<number>();

  hasPrevious = false;
  hasNext = false;

  idFirst: number;
  idPrev: number;
  idNext: number;
  idLast: number;
  idCurrent: number;

  constructor() { }

  ngOnInit() {}

  getTooltip(type = 'prev') {
    switch(type) {
      case 'prev':
        if (this.hasPrevious) {
          return 'ticket précédent';
        } else {
          return 'début de liste';
        }
      case 'next':
        if (this.hasNext) {
          return 'ticket suivant';
        } else {
          return 'fin de liste';
        }
      case 'first':
        if (this.hasPrevious) {
          return '1er ticket';
        } else {
          return 'début de liste';
        }
      case 'last':
        if (this.hasNext) {
          return 'dernier ticket';
        } else {
          return 'fin de liste';
        }
    }
  }

  onFirst(event) {
    this.previous.emit(this.idFirst);
  }

  onPrevious(event) {
    this.previous.emit(this.idPrev);
  }

  onNext(event) {
    this.next.emit(this.idNext);
  }

  onLast(event) {
    this.next.emit(this.idLast);
  }

  /**
   * Calcul s'il existe un ticket précédent ou suivant
   * @private
   */
  _calculatePrevNext() {
    this.hasPrevious = false;
    this.hasNext = false;

    if (this.ticket && this.ticketsIds && this.ticketsIds && this.ticketsIds.length > 0) {
      const idx = this.ticketsIds.findIndex((id) => id === this.ticket.id);
      if (idx > -1) {
        this.idCurrent = this.ticketsIds[idx];
        if (idx > 0) {
          this.hasPrevious = true;
          this.idPrev = this.ticketsIds[idx - 1];
        }
        if (idx < this.ticketsIds.length - 1) {
          this.hasNext = true;
          this.idNext = this.ticketsIds[idx + 1];
        }
        this.idFirst = this.ticketsIds[0];
        this.idLast = this.ticketsIds[this.ticketsIds.length - 1];
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.ticket && changes.ticket.currentValue && changes.ticketsIds && changes.ticketsIds.currentValue) {
    if (changes.ticket && changes.ticket.currentValue) {
      this._calculatePrevNext();
    }
  }
}
