<mat-card>

  <mat-card-content [innerHtml]="sanitazed" *ngIf="!editMode"  [ngClass]="{'limit-content-height': isReadMore}">
  </mat-card-content>


  <mat-card-actions align="end">
    <button
      mat-button
      color="accent"
      (click)="showAllContent()">
      {{ isReadMore ? 'Lire la suite ': 'Voir moins' }}
    </button>
    <div style="margin: 8px 8px 8px 0;" *ngIf="isStaff && newsFeed.archived">
      <mat-slide-toggle [matTooltip]="getTooltipText()"
                        [checked]="newsFeed.archived"
                        (change) = "archiveOrUnarchiveCarte()"
                        [color]="'primary'"
                        labelPosition="before"
      >Désarchiver ?
      </mat-slide-toggle>
    </div>

    <button *ngIf="isStaff()"
            mat-icon-button
            color="accent"
            (click)="changeEditMode()">

      <mat-icon class="md-24"
               aria-label="Editer"

      >edit</mat-icon>
    </button>

  </mat-card-actions>

</mat-card>
