<div *ngIf="ticket" class="history-canvas">
  <div class="p8-cm_data-table__progress-bar-container">
    <mat-progress-bar *ngIf="loading" class="p8-cm_data-table__progress-bar" mode="indeterminate">
    </mat-progress-bar>
  </div>
  <p class="mat-subheading-1">Historique</p>
  <div *ngIf="actions">
    <p *ngFor="let action of actions">
      <span class="mat-body-1 bold-text">{{ action.individu }}</span>
      <br/>
      <span class="mat-body-1">
        {{ action.description }}
      </span>
      <!--<span class="mat-body-1" *ngIf="action.new_value || action.old_value">-->
        <!--<span *ngIf="action.old_value">{{ action.old_value }}</span>-->
        <!--<span *ngIf="action.new_value">{{ action.new_value }}</span>-->
      <!--</span>-->
      <br/>
      <span class="mat-card-subtitle">{{ action.performed | date: 'short' }}</span>
    </p>
  </div>
</div>

