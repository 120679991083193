<mat-toolbar class="mat-elevation-z6" color="primary" [ngStyle]="{'background': customNavBackground, 'color': customNavFontColor}" [ngClass.lt-md]="'p8-cm_navbar__mobile'">
  <ng-container *ngIf="displaySidenav">
    <button mat-icon-button (click)="clickOpen()">
      <mat-icon>{{iconMenu()}}</mat-icon>
    </button>
  </ng-container>
  <a href="{{homeApplication}}" fxHide.lt-md><img class="p8-cm_navbar__logo_p8" alt="P8" [src]="defaultLogo"></a>

  <div fxFlexAlign="center" fxFlex class="p8-cm_navbar__title" [ngClass.lt-md]="'p8-cm_navbar__mobile'">
    <span>{{ titre }}</span>
    <div class="p8-cm_navbar__version">{{ version }}</div>
  </div>

  <add-banner [componentsItems]="components | async" remove-host></add-banner>

  <!-- Test pour garder la compatibilité en attendant que chaque appli implémente sa liste de composants à afficher -->
  <div *ngIf="(components | async).length === 0">
    <ng-container *ngFor="let m of menu">
      <button *ngIf="m.nav" mat-button [routerLink]="m.url" [routerLinkActive]="'active'"
              [routerLinkActiveOptions]="{exact:true}">

        <app-menuicon *ngIf="m.icon!==''" [icon]="m.icon"></app-menuicon>

        {{ m.titre }}
      </button>
    </ng-container>
  </div>

  <button mat-button *ngIf="displayLogon && user"
          [matTooltip]="displayName"
          [matMenuTriggerFor]="profileMenu" fxHide.lt-md>

    <mat-icon *ngIf="!avatar" class="p8-cm_navbar__connexion-left-icon">account_circle</mat-icon>
    <span *ngIf="!avatar">{{ displayName }}</span>

    <div *ngIf="avatar" fxLayout="row" fxLayoutAlign="center center">
      <app-avatar [size]="35" [name]="displayName"></app-avatar>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
  </button>

  <button mat-icon-button *ngIf="displayLogon && user" [matMenuTriggerFor]="profileMenu" fxHide.gt-sm>
      <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #profileMenu="matMenu" xPosition="before">
    <button mat-menu-item (click)="logout()">
      <mat-icon>power_settings_new</mat-icon>
      <span>Déconnexion</span>
    </button>
  </mat-menu>

  <button *ngIf="displayLogon && ! user" mat-button (click)="logout()">
    CONNEXION
  </button>

</mat-toolbar>
