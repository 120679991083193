import {Injectable} from '@angular/core';
import {Individu, IndividuService} from '../../../common/core/services/campagne_emplois';
import {RoleHelpdeskEnum} from "../services";

@Injectable({ providedIn: 'root' })
export class AccountLevelService {
  individu: Individu;

  constructor(private individuService: IndividuService) {
    this.individuService.individu$.subscribe(ind =>
      this.individu = ind
    );
  }

  isAdmin(): boolean {
    return this.individuService.hasRole(this.individu, RoleHelpdeskEnum.admin);
  }

  isSuperviseur(): boolean {
    return this.individuService.hasRole(this.individu, RoleHelpdeskEnum.superviseur);
  }

  isDispatcher(): boolean {
    if(this.isAdmin()) {
      return true;
    }
    return this.individuService.hasRole(this.individu, RoleHelpdeskEnum.dispatcher);
  }

  isSolver(): boolean {
    return this.individuService.hasRole(this.individu, RoleHelpdeskEnum.solver);
  }

  getIndividu(): Individu {
    return this.individu;
  }

  public isStaff() {
    if (this.isAdmin()) {
      return true;
    }
    if (this.isSuperviseur()) {
      return true;
    }
    if (this.isDispatcher()) {
      return true;
    }

    return this.isSolver();
  }

}
