import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from '@Services/auth';
import {PersonnelComponent} from './personnel.component';
import {NewTicketComponent} from './new-ticket/new-ticket.component';
import {OverviewTicketsComponent} from './overview-tickets/overview-tickets.component';
import {TicketViewEncapsulerComponent} from './ticket-view-encapsuler/ticket-view-encapsuler.component';

const routes: Routes = [
  {
    path: '',
    component: PersonnelComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {path: 'new-ticket', component: NewTicketComponent},
      {path: 'overview-tickets', component: OverviewTicketsComponent},
      {path: 'ticket', component: TicketViewEncapsulerComponent}

    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PersonnelRoutingModule { }
