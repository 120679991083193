import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Ticket} from '../../services';
import {TicketToBookmarkers} from '../../services/ticket/ticketToBookmarkers.model';
import {SubSink} from '@Common/core/utils/subsink';
import {TicketToBookmarkersService} from '../../services/ticket/ticketToBookmarkers.service';
import {FlagColorEnum} from '../../services/flag/flagColor.enum';
import {AuthService} from '@Services/auth';
import {SnackBarToastyService} from '@Common/widgets/general/snack-bar/snack-bar-toasty.service';
import {SnackBarDataModel, typeToast} from '@Common/widgets/general/snack-bar';

@Component({
  selector: 'app-ux-ticket-flags',
  templateUrl: './ux-ticket-flags.component.html',
  styleUrls: ['./ux-ticket-flags.component.scss']
})
export class UxTicketFlagsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() ticket: Ticket;

  @Input() msgUpdate: string;

  @Input() msgCreate: string;

  @Input() msgDelete: string;

  ticketToBookmarkers: TicketToBookmarkers = {
    id: null,
    ticket: null,
    bookmarker: null,
    flag: null,
  };

  flagsEnum = FlagColorEnum;
  selectedFlag: FlagColorEnum = null;

  subSink = new SubSink();

  constructor(private authSvc: AuthService,
              public ticketToBookmarkersService: TicketToBookmarkersService,
              private snackSvc: SnackBarToastyService) { }

  ngOnInit() {
    this.initFlag();
  }

  /**
   * Pour le changement de ticket navigation panier, évite les doubles appels à l'API
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ticket && changes.ticket.currentValue && changes.ticket.previousValue !== changes.ticket.currentValue) {
      if (changes.ticket.previousValue !== undefined && changes.ticket.previousValue.id !== changes.ticket.currentValue.id) {
        this.initFlag();
      }
    }
  }

  toolTip(color) {
    if (this.selectedFlag !== null && this.selectedFlag === color) {
      return `force ${this.ticketToBookmarkersService.freshForceColorName(color)} ! cliquer pour enlever le favori`;
    }

    return `force ${this.ticketToBookmarkersService.freshForceColorName(color)} ! cliquer pour mettre ce ticket en favori avec cette couleur`;
  }

  /**
   * Initialisation du flag favori
   */
  initFlag() {
    this.selectedFlag = null;
    const username = this.authSvc.getUser().username;
    const filters: Map<string, string> = new Map<string, string>();
    filters.set('bookmarker', username).set('ticket', this.ticket.id.toString());
    this.subSink.sink = this.ticketToBookmarkersService.listAllItems(filters).subscribe( (_ticketsToBookmarkers) => {
        const ticketToBookmarkers = _ticketsToBookmarkers.list
          .find((_ticket) => _ticket.bookmarker === username && _ticket.ticket === this.ticket.id);
        this.ticketToBookmarkers = ticketToBookmarkers;
        if (ticketToBookmarkers != null) {
          this.selectedFlag = this.ticketToBookmarkers.flag;
        }
      }
    );
  }
  /**
   * Sélection du flag
   * - suppression
   * - mise à jour / changement de couleur flag
   * - création
   * @param color
   */
  flagColorSelected(color: FlagColorEnum) {
    if (this.ticketToBookmarkers != null && this.ticketToBookmarkers.flag === color) {
      this._deleteFlag();
    } else {
      if (this.ticketToBookmarkers != null && this.ticketToBookmarkers.id != null &&
        this.ticketToBookmarkers.flag !== color) {
        this.ticketToBookmarkers.flag = color;
        this._createOrUpdateFlag();
      } else if (!this.ticketToBookmarkers || this.ticketToBookmarkers.id === null &&
        this.ticketToBookmarkers.flag === null) {
        const username = this.authSvc.getUser().username;
        this.ticketToBookmarkers = {
          ticket: this.ticket.id,
          bookmarker: username,
          flag: color,
        };
        this._createOrUpdateFlag();
      }
    }
  }
  private _forceFreshColor() {

  }
  private _deleteFlag() {
    this.subSink.sink = this.ticketToBookmarkersService.delete(this.ticketToBookmarkers).subscribe(
      (_ticketToBookmarkers) => {
        this.selectedFlag = null;
        this.toasty(this.msgDelete);
      }
    );
    this.ticketToBookmarkers = {
      id: null,
      ticket: null,
      bookmarker: null,
      flag: null,
    };
  }
  private _createOrUpdateFlag() {
    this.subSink.sink = this.ticketToBookmarkersService
      .updateOrcreate(this.ticketToBookmarkers)
      .subscribe((_ticketToBookmarkers) => {
        this.selectedFlag = _ticketToBookmarkers.flag;
        this.toasty(this.ticketToBookmarkers.id ? this.msgUpdate : this.msgCreate);
        this.ticketToBookmarkers = {
          id: _ticketToBookmarkers.id,
          ticket: _ticketToBookmarkers.ticket,
          bookmarker: _ticketToBookmarkers.bookmarker,
          flag: _ticketToBookmarkers.flag,
        };
    });
  }
  public toasty(msg: string, title: string = '', type: typeToast = typeToast.success) {
    const config: SnackBarDataModel = {
      title: title,
      message: msg,
      type: type,
      showClose: true
    };

    this.snackSvc.openSnackToasty(config);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
