import {Injectable} from "@angular/core";
import {SnackBarToastyComponent} from "@Common/widgets/general/snack-bar/snack-bar-toasty.component";
import {SnackBarDataModel} from "@Common/widgets/general/snack-bar/snack-bar-data.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import {BehaviorSubject} from "rxjs";
import {typeToast} from "@Common/widgets/general/snack-bar/snack-bar-toasty-type.enum";

@Injectable({ providedIn: 'root' })
export class SnackBarToastyService {
  /**
   * Hook après que le toast est fermé
   */
  public toasted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  /**
   * Hook après que le toast est ouvert
   */
  public toasting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(private snackBar: MatSnackBar) {}

  public openSnackToasty(snackConfig: SnackBarDataModel) {
    const snack = this.snackBar.openFromComponent(SnackBarToastyComponent,
      {
        data: snackConfig,
        duration: snackConfig.duration ? snackConfig.duration : 3000,
        verticalPosition: snackConfig.verticalPosition ? snackConfig.verticalPosition : "top",
        horizontalPosition: snackConfig.horizontalPosition ? snackConfig.horizontalPosition : "right"});
      snack.afterDismissed().subscribe(() => this.toasted.next(true));
      snack.afterOpened().subscribe(() => this.toasting.next(true));
  }

  /**
   * Toasty
   * @param title
   * @param msg
   * @param type
   * @param {number} duration : en millisesonde (3000 = 3s par défaut)
   */
  public toasty(title: string, msg: string, type: typeToast, duration: number = 3000) {
    const config: SnackBarDataModel = {
      title: title,
      message: msg,
      type: type,
      showClose: true,
      duration: duration
    };

    setTimeout( () => this.openSnackToasty(config));
  }
}
