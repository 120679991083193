import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { LdapService } from '@Services/ldap/ldap/ldap.service';
import { LdapUser } from '@Services/ldap/ldap/ldap-user.model';
import { Observable, of, Subject} from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { environment } from '@Env/environment';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  catchError, tap, finalize
} from 'rxjs/operators';

import { FilesUtils } from '@Services/common';

import {isArray} from 'rxjs/internal-compatibility';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {User} from '@Services/auth';

@Component({
  selector: 'app-search-ldap',
  templateUrl: './search-ldap.component.html',
  styleUrls: ['./search-ldap.component.css']
})
export class SearchLdapComponent implements OnInit {
  /**
   * Placeholder du champ de recherche
   */
  @Input() placeHolder = 'Rechercher une personne...';

  /**
   * Activer la recherche par typologie de personnes (Tous, Personnel, Etudiants, Enseignants, Recherche)
   * @type {boolean}
   */
  @Input() scope = false;

  /**
   * Minimum nb. caractères avant déclenchement recherche
   */
  @Input() minLettersSearch = 3;
  /**
   * Debounce en ms entre 2 caractères
   */
  @Input() debounceTimeSearch = 300;
  /**
   * Pèrimétre(s) de recherche positionnés par défaut, pour se positionner dans la liste, valable si scope = true
   * Valeur possible : employee | faculty | student | research, ou combinés : employee,student
   */
  @Input() set defaultScopes(value: string) {
    if (value) {
      this.scopeValues = value.trim().split(',');
      this.onSelected(this.scopeValues);
    }
  }

  /**
   * Liste des options possibles à afficher
   * Valable si scope = true
   */
  @Input() scopesToDisplay = ['employee', 'faculty', 'researcher', 'student'];

  @Input() titleLabel = 'Recherche sur l\'annuaire P8';

  /**
   * Titre la card à afficher ?
   */
  @Input() title = true;

  /**
   * La valeur / attribut LDAP à afficher dans les propositions
   */
  @Input() ldapDisplayProperty = 'displayName';

  /**
   * Enlever les accents pour la recherche ?
   * Par défaut false
   * @type {boolean} true | false
   */
  @Input() removeDiatrics = false;

  @Input() submitted = false;

  /**
   * Le tooltip sur le champ de recherche
   */
  @Input() toolTip = 'Taper les 3 premiers caractères...';

  /**
   * La recherche doit-elle splitter le mot en plusieurs mots ?
   */
  @Input() splitWords = false;

  /**
   * Progress bar lors de la recherche ?
   */
  @Input() progressBar = false;
  /**
   * Mode d'affichage
   */
  @Input() mode: 'full' | 'light' = 'full';
  /**
   * Le style du champ de recherche
   */
  @Input() appearance: MatFormFieldAppearance = 'fill';
  /**
   * Attributs supplémentaires de recherche
   * liste d'attributs séparés par une ,
   */
  @Input() attrsAddFilters = '';
  @Output() ldapUserSelected = new EventEmitter<LdapUser>();

  scopeValues = [''];

  ldapUsersSearch: Observable<LdapUser[]>;
  dataSource = new Subject<LdapUser[]>();
  success = false;
  loading = false;

  /**
   * Liste des scopes à afficher dans la sélection d'affinage
   */
  scopes: any[] = [
    { id: 'employee', libelle: 'Personnel' },
    { id: 'faculty', libelle: 'Enseignants' },
    { id: 'researcher', libelle: 'Recherche'},
    { id: 'student', libelle: 'Etudiants' }];

  search_scope = '';

  searchControl = new UntypedFormControl();

  control = new UntypedFormControl([]);

  constructor(private ldapService: LdapService, private fileUtils: FilesUtils) { }

  ngOnInit() {
    this.ldapUsersSearch = this.searchControl.valueChanges.pipe(
      debounceTime(this.debounceTimeSearch),
      distinctUntilChanged(),
      filter((searchTerm) => searchTerm.length >= this.minLettersSearch),
      tap(() => this.loading = true),
      map(data => {
        if (this.removeDiatrics) {
          return this.fileUtils.removeDiatrics(data);
        }
        return data;
      }),
      finalize(() => this.loading = false),
      switchMap(data => this.getLdapUserSearch(data, this.splitWords, this.attrsAddFilters))).
    pipe(catchError( error => of<LdapUser[]>([])));
  }
  get state() {
    return '';
  }
  reduceScopes(perims: string[]): string {
    return perims.join(',');
  }

  onSelected(choices) {
    this.search_scope = this.reduceScopes(choices);
  }

  getLdapUserSearch(token: string, splitWords = false, attrsAddFilters = ''): Observable<LdapUser[]> {
    return this.ldapService.list(token.trim(), this.search_scope, splitWords, attrsAddFilters);
  }

  /**
   * Emission de la sélection de l'individu
   * @param e
   */
  public typeaheadOnSelect(e: LdapUser): void {
    this.ldapUserSelected.next(e);
    this.ldapService.sendLdapUserSelected(e);
    this.loading = false;
  }

  displayFn(ldapUser: LdapUser) {
    /* if (ldapUser) {
      return ldapUser[this.ldapDisplayProperty];
    }
    return ''; */
  }

  /**
   * Les options d'affinage à afficher, prend en compte l'option scopesToDisplay
   */
  getScopesToDisplay() {
    const scopes = [];

    this.scopesToDisplay.forEach((_scope) => {
      const find = this.scopes.find((_option) => _option.id === _scope);
      if (find) {
        scopes.push(find);
      }
    });

    return scopes;
  }

  /**
   * Remplace les éventuels \ par rien
   * @param value : valeur d'une propertie ldap (cn, displayName, etc), une entrée est soit un string[], soit un string
   * @private
   */
  private _getLdapValue(value) {
    let display = '';

    if (isArray(value) && value.length > 0) {
      if (value[0]) {
        display = value[0].replace('\\', '');
      }
    } else {
      if (value) {
        display = value.replace('\\', '');
      }
    }

    return display;
  }

  /**
   * Affichage du résultat / proposition selon les attributs à prendre (ldapDisplayProperty)
   * @param ldapUser
   */
  displayOption(ldapUser: LdapUser): string {
    let display = '';

    if (ldapUser) {
      const properties = this.ldapDisplayProperty.trim().split(',');

      const fctLdapValue = this._getLdapValue; // pour le reduce en pure fct JS, évite le this._getLdapValue() dedans
      display = properties.reduce(function(acc, currentValue, idx) {
        if (idx === 0) {
          return fctLdapValue(ldapUser[currentValue]);
        } else {
          return  `${acc} ${fctLdapValue(ldapUser[currentValue])}`;
        }
      }, '');
    }

    return display;
  }

}
