import { Component, OnInit } from '@angular/core';
import {AccountLevelService} from '../../common/account-level/account-level.service';

@Component({
  selector: 'app-notes-versions',
  templateUrl: './notes-versions.component.html',
  styleUrls: ['./notes-versions.component.scss']
})
export class NotesVersionsComponent implements OnInit {

  constructor(private accountLevel: AccountLevelService,) { }

  ngOnInit(): void {
  }
  isStaff() {
    return this.accountLevel.isStaff();
  }
}
