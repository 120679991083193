import {Injectable} from '@angular/core';
import {environment} from '@Env/environment';
import {HttpClient} from '@angular/common/http';
import {TicketToBookmarkers} from './ticketToBookmarkers.model';
import {DaoGeneric} from '@Services/base/daoService';
import {FlagColorEnum} from '../flag/flagColor.enum';

@Injectable({   providedIn: 'root' })
export class TicketToBookmarkersService extends DaoGeneric<TicketToBookmarkers> {
  private url = `${environment.baseUrl}/helpdesk/ticketstobookmarkers/`;
  private _flags: FlagColorEnum[] =
    [FlagColorEnum.black, FlagColorEnum.blue, FlagColorEnum.green, FlagColorEnum.purple, FlagColorEnum.orange,
     FlagColorEnum.red, FlagColorEnum.cyan, FlagColorEnum.pink, FlagColorEnum.yellow];
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getRootUrl(): string {
    return this.url;
  }
  freshForceColorName(color: FlagColorEnum, gender: 'f' | 'm' = 'f') {
    switch (color) {
      case FlagColorEnum.black:
        return gender === 'f' ? 'noire' : 'noir';
      case FlagColorEnum.blue:
        return gender === 'f' ? 'bleue' : 'bleu';
      case FlagColorEnum.cyan:
        return 'cyan';
      case FlagColorEnum.orange:
        return 'orange';
      case FlagColorEnum.green:
        return gender === 'f' ? 'verte' : 'vert';
      case FlagColorEnum.pink:
        return 'rose';
      case FlagColorEnum.purple:
        return gender === 'f' ? 'violette' : 'violet';
      case FlagColorEnum.red:
        return 'rouge';
      case FlagColorEnum.yellow:
        return 'jaune';
    }
  }
  get colors() {
    return this._flags;
  }
}
