<div fxLayoutAlign="start center">
  <button mat-icon-button *ngFor="let flagColorNum of ticketToBookmarkersService.colors"
          [matTooltip]="toolTip(flagColorNum)"
          [ngClass]="{ flagOn: selectedFlag === flagColorNum, 'opacity-flag': this.selectedFlag !== flagColorNum }"
          (click)="flagColorSelected(flagColorNum)">
    <mat-icon [ngStyle]="{color: flagsEnum[flagColorNum]}">flag</mat-icon>
  </button>
</div>


