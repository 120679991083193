import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { interval } from 'rxjs/internal/observable/interval';

@Injectable({
  providedIn: 'root'
})
export class LiveUpdateService implements OnDestroy {

  public live: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private alive = false;
  private time = 10000; // default 10 s

  constructor() {

  }


  public setTime(val: number): void {
    this.time = val;
  }

  public setAlive(bol: boolean): void {
    this.alive = bol;
    if (this.alive) {
      this.createTicker();
    }
  }

  private createTicker(): void {
     interval(this.time).pipe(
      takeWhile(() => this.alive))  // pour le tuer ;)
      .subscribe(() => {
        this.live.next(this.alive);
      });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }


}
