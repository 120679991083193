<div fxLayout="row"
     class="mat-elevation-z2 file-canvas mat-caption"
     matTooltip="{{tooltip}}"
     matTooltipPosition="above"
     [ngStyle]="{backgroundColor: state}">
  {{pieceJointe.datafile.name | truncate: 10}}
  <mat-icon *ngIf="! loading && upload?.state === 'DONE'">done</mat-icon>
  <span class="fill-space"></span>
  <mat-icon *ngIf="! upload" color="primary"
            class="close-button"
            (click)="destroyMe()">close
  </mat-icon>
</div>


