<mat-card fxLayout="column" class="wrapper-new-ticket">
  <mat-card-title>
    <p8-layout-settings-header [titlePath]="['Saisie d\'un ticket en tant qu\'agent']"></p8-layout-settings-header>
  </mat-card-title>
  <mat-card-content fxLayoutGap="5%" fxFlex="100%">
    <app-form-create-ticket
      (onSaved)="redirect($event)"
      (onCanceled)="cancel()"
      [extendedFields]="extendedFields"
      [typeForm]="formType.agent"></app-form-create-ticket>
  </mat-card-content>
</mat-card>
