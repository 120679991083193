<mat-expansion-panel style="margin-top: 5px;">
  <mat-expansion-panel-header>
    <span class="mandatory-field" *ngIf="contactsRequired">* &nbsp;</span>
    Contact(s) à ajouter &nbsp;<i class="fa fa-question-circle" matTooltip="les contacts auront accès au ticket et pourront interagir (par ex. commenter)"></i>&nbsp;
  </mat-expansion-panel-header>
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="70">
          <app-search-ldap
            [ldapDisplayProperty]="'cn,mail'"
            [defaultScopes]="'employee,faculty,researcher'"
            [removeDiatrics]="true"
            [splitWords]="true"
            [placeHolder]="'Rechercher le nom d\'une personne puis la sélectionner pour l\'ajouter...'"
            [toolTip]="'Taper au moins les 3 premières lettres de son nom suivi optionnellement de son prénom'"
            [title]="false"
            (ldapUserSelected)="onSelectLdapIndiv($event)">
          </app-search-ldap>
        </div>
        <div fxFlex="30">
          <mat-chip-list class="mat-chip-list-stacked" #chipList>
            <mat-chip
              *ngFor="let sub of subscriptions"
              [selectable]="true"
              [removable]="true"
              (removed)="delete(sub)">
              <span>{{sub.first_name}} {{sub.last_name}}</span>
              <span class="fill-space"></span>
              <mat-icon matChipRemove matTooltip="cliquer pour retirer ce contact">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
</mat-expansion-panel>
