<mat-form-field  fxLayout="column">
    <mat-label data-cy="lbl-title-ticket-objet-demande"><span class="mandatory-field">* </span>Objet de la demande (4 caractères minimum)</mat-label>
    <input name="title" matInput placeholder="{{placeholder}}" fxFlex="100%"
           [UpperCaseFirstLetter]="true"
           [formControl]="control"
           class="form-control"
           required minlength="{{min}}" maxlength="{{max}}">
  <mat-hint align="end"><code>{{control.value?.length}}</code> / <code>200</code></mat-hint>
  <mat-error *ngIf="control.errors && (control.dirty || control.touched)">
    <div [hidden]="!control.errors.required">
      L'objet de la demande est obligatoire.
    </div>
    <div [hidden]="!control.errors.minlength">
      Minimum {{min}} caractères.
    </div>
    <div [hidden]="!control.errors.maxlength">
      Maximum {{max}} lettres.
    </div>
  </mat-error>
</mat-form-field>
