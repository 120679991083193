
import { Injectable } from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {environment} from '@Env/environment';
import {Status} from './status.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {tap} from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class StatusService {
  private url = `${environment.baseUrl}/helpdesk/status/`;
  private statusSource = new ReplaySubject<Status[]>(1);
  status$ = this.statusSource.asObservable();

  constructor(private http: HttpClient) {
  }

  create(status: Status) {
    return this.http.post(this.url, status)
      .pipe(tap(data => this.list()));
  }

  list(sort: string = '', order: string = '') {
    let params: HttpParams = new HttpParams();
    if (sort && sort !== '') {
      params = this._getSorting(sort, order, params);
    }
    return this.http.get<Status[]>(this.url, {params: params});
  }

  get(id: number) {
    return this.http.get<Status>(`${this.url}${id}/`);
  }

  update(status: Status) {
    return this.http.put<Status>(`${this.url}${status.id}/`, status);

  }

  delete(status: Status) {
    return this.http.delete<Status>(`${this.url}${status.id}/`);

  }

  protected _getSorting(sort: string, order: string, params: HttpParams) {
    let orderDirection = '';
    let orderField = '';
    if (order) {
      switch (order) {
        case 'asc':
          orderDirection = '';
          break;
        case 'desc':
          orderDirection = '-';
          break;
      }
    }

    if (sort) {
      orderField = `${orderDirection}${sort}`;
      params = params.set('ordering', orderField);
    }

    return params;
  }
}
