<mat-card-subtitle>Liaisons</mat-card-subtitle>
<div>
  <mat-progress-bar *ngIf="loading" [mode]="'indeterminate'"></mat-progress-bar>
  <mat-form-field style="width: 95%" [appearance]="'fill'">
    <input matInput type="text"
           [placeholder]="placeholder_search"
           [matTooltip]="placeholder_search"
           [formControl]="ticketControl"
           [matAutocomplete]="auto">
    <button matPrefix mat-icon-button (click)="createTicketLie()"
            matTooltip="Pour créer un nouveau ticket à lier">
      <mat-icon>add</mat-icon>
    </button>
  </mat-form-field>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let tl of ticketsSearch | async"
                (onSelectionChange)="onSelectTL(tl)">
      <span matTooltip="{{tl.title}}">{{ tl.id }} {{tl.title}}</span>
    </mat-option>
  </mat-autocomplete>
</div>

<div *ngFor="let links of linksGroupByLabel | keyvalue">
  <div *ngIf="links">
    <mat-card-subtitle>{{links.key}}</mat-card-subtitle>
    <div class="overflow-container">
      <mat-chip-list class="mat-chip-list-stacked" fxFlex="80%">
        <mat-chip *ngFor="let tl of links.value;"
                  [removable]="true"
                  (removed)="delete_tl(tl)"
                  class="mat-small"
        >
          <mat-icon matChipRemove matTooltip="Enlever le ticket lié">cancel</mat-icon>
          <span *ngIf="currentTicketIsSource(tl)" matTooltip="{{tl?.target_obj?.title}}">
            {{getTargetTitle(tl)}}
          </span>
          <span *ngIf="currentTicketIsTarget(tl)" matTooltip="{{tl?.source_obj?.title}}">
            {{getSourceTitle(tl)}}
          </span>
          <button matSuffix mat-icon-button (click)="previewTicketLie(tl)"
                  matTooltip="Visualiser un ticket lié">
            <mat-icon>visibility</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
