import {Component, OnInit, Input, Output, EventEmitter, HostBinding} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import {PopUpCkeditorComponent} from './pop-up-ckeditor/pop-up-ckeditor.component';
import {NewsFeedService} from '../services/news-feed/newsFeedService';
import {NewsFeed} from '../services/news-feed/news-feed';
import {IndividuSafeService} from '../services/individu_helpdesk/individu.service';
import {rolesHD} from "../roleEnum";
import {Individu} from "@Common/core/services";

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss']
})
export class NewsFeedComponent implements OnInit {

  archive = false;
  editMode = false;

  sanitazed: any;
  _nfeed: NewsFeed;
  isReadMore = true;
  isChecked = true;
  private _individu: Individu;

  @HostBinding('class.news-box')
  @Input()
  set newsFeed(nfFeed) {
    this._nfeed = nfFeed;
    this.sanitazeNewsFeed(this.newsFeed.content);
  }

  get newsFeed() {
    return this._nfeed;
  }

  @Output()
  saved = new EventEmitter();

  constructor(private mySanitizer: DomSanitizer,
              public dialog: MatDialog,
              private nfService: NewsFeedService,
              private individuService: IndividuSafeService) {
  }

  ngOnInit() {
    this.individuService.individu$.subscribe(_in => {
      this._individu = _in;
    });
  }

  getTooltipText() {
    return (this.newsFeed.archived) ? 'La carte sera désarchivée' : 'La carte sera archivée';
  }

  archiveOrUnarchiveCarte() {
    this.newsFeed.archived = !this.newsFeed.archived;
    this.updateNewsFeed(this.newsFeed);
  }

  showAllContent() {
    this.isReadMore = !this.isReadMore;
  }

  isStaff(_in: Individu = this._individu) {
    return _in && (this.individuService.hasRole(_in, rolesHD.admin) || this.individuService.hasRole(_in, rolesHD.superviseur) || this.individuService.hasRole(_in, rolesHD.solver));
  }

  changeEditMode() {
    if (! this.isStaff())
      return;

    let dialogRef = this.dialog.open(PopUpCkeditorComponent,{data: this.newsFeed});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateNewsFeed(result)
      }
    });
  }

  updateNewsFeed(content) {
    this.newsFeed = content;
    if (this.newsFeed.id) {
      this.nfService.update(this.newsFeed)
        .subscribe(nfs => {
          this.sanitazeNewsFeed(nfs.content);
            this.saved.emit();

          }
        );
    } else {
      this.nfService.create(this.newsFeed)
        .subscribe(nfs => {
          this.sanitazeNewsFeed(nfs.content);
          this.newsFeed = nfs;
            this.saved.emit();

          }
        );
    }

  }

  sanitazeNewsFeed(htmlSrc) {
    this.sanitazed = this.mySanitizer.bypassSecurityTrustHtml(htmlSrc);
  }
}
