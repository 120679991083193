import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {allowedExtensionsForPreview, CommentTicket, PriorityService, Ticket, TicketService} from '../../services';
import {Subject} from 'rxjs';

import {PieceJointe, PiecejointeDownloadService} from '../../services';
import * as FileSaver from 'file-saver';

import {AccountLevelService} from '../../account-level/account-level.service';
import {ImagePreviewComponent} from '../../image-preview/image-preview.component';
import { MatDialog } from '@angular/material/dialog';
import {IndividuSafeService} from '../../services/individu_helpdesk/individu.service';
import {finalize} from 'rxjs/operators';
import {SubSink} from '@Common/core/utils/subsink';
import {PubSubService} from '../../pub-sub.service';
import {ActionTicketChangeStream, TicketChangeStream} from '../../ticket-change-stream.model';
import {AnnotationService} from '../../services/ticket/annotation.service';
import {Annotation} from '../../services/ticket/annotation.model';
import {User} from '@Services/auth';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {TabEditorIndex, TabListIndex} from '../../tabs.enums';

@Component({
  selector: 'app-ux-ticket-body',
  templateUrl: './ux-ticket-body.component.html',
  styleUrls: ['./ux-ticket-body.component.scss']
})
export class UxTicketBodyComponent implements OnInit, OnDestroy {
  @Input() ticket: Ticket;

  @Input() ticketsIds: number[] = [];

  @Input() isVisible: boolean;
  @Input() inModale = false; // Workaround Firefox pour ne pas aplatir la partie ticket

  @ViewChild('ticketWrapper') ticketWrapper: ElementRef;

  comments: CommentTicket[];

  /**
   * Pour écoute modification du ticket ailleurs
   */
  @Input()
  ticketChangeStream: Subject<TicketChangeStream>;

  @Input() groupChangeStream: Subject<Ticket>;

  @Input() displayToggleNavBar = false;

  previewImage: any;

  isStaff = false;

  loading = false;

  opened = true;
  @Output() openedChange = new EventEmitter();

  selectedTab = 0;

  annotations: Annotation[];

  annotations_content = [];

  userConnectedPermissions: any;

  showAnnotations: boolean;
  showNotifMail: boolean;
  showHistoric: boolean;

  loadingComments = false;
  loadingAnnotations = false;

  subSink = new SubSink();

  constructor(private pubSubSvc: PubSubService,
              private priorityService: PriorityService,
              private ticketService: TicketService,
              private pieceJointeDownload: PiecejointeDownloadService,
              public accountLevel: AccountLevelService,
              private individuService: IndividuSafeService,
              public dialog: MatDialog,
              private annotationSvc: AnnotationService) {
  }

  ngOnInit() {
    this.isVisible = true;
    this.isStaff = this.accountLevel.isStaff();

    this.fetchComments();
    this.fetchAnnotations();
    this.toggleTicketLu();

    this.subSink.sink = this.ticketChangeStream.subscribe(_ticket => {
      this.ticket = _ticket.ticket;
      this.fetchComments();
      this.fetchAnnotations();
    });
    this.isAgentSuperviseurAdmin();
    this.showNotifMail = this.isStaff;
    this.showHistoric = this.isStaff;
    this._onTabListCommentOrAnnotation();
  }
  synchronisationAnnotationsTabs(annotation) {
    this.annotationSvc.annotationCreated(annotation);
  }
  fetchAnnotations() {
    if (this.isStaff) {
      this.loadingAnnotations = true;
      const user: User = JSON.parse(localStorage.getItem('user'));
      const extraParams = new Map<string, string>();
      extraParams.set('agent', user.username).set('ticket', this.ticket.id.toString());
      this.subSink.sink = this.annotationSvc
        .listAllItems(extraParams)
        .pipe(finalize(() => this.loadingAnnotations = false))
        .subscribe((items) => {
          this.annotations = items.list as Annotation[];
        });
    }
  }
  getTicketNumber() {
    if (this.ticket) {
      return `N° ${this.ticket.id}`;
    }
    return '';
  }
  toggleTicketLu() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.subSink.sink = this.ticketService.setReadStateBulk([this.ticket.id], user.username,'lu')
      .subscribe(data => {
        if (this.groupChangeStream) {
          this.groupChangeStream.next(null);
        }
      });
  }

  toggleSidenav(event) {
    this.openedChange.emit(event);
  }

  onNextPrevious(ticketId: number) {
    this.pubSubSvc.publish('loading', true);

    this.subSink.sink = this.ticketService
      .get(ticketId)
      .pipe(finalize(() => this.pubSubSvc.publish('loading', false)))
      .subscribe((_ticket) => {
        const ticketChangeStream = <TicketChangeStream> {
          ticket: _ticket,
          action: ActionTicketChangeStream.load
        };
        this.ticketChangeStream.next(ticketChangeStream);
        this.ticket = _ticket;
        this.toggleTicketLu();
      });
  }

  /**
   * TODO : en faire un pipe
   * @param value
   * @param fromEnd
   */
  takeNFromEnd(value: string, fromEnd: number = 4) {
    if (value && value.length > fromEnd) {
      return value.slice(-Math.abs(fromEnd));
    }

    return value;
  }

  fetchComments() {
    this.loadingComments = true;
    this.loading = true;
    this.subSink.sink = this.ticketService
      .getComments(this.ticket.id)
      .pipe(finalize(() => this.loadingComments = false))
      .subscribe(coms => {
        this.comments = coms;
        this.scrollToBottom();
      });
  }

  doCommentAdd(e: CommentTicket) {
    if (e && 'id' in e) {
      console.log(e);
      this.comments.push(e);
      this.scrollToBottom();
    }
  }

  pieceJointeIsImageOrPdf(file: PieceJointe) {
    const ext = file.filename.split('.').pop();
    return (allowedExtensionsForPreview.indexOf(ext.toLowerCase()) !== -1);
  }

  downloadPieceJointe(file: PieceJointe) {
    this.subSink.sink = this.pieceJointeDownload.getFile(file.id).subscribe(data => {
      FileSaver.saveAs(data, file.filename);
    });
  }

  popUpPreviewImage(file: PieceJointe) {
    this.subSink.sink = this.pieceJointeDownload.getFile(file.id).subscribe(data => {
      if (data) {
        this.popUp(data);
      }
    });
  }

  popUp(_blob: any) {
    this.dialog.open(ImagePreviewComponent, { data: _blob });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  scrollToBottom() {
    setTimeout(() => this.ticketWrapper.nativeElement.scrollTop = this.ticketWrapper.nativeElement.scrollHeight);
  }

  isAgentSuperviseurAdmin() {
    this.showAnnotations = this.accountLevel.isStaff();
  }
  onFocus(event: MatTabChangeEvent) {
    this.pubSubSvc.publish('tab_index_to_editors', event.index);
  }
  /**
   * Ecoute du changement d'onglet dans la partie haute des listes commentaires / annotations
   * Se place sur le bon onglet : si sur liste commentaire alors édition commentaires, idem annotations
   * @private
   */
  private _onTabListCommentOrAnnotation() {
    this.subSink.sink = this.pubSubSvc.on('tab_index_to_lists').subscribe((index) => {
      switch (index) {
        case TabEditorIndex.annotations:
          this.selectedTab = TabListIndex.annotations;
          break;
        default:
          this.selectedTab = TabListIndex.commentaire;
          break;
      }
    });
  }
}
