import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {CategorieHelpDesk} from '../services/categorie/categorie.model';
import {Ticket} from '../services';
import {UntypedFormControl} from '@angular/forms';
import {from, Observable} from 'rxjs';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-autocomplete-categorie',
  templateUrl: './autocomplete-categorie.component.html',
  styleUrls: ['./autocomplete-categorie.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteCategorieComponent implements OnInit, OnDestroy, OnChanges {
  @Input() class = 'text-for-datatable';

  @Input()
  categories: CategorieHelpDesk[];

  @Input()
  ticket: Ticket;

  @Input() placeholder = '';
  @Input() appearance;

  @Output()
  onselect: EventEmitter<Ticket> = new EventEmitter<Ticket>();

  @ViewChild('myInput') myInputAuto: ElementRef;
  @ViewChild(MatAutocompleteTrigger) trigger;

  categorieControl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<CategorieHelpDesk[]>;
  categorieSelected: CategorieHelpDesk;

  constructor() {}

  ngOnInit() {
    this.setPlaceholder(this.ticket);
    this.filtered();
  }

  setPlaceholder(ticket: Ticket) {
    if (ticket && ticket.categorie_object) {
      this.placeholder = ticket.categorie_object.label;
    }
  }

  filtered() {
    this.filteredOptions = this.categorieControl.valueChanges
      .pipe(startWith(''),
            map(val => this.filter(val)));
  }

  filter(val: string): CategorieHelpDesk[] {
    if (!this.categories || val === null) {
      return;
    }
    return this.categories.filter(cat => cat.label.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  triggerSelect(option: CategorieHelpDesk) {
    this.categorieSelected = option;
    if (this.ticket) {
      this.ticket.categorie = option.id;
      this.ticket.categorie_object = <CategorieHelpDesk> { id: option.id, label: option.label};
      this.onselect.emit(this.ticket);
      this.setPlaceholder(this.ticket);
    } else {
      const ticket: Ticket = <Ticket> { categorie: option.id };
      ticket.categorie_object = <CategorieHelpDesk> { id: option.id, label: option.label};
      this.onselect.emit(ticket);
    }
    this.categorieControl.reset();
    this.filtered();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categories && changes.categories.currentValue) {
      this.filtered();
    }

    if (changes.ticket && changes.ticket.currentValue) {
      this.setPlaceholder(changes.ticket.currentValue);
    }
  }

  ngOnDestroy(): void {
  }
}
