import {Injectable} from "@angular/core";
import {DaoGeneric} from "@Services/base/daoService";
import {environment} from "@Env/environment";
import {HttpClient} from '@angular/common/http';
import {GroupModel} from './group.model';


@Injectable({ providedIn: 'root' })
export class GroupService extends DaoGeneric<GroupModel> {

  private url = `${environment.baseUrl}/helpdesk/groups/`;
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getRootUrl(): string {
    return this.url;
  }

}
