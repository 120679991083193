import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Ticket} from '../../common/services';
import {TicketService} from '../../common/services';
import {ActivatedRoute, Router} from '@angular/router';
import {TicketDataSource} from '../../common/services/ticket/ticket.datasource';
import {PaginationBase} from "@Common/widgets/general/pagination";
import {UntypedFormControl} from "@angular/forms";
import {Subscription} from "rxjs";

import {debounceTime, distinctUntilChanged, finalize} from "rxjs/operators";
import {PubSubService} from "../../common/pub-sub.service";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import {User} from '@Services/auth';

@Component({
  selector: 'app-overview-tickets',
  templateUrl: './overview-tickets.component.html',
  styleUrls: ['./overview-tickets.component.css']
})
export class OverviewTicketsComponent extends PaginationBase implements OnInit, OnDestroy, AfterViewInit {
  displayedColumns = ['id', 'title', 'status', 'modified', 'link'];
  dataSource: TicketDataSource = new TicketDataSource();
  selectedRowId: number;
  term = new UntypedFormControl();
  subSearch: Subscription;
  searchTick = '';
  private sortBy = '';
  private direction = '';
  private tickets: Ticket[];
  subscriptions: Subscription[] = [];
  user: User;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ticketService: TicketService,
              private pubSubSvc: PubSubService,
              private router: Router,
              private route: ActivatedRoute) {
    super();

    this.subSearch = this.term.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(text => {
        this.searchTick = text;
        this.page = 1;
        this.getTickets(this.page, text);
      });
  }

  getList(page: number = 1) {
    this.getTickets(page);
  }

  ngOnInit() {
    this.MAX_ITEMS = 5;
    this.getTickets();
    this.user = JSON.parse(localStorage.getItem('user'));
    console.log('this.user', this.user);
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(sub => {
        if (sub) {
          sub.unsubscribe();
        }});
    }
  }

  getTickets(page = 1, sortBy: string = this.sortBy, order: string = this.direction, search = this.searchTick){
    this.pubSubSvc.publish('loading', true);

    this.page = page;
    this.ticketService.myTickets(this.MAX_ITEMS, (page - 1) * this.MAX_ITEMS, search)
      .pipe(finalize(() => this.pubSubSvc.publish('loading', false)))
      .subscribe(tickets => {
        this.total = tickets.total;
        this.totalView = tickets.totalView;
        this.dataSource.load(tickets.list);
        this.tickets = tickets.list;
    });

  }

  // onSortSub() {
  //   const subSort = this.dataSource.sort.sortChange.subscribe((d) => {
  //
  //     this.sortBy = d.active;
  //     this.direction = d.direction;
  //
  //     this.getTickets(this.page, this.sortBy, this.direction)
  //   });
  //
  //   this.subscriptions.push(subSort);
  // }

  goToTicket(id: number) {
    this.router.navigate(['helpdesk/ticket', {id: id}], {relativeTo: this.route.parent});
  }

  exampleFunction(ticket: Ticket) {
    console.log(ticket);
    this.selectedRowId = ticket.id;
  }


  isSelected(id: number) {
    if (this.selectedRowId === id) {
      return '#F5DEB3';
    }
  }

  ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
    // this.onSortSub();
  }
}


