import { Component, OnInit, Input } from '@angular/core';
import {Ticket} from '../../../services';

@Component({
  selector: 'app-ux-affectation-display',
  templateUrl: './ux-affectation-display.component.html',
  styleUrls: ['./ux-affectation-display.component.scss']
})
export class UxAffectationDisplayComponent implements OnInit {
  @Input()
  ticket: Ticket;
  constructor() { }

  ngOnInit() {
  }

}
